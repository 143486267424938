import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { FC } from 'react'

interface MuiDatePickerProps {
    label: string
    value?: Date
    onChange?: (date: Date) => void
}
const MuiDatePicker: FC<MuiDatePickerProps> = ({ label, value, onChange }) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                sx={{ width: '100%' }}
                label={label}
                value={dayjs(value)}
                disabled={onChange === undefined}
                onChange={(date: any) => {
                    onChange(dayjs(date).toDate())
                }}
            />
        </LocalizationProvider>
    )
}

export default MuiDatePicker
