import { useAuth0 } from "@auth0/auth0-react";
import { FunctionComponent, useEffect, useState } from "react";
import Footer from "../../components/Footer";
import LoadingSpinner from "../../components/loadingSpinner";
import GeneralConfirmModal from "../../components/modals/GeneralConfirmModal";
import PracticeModal from "../../components/modals/PracticeModal";
import NavBarNew from "../../components/NavBarNew";
import Text from "../../components/text";
import AuthContainer from "../../container/AuthContainer";
import client from "../../libary/apollo";
import { EmployerModel, LocalityModel, LocationModel } from "../../libary/Models";
import EmployerList from "./EmployerList";
import LocationList from "./LocationList";
import { requestPermissionMutation } from "./mutations";
import { getEmployersAndLocationsQuery, getLocalitiesQuery } from "./queries";
import './styles.css'

enum PermissionsSelectedTab {
    LOCATION,
    EMPLOYER
}

const CreateSessionPermissionRequests: FunctionComponent = () => {
    const { getAccessTokenSilently } = useAuth0()
    const [token, setToken] = useState<string | null>(null)

    const [localities, setLocalities] = useState<LocalityModel[] | null>(null)
    const [employers, setEmployers] = useState<EmployerModel[] | null>(null)
    const [locations, setLocations] = useState<LocationModel[] | null>(null)
    const [locality, setLocality] = useState<LocalityModel | null>(null)
    const [employer, setEmployer] = useState<EmployerModel | null>(null)
    const [location, setLocation] = useState<LocationModel | null>(null)
    const [selectedTab, setSelectedTab] = useState<PermissionsSelectedTab>(PermissionsSelectedTab.LOCATION)
    const [permissionRequest, setPermissionRequest] = useState<{object_id: string, object_name: string, object_type: string, locality_id: string}|null>(null)

    useEffect(() => {
        (async () => {
            const newToken = await getAccessTokenSilently()
            client.query({
                query: getLocalitiesQuery,
                context: {
                    headers: {
                        "Authorization": newToken
                    }
                }
            }).then((response) => {
                setToken(newToken)
                setLocalities(response.data.getLocalities)
            })
        })()
    }, [])

    useEffect(() => {
        console.log("LOC IS: ", locality)
        if (locality === null) { return }
        client.query({
            query: getEmployersAndLocationsQuery,
            variables: {
                localityId: locality.id
            },
            context: {
                headers: {
                    Authorization: token
                }
            }
        }).then((response) => {
            setEmployers(response.data.getEmployers)
            setLocations(response.data.getLocations)
        })
    }, [locality])


    const renderFilterBar = () => {
        return (
            <div className="locality-filter-bar-container">
                <div className="locality-filter-bar-filter-container">
                    <label>Locality</label>
                    <select value={locality === null ? "Select a Locality" : undefined} className="dropdown" onChange={(event) => setLocality(localities[event.target.selectedIndex - 1])}>
                        <option disabled>Select a Locality</option>
                        {localities.map((loc) => <option key={loc.id}>{loc.name_value}</option>)}
                    </select>
                </div>
            </div>
        )
    }

    const renderTabs = () => {
        return (
            <div className="permission-request-tab-container">
                <div className={selectedTab === PermissionsSelectedTab.LOCATION ? "permission-request-tab-item selected" : "permission-request-tab-item"} onClick={() => setSelectedTab(PermissionsSelectedTab.LOCATION)}>
                    Locations
                </div>
                {/* <div className={selectedTab === PermissionsSelectedTab.EMPLOYER ? "permission-request-tab-item selected" : "permission-request-tab-item"} onClick={() => setSelectedTab(PermissionsSelectedTab.EMPLOYER)}>
                    Employers
                </div> */}
            </div>
        )
    }

    const requestPermission = () => {
        client.mutate({
            mutation: requestPermissionMutation,
            variables: {
                permission: `CREATE-SESSION`,
                objectId: permissionRequest.object_id,
                objectName: permissionRequest.object_name,
                localityId: permissionRequest.locality_id
            },
            context: {
                headers: {
                    "Authorization": token
                }
            }
        }).then((response) => {
            if(response.data.requestPermission.success === false) { alert("Unable to request permission") }
            if(response.data.requestPermission.success === true) { 
                const newEmployers = employers.filter((emp) => emp.id !== permissionRequest.object_id)
                const newLocations = locations.filter((loc) => loc.id !== permissionRequest.object_id)
                setEmployers(newEmployers)
                setLocations(newLocations)
                setPermissionRequest(null)
            }
        })
    }

    return (
        <AuthContainer>
            <div className="create-session-permission-requests-container">
                <NavBarNew setTab={() => { }} />
                    <div className="mainBody">
                        <div className="create-session-permission-requests-body">
                        <Text text="Request permisisons to create shifts" type="header" />
                        {localities === null && <LoadingSpinner />}
                        {localities !== null && renderFilterBar()}
                        {localities !== null && renderTabs()}
                        {(selectedTab === PermissionsSelectedTab.EMPLOYER && employers !== null) && <EmployerList
                            employers={employers}
                            employerSelected={((emp) => setEmployer(emp))}
                            requestPermissionClicked={(emp: EmployerModel) => { setPermissionRequest({object_id: emp.id, object_name: emp.name_value, object_type: "EMPLOYER", locality_id: employer.locality_id}) }}
                        />}
                        {(selectedTab === PermissionsSelectedTab.LOCATION && locations !== null) && <LocationList
                            locations={locations}
                            locationSelected={((loc) => setLocation(loc))}
                            requestPermissionClicked={(loc: LocationModel) => { setPermissionRequest({object_id: loc.id, object_name: loc.name_value, object_type: "LOCATION", locality_id: loc.locality_id}) }}
                        />}
                        {locality === null &&
                            <div className="create-session-permissions-no-locality">
                                Please select a locality to see locations available
                            </div>
                        }
                        
                    </div>
                    {location !== null && (
                        <PracticeModal 
                            show={location !== null}
                            closeModal={() => setLocation(null)}
                            locality_id={locality.id}
                            location_id={location.id}
                        />
                    )}
                    {permissionRequest !== null && (
                        <GeneralConfirmModal
                            show={permissionRequest !== null}
                            title={`Do you want to request permission to post shifts at ${permissionRequest.object_name}`}
                            closeModal={() => setPermissionRequest(null)}
                            confirmAction={() => {requestPermission()}}
                        />
                    )}
                    </div>
                <Footer />
            </div>
        </AuthContainer>
    )
}

// interface LocalityFilterBarProps {
//     localities: LocalityModel[]
//     token: string
// }

export default CreateSessionPermissionRequests