import { VisibilityOffRounded } from '@mui/icons-material'
import { FunctionComponent, useContext } from 'react'
import { ThemeContext } from '../../App'
import ColoredButton, { ColoredButtonEmphasis } from '../colored-button'
import './styles.css'
interface CalendarDayCellProps {
    cellState: string
    date: Date
    day: string
    onClick: Function
    createSession: Function
    counts: {
        no_applicants: number
        pending: number
        filled: number
        hidden: number
    }
    canCreate: boolean
    hideSessionStatus?: boolean
}

const CalendarDayCell: FunctionComponent<CalendarDayCellProps> = (
    props: CalendarDayCellProps
) => {
    const theme = useContext(ThemeContext)

    const renderSessions = () => {
        let sessions = []

        if (props.cellState !== 'day-disabled') {
            if (props.hideSessionStatus && props.hideSessionStatus === true) {
                const sessionCount =
                    props.counts.no_applicants +
                    props.counts.pending +
                    props.counts.filled
                sessions.push(
                    <div
                        key={1}
                        className={
                            sessionCount > 0 ? 'sessionCount' : 'hidden'
                        }>
                        • {sessionCount} Sessions.
                    </div>
                )
                return sessions
            }
            sessions.push(
                <div
                    key={1}
                    className={
                        props.counts.no_applicants > 0
                            ? 'sessionCount'
                            : 'hidden'
                    }>
                    • {props.counts.no_applicants} No App.
                </div>
            )
            sessions.push(
                <div
                    key={2}
                    className={
                        props.counts.pending > 0 ? 'sessionCount' : 'hidden'
                    }>
                    • {props.counts.pending} Pending
                </div>
            )
            sessions.push(
                <div
                    key={3}
                    className={
                        props.counts.filled > 0 ? 'sessionCount' : 'hidden'
                    }>
                    • {props.counts.filled} Filled
                </div>
            )
            sessions.push(
                <div
                    key={4}
                    className={
                        props.counts.hidden > 0 ? 'sessionCount' : 'hidden'
                    }
                    style={{ justifyContent: 'center' }}>
                    • {props.counts.hidden} Hidden{' '}
                    <VisibilityOffRounded
                        style={{ height: '12px', width: '12px' }}
                    />
                </div>
            )
            return sessions
        }
        return <div />
    }

    const startCreateSession = () => {
        props.createSession(props.date)
    }

    const renderButton = () => {
        if (props.cellState !== 'day-disabled') {
            return (
                <div
                    className={
                        props.cellState === 'day-selected'
                            ? 'cell-add-button-visible'
                            : 'cell-add-button'
                    }>
                    <ColoredButton
                        emphasis={ColoredButtonEmphasis.HIGH}
                        text='Add'
                        onClick={() => {
                            startCreateSession()
                        }}
                        height='20px'
                        width='40px'
                        backgroundColor={
                            props.cellState === 'day-selected'
                                ? theme.colors.secondary
                                : 'transparent'
                        }
                        color={
                            props.cellState === 'day-selected'
                                ? 'white'
                                : 'black'
                        }
                        border={
                            props.cellState === 'day-selected'
                                ? 'transparent'
                                : '#BBBBBB'
                        }
                    />
                </div>
            )
        }
    }

    const getStatusIndicatorClassName = () => {
        if (props.hideSessionStatus && props.hideSessionStatus === true) {
            return `statusIndicator ${
                props.cellState === 'day-disabled'
                    ? ''
                    : props.counts.no_applicants + props.counts.pending > 0
                    ? 'status-no'
                    : ''
            }`
        } else {
            return `statusIndicator ${
                props.cellState === 'day-disabled'
                    ? ''
                    : props.counts.no_applicants > 0
                    ? 'status-no'
                    : props.counts.pending > 0
                    ? 'status-pend'
                    : props.counts.filled > 0
                    ? 'status-fill'
                    : ''
            }`
        }
    }

    return (
        <div className={props.cellState} onClick={() => props.onClick()}>
            <div className={getStatusIndicatorClassName()} />
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingRight: '4px',
                }}>
                <div
                    className={
                        props.cellState === 'day-disabled'
                            ? 'text-disabled'
                            : 'text-enabled'
                    }>
                    {props.day}
                </div>
                {props.canCreate && renderButton()}
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div className='sessionContainer'>{renderSessions()}</div>
            </div>
        </div>
    )
}

export default CalendarDayCell
