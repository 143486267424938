import Theme from "./theme"
import Logo from '../assets/gm_logo.png'

const GMWorkforceTheme: Theme = {
    images: {
        logo: Logo
    },
    colors: {
        brand: '#68C8D0',
        secondary: '#5E2A6F',
        text: 'black',
        lightText: 'white',
        parallaxBackground: '#68C8D068'
    },
    fonts: {
        titleFont: '',
        titleFontSize: '',
        bodyFont: '', 
        bodyFontSize: ''
    }
}

export default GMWorkforceTheme