import { useAuth0 } from "@auth0/auth0-react";
import { FunctionComponent } from "react";
import { useCookies } from "react-cookie";
import useFeatures from "../../../libary/hooks/useFeatures";
import './styles.css'

interface BurgerMenuProps {
    open: boolean
    admin: boolean
}

const BurgerMenu: FunctionComponent<BurgerMenuProps> = (props: BurgerMenuProps) => {

    const { features } = useFeatures()

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [cookies, setCookie, removeCookie] = useCookies()
    const { logout } = useAuth0()


    const navigate = (location: string) => {
        window.history.pushState(null, null, location)
        window.location.replace(location)
    }

    const logoutPressed = () => {
        let logoutUrl = "http://localhost:3000"
        if(window.location.hostname.includes("fedworks")) {
            logoutUrl = "https://fedworks.org"
          } else if(window.location.hostname.includes("gmworkforce")) {
            logoutUrl = "https://gmworkforcebank.co.uk"
          } else {
            logoutUrl = "http://localhost:3000"
          }
          
        const returnTo = process.env.NODE_ENV  === 'production' ? logoutUrl : "http://localhost:3000"
        removeCookie('token')
        sessionStorage.clear()
        logout({ returnTo })
    }

    return (
        <div className={props.open === true ? 'burger-menu-container-open' : 'burger-menu-container-close'}>
            <div className='burger-menu-item-list'>
                {features.manageRota === true && (
                    <div className='burger-menu-item'>
                        Rota
                    </div>
                )}
                {features.findSessions === true && (
                    <div className='burger-menu-item' onClick={() => navigate('/diary')}>
                        My Diary
                    </div>
                )}
                {features.findSessions === true && (
                    <div className='burger-menu-item' onClick={() => navigate('/search')}>
                        Find Sessions
                    </div>
                )}
                <div className='burger-menu-item' onClick={() => logoutPressed()}>
                        Logout
                </div>
            </div>
        </div>
    )
}

export default BurgerMenu