import { FunctionComponent, useState } from "react";
import Footer from "../../components/Footer";
import StaffModal from "../../components/modals/StaffModal";
import NavBarNew from "../../components/NavBarNew";
import AuthContainer from "../../container/AuthContainer";
import { SelectedTab, StaffModel } from "../../libary/Models";
import AdminStaffList from "./AdminStaffList";

import './styles.css'

const Admin: FunctionComponent = () => {

    const [staffSelected, setStaffSelected] = useState<StaffModel | null>(null)

    return (
        <AuthContainer>
        <div className='admin-body'>
            <NavBarNew setTab={() => { }} selectedTab={SelectedTab.Admin} />
            <div className='mainBody'>
                <div className='admin-content'>
                    <AdminStaffList select={(staff) => setStaffSelected(staff)}/>
                </div>
            </div>
            {staffSelected !== null && (
                <StaffModal
                    closeModal={() => setStaffSelected(null)}
                    id={staffSelected.id}
                    show={staffSelected !== null}
                />
            )}
            <Footer />
        </div>
        </AuthContainer>
    )
}

export default Admin