
import { FunctionComponent, useContext, useState } from "react";
import Footer from "../../../components/Footer";
import NavBarNew from "../../../components/NavBarNew";
import AuthContainer from "../../../container/AuthContainer";
import '../styles.css'
import ColoredButton, { ColoredButtonEmphasis } from "../../../components/colored-button";
import { ThemeContext } from "../../../App";
import client from "../../../libary/apollo";
import { acceptStaffTermsMutation } from "./mutations";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingSpinner from "../../../components/loadingSpinner";

const StaffDisclaimer: FunctionComponent = () => {
    const theme = useContext(ThemeContext)
    const { getAccessTokenSilently } = useAuth0()

    const [loading, setLoading] = useState<boolean>(false)
    const [termsAccepted, setTermsAccepted] = useState<boolean>(false)
    const [emailAccepted, setEmailAccepted] = useState<boolean>(false)

    const acceptTerms = () => {
        (async () => {
            setLoading(true)
            const token = await getAccessTokenSilently()
            client.mutate({
                mutation: acceptStaffTermsMutation,
                context: {
                    headers: {
                        "Authorization": token
                    }
                }
            }).then((response) => {
                if(response.data.acceptStaffTerms.success === true) {
                    setLoading(false)
                    window.location.replace('/')
                } else {
                    setLoading(false)
                    alert("Unable to accept terms")
                }
            })
        })()
    }

    return (
        <AuthContainer>
            <div className="disclaimer-page-container">
                <NavBarNew hideButtons={true} />
                <div className="mainBody">
                        {loading === true && <LoadingSpinner/>}
                        {loading === false && (
                            <div className="disclaimer-page-body">
                            <iframe title="Staff Disclaimer" className="disclaimer-pdf-container" src="/staff-disclaimer.html"/>
                            <div className="disclaimer-checkbox-container">
                                <label className="disclaimer-checkbox-label">By ticking this box, I agree to the terms set out above</label><input className="disclaimer-checkbox" type="checkbox" onChange={(event) => setTermsAccepted(event.target.checked)}/>
                            </div>
                            <div className="disclaimer-checkbox-container">
                                <label className="disclaimer-checkbox-label">By ticking this box, I agree to receive emails</label><input className="disclaimer-checkbox" type="checkbox" onChange={(event) => setEmailAccepted(event.target.checked)}/>
                            </div>
                            <ColoredButton text="Accept Terms And Continue" emphasis={ColoredButtonEmphasis.HIGH} backgroundColor={theme.colors.brand} color={theme.colors.lightText} disabled={!termsAccepted || !emailAccepted} onClick={() => acceptTerms()}/>
                            </div>
                        )}
                </div>
                <Footer />
            </div>
        </AuthContainer>
    )
}

export default StaffDisclaimer