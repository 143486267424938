
import client from '../../apollo'
import { gql } from '@apollo/client'
import { LocationModel } from '../../Models';

class PracticeMutations {

    static updatePractice = (location: LocationModel, token) => {
      console.log("PRACTICE TO UPDATE: ", location)
        const mutation = gql`
        mutation updateLocationInformation(
          $locality_id: String!,
          $id: String!, 
          $about: String, 
          $address_line1: String, 
          $address_line2: String, 
          $address_line3: String, 
          $contact_email: String, 
          $contact_name: String, 
          $contact_telephone: String, 
          $location_code: String, 
          $mobile_number: String, 
          $name_value: String, 
          $parking: String, 
          $phone_number: String, 
          $postcode: String, 
          $town: String, 
          $trave_info: String) {
          updateLocationInformation(locality_id: $locality_id, id: $id, about: $about,
          address_line1: $address_line1,
          address_line2: $address_line2,
          address_line3: $address_line3,
          contact_email: $contact_email,
          contact_name: $contact_name,
          contact_telephone: $contact_telephone,
          location_code: $location_code,
          mobile_number: $mobile_number,
          name_value: $name_value,
          parking: $parking,
          phone_number: $phone_number,
          postcode: $postcode,
          town: $town,
          travel_info: $trave_info) {
            success
            error
          }
        }
        `
        return new Promise((resolve, reject) => {
            client.mutate({ 
                mutation: mutation,
                variables: location,
                context: {headers: {"Authorization": token}}})
                .then((response) => {
                    if (response.data.updateLocationInformation.success  === true) {
                        resolve(response)
                    } else {
                        reject(response)
                    }
                })
        })
    }
}


export default PracticeMutations