import { gql } from "@apollo/client";

const notificationsQuery = gql`
query notifications {
    notifications {
      id
      read
      seen
      timestamp
      type
      data
    }
  }
`

export { 
    notificationsQuery
}