import { useAuth0 } from '@auth0/auth0-react'
import React, { FunctionComponent, useEffect, useState } from 'react'
import Footer from '../../components/Footer'
import LoadingSpinner from '../../components/loadingSpinner'
import NavBarNew from '../../components/NavBarNew'
import Text from '../../components/text'
import AuthContainer from '../../container/AuthContainer'
import client from '../../libary/apollo'
import { SelectedTab, StaffModel, StaffTypeModel } from '../../libary/Models'
import EditAccountForm from './Form'
import { getMe, updateUserInfo } from './query'
import './styles.css'
interface AccountInfoProps {

}
const EditAccount: FunctionComponent<AccountInfoProps> = (props: AccountInfoProps) => {
    const { getAccessTokenSilently } = useAuth0()
    const [me, setMe] = useState<StaffModel | null>(null)
    const [staffTypes, setStaffTypes] = useState<StaffTypeModel[] | null>(null)

    useEffect(() => {
        (async () => {
            const token = await getAccessTokenSilently();
            client.query({
                query: getMe,
                context: {
                    headers: {
                        authorization: token
                    }
                }

            }).then((response) => {
                setMe(response.data.me)
                setStaffTypes(response.data.getStaffTypes)
            })
        })()
    }, [getAccessTokenSilently])

    const submitForm = (newMe: StaffModel) => {
        getAccessTokenSilently().then((token) => {
            client.mutate({
                mutation: updateUserInfo,
                variables: {
                    staff_type: newMe.staff_type,
                    title: newMe.title,
                    name_value: newMe.name_value,
                    email: newMe.email,
                    bio: newMe.bio,
                    gmc: newMe.GMC_number
                },
                context: {
                    headers: {
                        authorization: token
                    }
                }
            }).then((response) => {
                if (response.data.updateUserInfo.success === true) {
                    window.location.href = "/"
                }
            })
        })
    }

    return (
        <AuthContainer>
            <div className='edit-account-body'>
                <NavBarNew hideButtons={true} selectedTab={SelectedTab.None} setTab={() => { }} />
                {me !== null && (
                    <div className='mainBody'>
                        <div className='editAccountFormContainer'>
                            <Text type='title' text='We need some information about you' />
                            {staffTypes != null && <EditAccountForm defaultMe={me} submitForm={submitForm} staffTypes={staffTypes}/>}
                        </div>
                    </div>
                )}
                {me === null && <LoadingSpinner />}
                <Footer />
            </div>
        </AuthContainer>
    )
}

export default EditAccount