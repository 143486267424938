import { gql } from "@apollo/client";

const staffPendingApprovalQuery = gql`
query staffPendingApproval {
    staffPendingApproval {
      id
      name_value
      approved
    }
  }
`

export { 
    staffPendingApprovalQuery
}