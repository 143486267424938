import styled from 'styled-components'
import { SessionModel } from '../../libary/Models'

export interface DayInfoProps {
    width?: string
    height?: string
    date: Date
    cardClicked: Function
    filterFilled?: boolean
    filterPending?: boolean
    shortDate?: boolean
    close?: Function
    LOCALITY_ID?: string
    LOCATION_ID?: string
    EMPLOYER_ID?: string
    reload?: boolean
    recurrenceEnabled?: boolean
    recurringSessions?: SessionModel[]
    staff_type?: string
    hideSessionStatus?: boolean
}

export interface ContainerProps {
    width: string
}

export const Header = styled.div`
    height: 48px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 24px;
    border-radius: 8px 8px 0px 0px;
`

export const ListContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: scroll;
    margin-top: 16px;
`
