import { FC, useEffect, useState } from 'react'
import LoadingSpinner from '../../../components/loadingSpinner/LoadingSpinner'
import { StaffModel } from '../../../libary/Models'
import StaffInformation from '../../staffInformation'
import { getStaffMember } from './queries'

interface StaffMemberTabProps {
    id: string
    sessionId: string
    token: string
    removeStaffMember: () => void
}

const StaffMemberTab: FC<StaffMemberTabProps> = ({
    id,
    sessionId,
    token,
    removeStaffMember,
}) => {
    const [staff, setStaff] = useState<StaffModel | undefined>(undefined)

    useEffect(() => {
        ;(async () => {
            const result = await getStaffMember(id, token)
            if (result.isOk) {
                setStaff(result.value)
            }
        })()
    }, [])

    return (
        <div style={{ flex: 1, overflow: 'scroll' }}>
            {!staff && <LoadingSpinner />}
            {staff && (
                <StaffInformation
                    staff={staff}
                    removeCallback={() => {
                        removeStaffMember()
                    }}
                />
            )}
        </div>
    )
}

export default StaffMemberTab
