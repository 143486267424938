import { gql } from "@apollo/client";

const getEmployersAndLocationsQuery = gql`
query getEmployersAndLocations($localityId: String) {
    getEmployers(locality_id: $localityId) {
      id
      name_value
      employer_code
      employer_type
    }

    getLocations(locality_id: $localityId, can_create_session: false) {
        id
        name_value
        location_code
        locality_id
      }
  }
`

const getLocalitiesQuery = gql`
query getLocalities {
    getLocalities {
      id
      name_value
    }
  }
`

export {
    getEmployersAndLocationsQuery,
    getLocalitiesQuery
}