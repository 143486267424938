import { gql } from '@apollo/client'
import { Result } from 'true-myth'
import { StaffModel } from '../../../libary/Models'
import query from '../../../libary/network/apollo'

const staffListQuery = gql`
    query staff($staffType: String) {
        staff(staff_type: $staffType) {
            id
            name_value
            staff_type
            image
        }
    }
`

const getStaffMemberQuery = gql`
    query staffById($id: String!) {
        staffById(id: $id) {
            id
            name_value
            staff_type
            title
            GMC_number
            image
            bio
            approved
            email
            documents {
                id
                approved
                name_value
                staff_id
                staff_name
            }
            workExperience {
                id
                name_value
            }
            itSystems {
                id
                name_value
            }
            languages {
                id
                name_value
                short_name
            }
            qualifications {
                id
                name_value
            }
        }
    }
`

const staffAvailabilityCheckQuery = gql`
    query StaffAvailabilityCheck($sessionDate: String!, $staffId: String) {
        staffAvailabilityCheck(session_date: $sessionDate, staffId: $staffId) {
            available
        }
    }
`

const getStaffList = (
    staffType: string,
    token: string
): Promise<Result<StaffModel[], Error>> => {
    return query(staffListQuery, token, 'staff', { staffType })
}

const getStaffMember = (
    id: string,
    token: string
): Promise<Result<StaffModel, Error>> => {
    return query(getStaffMemberQuery, token, 'staffById', { id })
}

const staffAvailabilityCheck = async (
    input: {
        sessionDate: string
        staffId?: string
    },
    token: string
): Promise<Result<{ available: boolean }, Error>> => {
    return query(
        staffAvailabilityCheckQuery,
        token,
        'staffAvailabilityCheck',
        {
            sessionDate: input.sessionDate,
            staffId: input.staffId,
        },
        'network-only'
    )
}

export { getStaffList, getStaffMember, staffAvailabilityCheck }
