import { gql } from "@apollo/client"

const getMe = gql`
query {

    getStaffTypes {
        id
        name_value
    }
    
    me {
    id
    name_value
    staff_type
    title
    GMC_number
    image
    bio
    documents {
        id
        name_value
        staff_name
        staff_id
        approved
    }
    workExperience {
        id
        name_value
    }
    itSystems{
        id
        name_value
    }
    languages{
        id
        name_value
    }
    qualifications{
        id
        name_value
    }
    }
}
`

const updateUserInfo = gql`
mutation($title: String, $name_value: String, $email: String, $bio: String, $gmc: String, $staff_type: String) {
    updateUserInfo(title: $title, name_value: $name_value, email: $email, bio: $bio, GMC: $gmc, staff_type: $staff_type) {
      id
      success
      error
    }
  }
`

export {
    getMe,
    updateUserInfo
}