import { gql } from "@apollo/client";

const approveMutation = gql`
mutation approveUser($staffId: String!, $approve: Boolean!) {
    approveUser(staff_id: $staffId, approve: $approve) {
      success
    }
  }
`

export { 
    approveMutation
}