import { FunctionComponent, useState } from "react";
import ColoredButton, { ColoredButtonEmphasis } from "../../../components/colored-button";
import StaffList from "../../staffList";
import './styles.css'

interface AssignStaffSectionProps {
    select: Function
    setHidePending?: Function
}

const AssignStaffSection: FunctionComponent<AssignStaffSectionProps> = (props: AssignStaffSectionProps) => {
    const [showStaffList, setShowStaffList] = useState<boolean>(false)

    const showHideStaffList = (show: boolean) => { 
        setShowStaffList(show)
        props.setHidePending(show)
    }

    if (showStaffList  === false) {
        return (
            <div className="assign-staff-button-container">
                <ColoredButton emphasis={ColoredButtonEmphasis.HIGH} text="Assign Staff" color='white' backgroundColor='#692068' onClick={() => showHideStaffList(true)} />
            </div>
        )
    } else {
        return (
            <div className="assign-staff-container">
                <div className='assign-staff-back-button' onClick={() => showHideStaffList(false)}>Back</div>
                <div className='assign-staff-list-container'>
                    <StaffList select={(staff) => props.select(staff)} />
                </div>
            </div>
        )
    }
}

export default AssignStaffSection