import {
    AddRounded,
    CancelRounded,
    CheckRounded,
    EditRounded,
    RepeatRounded,
} from '@mui/icons-material'
import {
    Button,
    Checkbox,
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material'
import { weekdays } from 'moment'
import { FC, useState } from 'react'
import {
    RecurrenceFrequency,
    RecurrenceSubModel,
} from '../../../../../libary/Models'
import MuiDatePicker from '../../../../MuiWrappers/MuiDatePicker/MuiDatePicker'
import MuiDropdown from '../../../../MuiWrappers/MuiDropdown/MuiDropdown'
import { renderRecurrenceItemText } from '../../helpers'

interface CreateSessionScheduleProps {
    sessionDate: Date
    sessionScheduleUpdated: (
        start_date: Date,
        end_date: Date,
        models: RecurrenceSubModel[]
    ) => void
    skipSchedule: () => void
    goBack: () => void
}

const weekDays = weekdays().map((day, ind) => {
    return { id: `${ind}`, name_value: day }
})

const dates = [...Array(32).keys()].map((item) => {
    return { id: `${item}`, name_value: `${item}` }
})
dates.shift()

const CreateSessionSchedule: FC<CreateSessionScheduleProps> = ({
    sessionDate,
    sessionScheduleUpdated,
    skipSchedule,
    goBack,
}) => {
    const [startDate, setStartDate] = useState<Date>(sessionDate)
    const [endDate, setEndDate] = useState<Date>(sessionDate)
    const [isScheduledSession, setIsScheduledSession] = useState(false)
    const [recurrenceModels, setRecurrenceModels] = useState<
        RecurrenceSubModel[]
    >([])
    const [addingScheduleItem, setAddingScheduleItem] = useState<
        RecurrenceSubModel | undefined
    >(undefined)

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Button
                    variant='text'
                    onClick={() => setIsScheduledSession(!isScheduledSession)}>
                    <Checkbox checked={isScheduledSession} />
                    <Typography>Is this a scheduled session?</Typography>
                </Button>
            </Grid>
            {isScheduledSession === true && (
                <>
                    <Grid item xs={12}>
                        <Typography variant='h6'>Schedule:</Typography>
                    </Grid>
                    <Divider />
                    <Grid item xs={6}>
                        <MuiDatePicker
                            label='Start Date'
                            value={sessionDate}
                            onChange={(date: Date) => {
                                setStartDate(date)
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <MuiDatePicker
                            label='End Date'
                            value={sessionDate}
                            onChange={(date: Date) => {
                                setEndDate(date)
                            }}
                        />
                    </Grid>
                    <Divider />
                </>
            )}
            <Grid item xs={12}>
                <List>
                    {isScheduledSession === true &&
                        recurrenceModels.length === 0 && (
                            <ListItem key='item-no-schedule'>
                                <ListItemText>
                                    There is no schedule for this session
                                </ListItemText>
                            </ListItem>
                        )}
                    {isScheduledSession === true &&
                        recurrenceModels.length > 0 && (
                            <>
                                {recurrenceModels.map((recc, ind) => {
                                    return (
                                        <ListItem key={ind}>
                                            <ListItemIcon>
                                                <RepeatRounded />
                                            </ListItemIcon>
                                            <ListItemText>
                                                {renderRecurrenceItemText(recc)}
                                            </ListItemText>
                                            <IconButton
                                                onClick={() => {
                                                    const newRecurrenceModels =
                                                        [
                                                            ...recurrenceModels,
                                                        ].filter(
                                                            (item) =>
                                                                item.frequency !==
                                                                    recc.frequency &&
                                                                item.date !==
                                                                    recc.date &&
                                                                item.day !==
                                                                    recc.day
                                                        )
                                                    setRecurrenceModels(
                                                        newRecurrenceModels
                                                    )
                                                }}>
                                                <CancelRounded />
                                            </IconButton>
                                        </ListItem>
                                    )
                                })}
                            </>
                        )}
                    {addingScheduleItem && (
                        <ListItem
                            key='item-adding-schedule'
                            sx={{
                                borderColor: 'var(--primary-blue-color)',
                                borderWidth: '1px',
                                borderStyle: 'solid',
                            }}>
                            <ListItemIcon>
                                <EditRounded />
                            </ListItemIcon>
                            <Stack
                                direction='row'
                                spacing={2}
                                width='100%'
                                alignItems='center'
                                justifyContent='space-between'>
                                <Stack direction='column'>
                                    <Stack
                                        direction='row'
                                        alignItems='center'
                                        onClick={() => {
                                            setAddingScheduleItem({
                                                ...addingScheduleItem,
                                                frequency:
                                                    RecurrenceFrequency.EVERY_DAY,
                                            })
                                        }}>
                                        <Checkbox
                                            checked={
                                                addingScheduleItem.frequency ===
                                                RecurrenceFrequency.EVERY_DAY
                                            }
                                        />
                                        <Typography variant='body1'>
                                            Every Day
                                        </Typography>
                                    </Stack>
                                    <Stack
                                        direction='row'
                                        alignItems='center'
                                        onClick={() => {
                                            setAddingScheduleItem({
                                                ...addingScheduleItem,
                                                frequency:
                                                    RecurrenceFrequency.EVERY_WEEK,
                                            })
                                        }}>
                                        <Checkbox
                                            checked={
                                                addingScheduleItem.frequency ===
                                                RecurrenceFrequency.EVERY_WEEK
                                            }
                                        />
                                        <Typography variant='body1'>
                                            Every Week
                                        </Typography>
                                    </Stack>
                                    <Stack
                                        direction='row'
                                        alignItems='center'
                                        onClick={() => {
                                            setAddingScheduleItem({
                                                ...addingScheduleItem,
                                                frequency:
                                                    RecurrenceFrequency.EVERY_MONTH,
                                            })
                                        }}>
                                        <Checkbox
                                            checked={
                                                addingScheduleItem.frequency ===
                                                RecurrenceFrequency.EVERY_MONTH
                                            }
                                        />
                                        <Typography variant='body1'>
                                            Every Month
                                        </Typography>
                                    </Stack>
                                </Stack>
                                {addingScheduleItem.frequency ===
                                    RecurrenceFrequency.EVERY_WEEK && (
                                    <Stack
                                        direction={'column'}
                                        spacing={1}
                                        sx={{ flex: 1 }}>
                                        <Typography variant='body1'>
                                            Select Day
                                        </Typography>
                                        <MuiDropdown
                                            sx={{ flexGrow: 2 }}
                                            label='Day'
                                            items={weekDays}
                                            defaultValue=''
                                            onChange={(value) => {
                                                console.log('VALUE: ', value)
                                                setAddingScheduleItem({
                                                    ...addingScheduleItem,
                                                    day: parseInt(value),
                                                })
                                            }}
                                            error=''
                                        />
                                    </Stack>
                                )}
                                {addingScheduleItem.frequency ===
                                    RecurrenceFrequency.EVERY_MONTH && (
                                    <Stack
                                        direction={'column'}
                                        spacing={1}
                                        sx={{ flex: 1 }}>
                                        <Typography variant='body1'>
                                            Select Date
                                        </Typography>
                                        <MuiDropdown
                                            label='Date'
                                            items={dates}
                                            onChange={(value) => {
                                                setAddingScheduleItem({
                                                    ...addingScheduleItem,
                                                    date: value,
                                                })
                                            }}
                                            error=''
                                        />
                                    </Stack>
                                )}
                                <Stack direction='row'>
                                    <IconButton
                                        onClick={() => {
                                            setRecurrenceModels([
                                                ...recurrenceModels,
                                                addingScheduleItem,
                                            ])
                                            setAddingScheduleItem(undefined)
                                        }}>
                                        <CheckRounded />
                                    </IconButton>
                                    <IconButton
                                        onClick={() => {
                                            setAddingScheduleItem(undefined)
                                        }}>
                                        <CancelRounded />
                                    </IconButton>
                                </Stack>
                            </Stack>
                        </ListItem>
                    )}
                    {isScheduledSession === true &&
                        recurrenceModels.filter(
                            (item) =>
                                item.frequency === RecurrenceFrequency.EVERY_DAY
                        ).length === 0 && (
                            <ListItem key='item-add-button'>
                                <ListItemButton
                                    onClick={() => {
                                        setAddingScheduleItem({
                                            frequency:
                                                RecurrenceFrequency.EVERY_DAY,
                                        })
                                    }}>
                                    <ListItemIcon>
                                        <AddRounded />
                                    </ListItemIcon>
                                    <ListItemText>
                                        Add Schedule Item
                                    </ListItemText>
                                </ListItemButton>
                            </ListItem>
                        )}
                </List>
            </Grid>
            <Grid item xs={6} marginTop={2}>
                <Button fullWidth variant='outlined' onClick={() => goBack()}>
                    Back
                </Button>
            </Grid>
            <Grid item xs={6} marginTop={2}>
                <Tooltip
                    title='You are adding a schedule item, press the tick or X to continue'
                    hidden={addingScheduleItem === undefined}>
                    <Button
                        fullWidth
                        variant='contained'
                        disabled={addingScheduleItem !== undefined}
                        onClick={() => {
                            if (recurrenceModels.length > 0) {
                                sessionScheduleUpdated(
                                    startDate,
                                    endDate,
                                    recurrenceModels
                                )
                            } else {
                                skipSchedule()
                            }
                        }}>
                        {isScheduledSession === true &&
                            recurrenceModels.length !== 0 && (
                                <span>Create Scheduled Session</span>
                            )}
                        {((isScheduledSession === true &&
                            recurrenceModels.length === 0) ||
                            isScheduledSession === false) && <span>Skip</span>}
                    </Button>
                </Tooltip>
            </Grid>
        </Grid>
    )
}

export default CreateSessionSchedule
