import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import Footer from '../../components/Footer';
import LoadingSpinner from '../../components/loadingSpinner';
import NavBarNew from '../../components/NavBarNew';
import client from '../../libary/apollo';
import { SelectedTab, SessionModel } from '../../libary/Models';
import { sessionQuery } from './queries';
import SessionInformation from '../sessionInformation'
import './styles.css';
import useToken from '../../libary/hooks/useToken';
import AuthContainer from '../../container/AuthContainer';
import { ThemeContext } from '../../App';

const sessionId = (id) => {
    return `SESSION#${id}`
}
const Session: FunctionComponent = () => {
    const theme = useContext(ThemeContext)
    // const searchParams = useSearchParams()
    const id = new URLSearchParams(window.location.search).get("id")
    // const id =""
    const [session, setSession] = useState<SessionModel | null>(null)
    const token = useToken()
    const [loading, setLoading] = useState<boolean>(false)
    useEffect(() => {
        if (token === null) { return }
        setLoading(true)
        client.query({
            query: sessionQuery,
            variables: {
                id: sessionId(id)
            },
            context: {
                headers: {
                    authorization: token
                }
            }

        }).then((response) => {
            setSession(response.data.session)
            setLoading(false)
        })
    }, [id, token])

    return (
        <AuthContainer>
            <div className='sessionPageContainer' style={{backgroundColor: theme.colors.parallaxBackground}}>
                <NavBarNew selectedTab={SelectedTab.None} setTab={() => { }} />
                <div className='mainBody'>
                    {loading && (
                        <LoadingSpinner />
                    )}
                    {session === null && (
                        <div>
                            Session Doesn't exist
                        </div>
                    )}
                    <div className='session-page-body'>
                        {session !== null && (
                            <SessionInformation session={session} sessionDeletedCallback={() => {
                                window.history.pushState(null, null, '/')
                                window.location.replace('/')
                            }} />
                        )}
                    </div>
                </div>
                <Footer />
            </div>
        </AuthContainer>
    )
}

export default Session