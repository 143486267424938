import { gql } from "@apollo/client";

const accountStateQuery = gql`
query {
    me {
        account_state
    }
}
`

export { 
    accountStateQuery
}