import { gql } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import { useEffect, useState } from 'react'
import LoadingSpinner from '../components/loadingSpinner'
import client from '../libary/apollo'
import { useFeatures } from '../libary/hooks/useFeatures'
import useMe from '../libary/hooks/useMe'

const AuthQuery = gql`
    query {
        me {
            id
            name_value
            account_state
            staff_type
        }
        getFeatures {
            findSessions
            manageRota
            viewStaffList
            approveStaff
            viewAdmins
            editPermissions
            requestPermissions
            canAssignSessions
            viewAnalytics
            newSessionModal
            manageRecurrences
            canOverrideAssignSession
        }
    }
`

const AuthContainer = ({ children }) => {
    const { getAccessTokenSilently } = useAuth0()
    const { setFeatures } = useFeatures()
    const { setMe } = useMe()
    const [loading, setLoading] = useState<boolean>(true)

    useEffect(() => {
        ;(async () => {
            const token = await getAccessTokenSilently()
            client
                .query({
                    query: AuthQuery,
                    context: {
                        headers: {
                            Authorization: token,
                        },
                    },
                })
                .then((response) => {
                    if (response.data.me.account_state === 'ACCOUNT_INFO') {
                        if (window.location.pathname !== '/editaccount') {
                            window.location.replace('/editaccount')
                        }
                    }
                    setMe(response.data.me)
                    setFeatures(response.data.getFeatures)
                    setLoading(false)
                })
        })()
    }, [])

    if (loading === true) {
        return (
            <div className='auth-container'>
                <LoadingSpinner />
            </div>
        )
    }
    if (loading === false) {
        return <div className='auth-container'>{children}</div>
    }
}

export default AuthContainer
