import { gql } from '@apollo/client'
import moment from 'moment'
import { Result } from 'true-myth'
import { RecurrenceModel } from '../../../libary/Models'
import { MutationResponse, mutation } from '../../../libary/network/apollo'

export interface CreateRecurrenceInput
    extends Omit<RecurrenceModel, 'id' | 'SK' | 'session_template'> {
    session_template: CreateSessionInput
}

export interface CreateSessionInput {
    LOCATION_ID: string
    location_name: string
    EMPLOYER_ID: string
    employer_name: string
    LOCALITY_ID: string
    locality_name: string
    it_system_id: string
    staff_id: string
    staff_type: string
    session_date: string
    start_time: number
    end_time: number
    pricePerHour: number
    break?: number
    another_doctor: boolean
    nurse: boolean
    catchup: boolean
    extra_information?: string
    session_description: string
    travel_info: string
    parking_info: string
    name_value: string
    payment_process: string
    payment_process_info: string
    length_of_appointments: string
}

const start = moment()
start.set('hours', 9)
start.set('minutes', 0)
const end = moment()
end.set('hours', 17)
end.set('minutes', 0)
export const EmptyCreateSessionInput: CreateSessionInput = {
    LOCATION_ID: '-1',
    location_name: '',
    EMPLOYER_ID: '-1',
    employer_name: '',
    LOCALITY_ID: '-1',
    locality_name: '',
    it_system_id: '-1',
    staff_id: '',
    staff_type: '-1',
    session_date: '',
    start_time: start.unix() * 1000,
    end_time: end.unix() * 1000,
    pricePerHour: -1,
    another_doctor: false,
    nurse: false,
    catchup: false,
    travel_info: '',
    parking_info: '',
    name_value: '',
    payment_process: '4_WEEKS',
    payment_process_info: '',
    length_of_appointments: '-1',
    session_description: '',
}

const createSessionMutation = gql`
    mutation CreateSession($session: SessionInput!) {
        createSession(session: $session) {
            success
            id
            error
        }
    }
`

const createRecurrenceMutation = gql`
    mutation CreateRecurrence($input: CreateRecurrenceInput) {
        createRecurrence(input: $input) {
            error
            id
            success
        }
    }
`

const createSession = (
    session: CreateSessionInput,
    token: string
): Promise<Result<MutationResponse, Error>> => {
    return mutation(createSessionMutation, token, 'createSession', {
        session: {
            ...session,
        },
    })
}

const createRecurrence = (
    input: CreateRecurrenceInput,
    token: string
): Promise<Result<MutationResponse, Error>> => {
    return mutation(createRecurrenceMutation, token, 'createRecurrence', {
        input,
    })
}

export { createRecurrence, createSession }
