import { gql } from "@apollo/client";

const markAllReadMutation = gql`
mutation markNotificationsRead {
    markNotificationsRead {
      success
    }
  }
`

export { 
    markAllReadMutation
}