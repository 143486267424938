import React, { FunctionComponent } from 'react'
import './styles.css'

export enum ColoredButtonEmphasis {
    LOW, MEDIUM, HIGH
}

interface ColoredButtonProps {
    text: string;
    onClick?: Function;
    color: string;
    backgroundColor: string;
    height?: string;
    width?: string;
    border?: string;
    margin?: string;
    padding?: string;
    emphasis?: ColoredButtonEmphasis;
    type?;
    borderRadius?;
    disabled?: boolean;
}

const ColoredButton: FunctionComponent<ColoredButtonProps> = (props: ColoredButtonProps) => {
    const getButtonEmphasis = (): Object => {
        if (props.emphasis !== undefined) {
            switch (props.emphasis) {
                case ColoredButtonEmphasis.HIGH:
                    return {
                        margin: props.margin ? props.margin : '0',
                        padding: props.padding ? props.padding : '8px',
                        backgroundColor: props.backgroundColor,
                        height: props.height,
                        width: props.width ?? 'auto',
                        color: props.color,
                        borderRadius: props.borderRadius || 0,
                        border: `1px solid ${props.border}`
                    }
                case ColoredButtonEmphasis.MEDIUM:
                    return {
                        margin: props.margin ? props.margin : '0',
                        padding: props.padding ? props.padding : '0',
                        backgroundColor: props.backgroundColor,
                        height: props.height,
                        color: props.color,
                        width: props.width,
                        border: `1px solid ${props.border}`,
                        borderRadius: props.borderRadius || 0
                    }
                case ColoredButtonEmphasis.LOW:
                    return {
                        margin: props.margin ? props.margin : '0',
                        padding: props.padding ? props.padding : '0',
                        backgroundColor: 'transparent',
                        color: props.color,
                        height: props.height,
                        width: props.width,
                        border: `1px solid ${props.border}`
                    }
            }
        } else {
            return {
                margin: props.margin ? props.margin : '0',
                padding: props.padding ? props.padding : '0',
                backgroundColor: props.color,
                height: props.height,
                width: props.width,
                border: `1px solid ${props.border}`
            }
        }
    }
    return (
        <button disabled={props.disabled ? props.disabled : false} type={props.type ? props.type : 'button'} className={props.disabled ? props.disabled === true ? "coloredButton disabled" : "coloredButton" : "coloredButton"} style={getButtonEmphasis()} onClick={() => props.onClick ? props.onClick() : {}}>
            {props.text}
        </button>
    )
}

export default ColoredButton;