import React, { useEffect } from 'react'
import { splitFormProps, useField } from 'react-form'
function SelectField(props) {
    const [field, fieldOptions, { options, ...rest }] = splitFormProps(props);
    const {
      value = "",
      setValue,
      meta: { error, isTouched }
    } = useField(field, fieldOptions);
  
  
    useEffect(() => {
      setValue(props.value)
    }, [])
    const handleSelectChange = e => {
      setValue(e.target.value);
    };
  
    return (
      <>
        <select className='editAccountSelect' {...rest} value={value} onChange={handleSelectChange}>
          {options.map(option => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>{" "}
        {isTouched && error ? <em>{error}</em> : null}
      </>
    );
  }

  export default SelectField