import { gql } from '@apollo/client'

const getMyPendingSessionsQuery = gql`
    query {
        myPendingSessions {
            id
            name_value
            session_date
            session_start_time
            session_end_time
            session_location_name
        }
    }
`

export { getMyPendingSessionsQuery }
