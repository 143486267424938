import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { FC } from 'react'

interface MuiTimePickerProps {
    label: string
    value?: number
    onChange?: (time: number) => void
}

const MuiTimePicker: FC<MuiTimePickerProps> = ({ label, value, onChange }) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
                sx={{ width: '100%' }}
                label={label}
                value={dayjs(value)}
                disabled={onChange === undefined}
                onChange={(time: any) => {
                    onChange(time.unix() * 1000)
                }}
            />
        </LocalizationProvider>
    )
}

export default MuiTimePicker
