import { gql } from "@apollo/client";

const promoteUserToAdminMutation = gql`
mutation promoteUserToAdmin($staffId: String!){
    promoteUserToAdmin(staffId: $staffId) {
      error
      success
    }
  }
`

const updateStaffTypeMutation = gql`
mutation updateStaffType($staffId: String!, $staffType: String!) { 
  updateStaffType(staff_id: $staffId, staff_type: $staffType) {
    success
  }
}
`

export { 
    promoteUserToAdminMutation,
    updateStaffTypeMutation
}