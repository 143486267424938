import { gql } from "@apollo/client";

const approvePermissionRequestMutation = gql`
mutation approvePermission($user_id: String!, $permission: String!, $object_id: String!) {
    approvePermission(user_id: $user_id, permission: $permission, object_id: $object_id) {
      success
      error
    }
  }
`

const rejectPermissionRequestMutation = gql`
mutation rejectPermission($user_id: String!, $permission: String!, $object_id: String!) {
    rejectPermission(user_id: $user_id, permission: $permission, object_id: $object_id) {
      success
      error
    }
  }
`

export {
    approvePermissionRequestMutation,
    rejectPermissionRequestMutation
}