import React, { FunctionComponent, useState } from 'react'
import './styles.css'
import editIcon from '../../../../assets/icons/ic_edit_black_text.svg'
import profile from '../../../../assets/placeholder_profile.png'
import Tick from '../../../../assets/icons/ic_check_circle.svg'
import Pending from '../../../../assets/icons/ic_pending.svg'

interface StaffInfoHeaderProps {
    image?: string
    name: string
    gmcNumber: string
    canEdit: boolean
    imageClicked?: Function
    approved: boolean
    canChangeStaffType: boolean
    staff_type: string
    updateStaffType: Function
    email?: string
}

const StaffInfoHeader: FunctionComponent<StaffInfoHeaderProps> = (props: StaffInfoHeaderProps) => {
    const [image, setImage] = useState(props.image ? props.image : profile)

    const renderStaffType = () => {
        if(props.canChangeStaffType) {
            return (
                <div className='profile-header-staff-type-label'>
                    {/* Staff Type: 
                    <select className='staff-info-type-select' onChange={(event) => {
                        props.updateStaffType(event.target.value)
                    }}
                    defaultValue={props.staff_type}>
                            {StaffTypes.map(option => (
                                <option key={option} value={option}>
                                {option}
                                </option>
                            ))}
                    </select> */}
                </div>
            )
        } else {
            return (
                <div className='profile-header-staff-type-label'>
                    Staff Type: {props.staff_type}
                </div>
            )
        }
        
    }

    return (
        <div className="profile-header-left-top">
            <img src={image} className="profile-header-image" onClick={() => props.imageClicked()} onMouseOver={() => {if(props.canEdit) {setImage(editIcon)} }} onMouseLeave={() => {if(props.canEdit){setImage(props.image ? props.image : profile)}}}/>
            <div className="profile-header-stack">
                <div className="profile-header-name">
                    {props.name}
                    <img className='profile-header-approval' src={props.approved === true ? Tick : Pending}/>
                </div>
                <div className="profile-header-name">
                    GMC No. {props.gmcNumber !== undefined ? props.gmcNumber : "N/A"}
                </div>
                <div className="profile-header-name">
                    Email: {props.email ? props.email : "N/A"}
                </div>
                {renderStaffType()}
            </div>
        </div>
    )
}

export default StaffInfoHeader