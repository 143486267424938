import { FunctionComponent } from 'react'

import './styles.css'

interface PracticeListAddButtonProps {
    select?: Function
}

const PracticeListAddButton: FunctionComponent<PracticeListAddButtonProps> = (props: PracticeListAddButtonProps) => {
    return (
        <div className="list-add-button-container" onClick={() => { props.select() }}>
            <div className="list-add-button-text">Add practice</div>
        </div>
    )
}

export default PracticeListAddButton;