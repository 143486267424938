import moment from 'moment'
import { SessionModel } from '../../libary/Models'

import { RepeatRounded } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import { FC } from 'react'
import { isRecurringSession } from '../../libary/models/SessionModel'
import './styles.css'

interface SessionCardProps {
    model: SessionModel
    cardClicked: Function
    hideSessionStatus?: boolean
}

const SessionCard: FC<SessionCardProps> = (props: SessionCardProps) => {
    const cardClicked = () => {
        props.cardClicked(props.model.id)
    }

    return (
        <div className='sessionCard' onClick={() => cardClicked()}>
            <div
                className={`status_${
                    props.hideSessionStatus && props.hideSessionStatus === true
                        ? 'no_applicants'
                        : props.model.session_status
                }`}>
                {/* Session #{props.model.id} */}
            </div>
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    fontSize: '12px',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '8px',
                    color: 'black',
                    fontWeight: 'normal',
                }}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginBottom: '8px',
                    }}>
                    <div>
                        <p className='p'>
                            {moment(props.model.session_date).format(
                                'dddd Do MMMM'
                            )}
                        </p>
                        <p className='p'>
                            {moment(props.model.start_time).format('HH:mm')}-
                            {moment(props.model.end_time).format('HH:mm')}
                        </p>
                    </div>
                    <div style={{ float: 'right' }}>
                        <p className='p'>
                            Staff Type: {props.model.staff_type}
                        </p>
                        <p className='p'>
                            £{props.model.pricePerHour} per hour
                        </p>
                    </div>
                    {isRecurringSession(props.model) && (
                        <div style={{ float: 'right' }}>
                            <Tooltip title='This session is part of a Recurrence'>
                                <RepeatRounded />
                            </Tooltip>
                        </div>
                    )}
                </div>
                <div>
                    <p className='p'>
                        {props.model.it_system.name_value
                            ? props.model.it_system.name_value
                            : ''}
                    </p>
                    <p className='p' style={{ paddingBottom: '5px' }}>
                        {props.model.break ? 'Break' : 'No break'}
                    </p>
                    <p className='p'>
                        {props.model.location.name_value
                            ? props.model.location.name_value
                            : ''}
                    </p>
                    {/* <p className = "p">{this.renderStaffNameSection()}</p> */}
                </div>
            </div>
        </div>
    )
}

export default SessionCard
