import { FunctionComponent } from "react";
import { EmployerModel } from "../../../libary/Models";
import './styles.css'

interface EmployerListProps {
    employers: EmployerModel[]
    employerSelected: Function
    requestPermissionClicked: Function
}

const EmployerList: FunctionComponent<EmployerListProps> = (props: EmployerListProps) => {

    const renderEmployers = () => {
        return props.employers.map((emp) => {
            return (
                <div className="employer-list-item" key={emp.id}>
                    <div className="employer-list-item-text" onClick={() => props.employerSelected(emp)}>
                        {emp.name_value}
                    </div>                
                    <div className="employer-list-item-request-text" onClick={() => props.requestPermissionClicked(emp)}>
                        Request Permission
                    </div>
                </div>
            )
        })
    }

    return (
        <div className="employer-list">
            {props.employers.length === 0 && <div>You already have Create Session permissions for all Employers</div>}
            {renderEmployers()}
        </div>
    )
}

export default EmployerList