import React, { FunctionComponent } from 'react'
import { NewModal } from '../../NewModal'

import './styles.css'

interface AddSystemModalProps {
    title: string
    yes?: string
    no?: string
    show: boolean
    closeModal: Function
    confirmAction: Function
}

const GeneralConfirmModal: FunctionComponent<AddSystemModalProps> = (props: AddSystemModalProps) => {

    return (
        <NewModal show={props.show} close={props.closeModal}>
                <div className='confirm-delete-modal-container'>
                    <p className='confirm-delete-modal-title'>{props.title}</p>
                    <div className='confirm-delete-button-container'>
                        <div className={'confirm-delete-button'} onClick={() => {props.confirmAction()}}>{props.yes ? props.yes : 'Yes'}</div>
                        <div className={'confirm-delete-button no'} onClick={() => {props.closeModal()}}>{props.no ? props.no : 'No'}</div>
                    </div>
                </div>
        </NewModal>
    )
}

export default GeneralConfirmModal