import { gql } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import { ThemeProvider } from '@emotion/react'
import { Box, Button, Stack, createTheme } from '@mui/material'
import { FunctionComponent, useEffect, useState } from 'react'
import { Cookies } from 'react-cookie'
import Footer from '../../../components/Footer'
import MuiDropdown from '../../../components/MuiWrappers/MuiDropdown/MuiDropdown'
import NavBarNew from '../../../components/NavBarNew'
import CalendarTemp from '../../../components/calendarTemp'
import DayInfo from '../../../components/day-info'
import DayInfoModalMobile from '../../../components/modals/DayInfoModalMobile'
import SessionModalNew from '../../../components/modals/SessionModalNew/SessionModalNew'
import AuthContainer from '../../../container/AuthContainer'
import {
    LocalityModel,
    LocationModel,
    SelectedTab,
    SessionModel,
} from '../../../libary/Models'
import client from '../../../libary/apollo'
import useFeatures from '../../../libary/hooks/useFeatures'
import useMe from '../../../libary/hooks/useMe'
import { isRecurringSession } from '../../../libary/models/SessionModel'
import { legacyTheme } from '../../../themes'
import { getLocalitiesQuery, getLocationsQuery } from './queries'
import './styles.css'

interface FindASessionProps {
    cookies?: Cookies
}

const findSessionQuery = gql`
    query search(
        $month: String
        $year: String
        $LOCALITY_ID: String
        $LOCATION_ID: String
        $EMPLOYER_ID: String
    ) {
        sessions(
            month: { month: $month, year: $year }
            filterFilled: true
            filterPending: true
            LOCALITY_ID: $LOCALITY_ID
            LOCATION_ID: $LOCATION_ID
            EMPLOYER_ID: $EMPLOYER_ID
        ) {
            session_status
            session_date
            RECURRENCE_ID
            location {
                name_value
            }
        }
    }
`

const muiTheme = createTheme({
    palette: {
        primary: {
            main: legacyTheme().colors.secondary,
        },
    },
})

const FindASession: FunctionComponent<FindASessionProps> = (
    props: FindASessionProps
) => {
    const [locality, setLocality] = useState<LocalityModel | undefined>(
        undefined
    )
    const [localities, setLocalities] = useState<LocalityModel[] | null>(null)
    const [locations, setLocations] = useState<LocationModel[] | undefined>(
        undefined
    )
    const [location, setLocation] = useState<LocationModel | undefined>(
        undefined
    )
    const [token, setToken] = useState<string | undefined>(undefined)
    const [reloadCalendar, setReloadCalendar] = useState<boolean>(false)

    const { getAccessTokenSilently } = useAuth0()
    const [selectedDate, setSelectedDate] = useState<Date | null>(null)
    const [selectedSessionId, setSelectedSessionId] = useState<string | null>(
        null
    )
    const [selectedSession, setSelectedSession] = useState<
        SessionModel | undefined
    >(undefined)
    const { features } = useFeatures()
    const { me } = useMe()
    useEffect(() => {
        if (features === null) {
            return
        }
        if (features.findSessions !== true) {
            window.location.replace('/no')
        }
    }, [features])

    useEffect(() => {
        ;(async () => {
            const accessToken = token ? token : await getAccessTokenSilently()
            setToken(accessToken)
            client
                .query({
                    query: getLocalitiesQuery,
                    context: {
                        headers: {
                            Authorization: accessToken,
                        },
                    },
                })
                .then((response) => {
                    setLocalities(response.data.getLocalities)
                    if (response.data.getLocalities.length === 1) {
                        setLocality(response.data.getLocalities[0])
                    }
                })
        })()
    }, [getAccessTokenSilently])

    useEffect(() => {
        if (!locality) {
            return
        }
        client
            .query({
                query: getLocationsQuery,
                variables: {
                    localityId: locality.id,
                },
                context: {
                    headers: {
                        Authorization: token,
                    },
                },
            })
            .then((response) => {
                setLocations(response.data.getLocations)
            })
    }, [locality])

    const closeSessionModal = (reload: boolean) => {
        setSelectedSessionId(null)
        setSelectedSession(null)
        if (reload === true) {
            console.log('RELOAD')
            setReloadCalendar(!reloadCalendar)
        }
    }

    const setDate = (date: Date) => {
        setSelectedDate(date)
    }

    const recurrenceCardClicked = (session: SessionModel) => {
        setSelectedSession(session)
    }

    const cardClicked = (cardId: string) => {
        setSelectedSessionId(cardId)
    }

    const renderFilterBar = () => {
        return (
            <Box
                sx={{
                    paddingLeft: '64px',
                    paddingTop: '16px',
                    paddingBottom: '16px',
                }}>
                <Stack direction='row' spacing={2}>
                    <Box sx={{ width: '400px' }}>
                        <MuiDropdown
                            label='Locality'
                            items={localities}
                            defaultValue={
                                localities.filter(
                                    (item) => item.id === locality?.id
                                )[0]?.id ?? ''
                            }
                            onChange={(value) => {
                                console.log('LOCAL', value)
                                setLocations(undefined)
                                const loc = localities.filter((i) => {
                                    return i.id === value
                                })[0]

                                console.log('LOG: ', loc)
                                setLocality(loc)
                            }}
                            error=''
                            sx={{ width: 'auto' }}
                        />
                    </Box>
                    <Box sx={{ width: '400px' }}>
                        <MuiDropdown
                            label='Location'
                            items={locations}
                            defaultValue={
                                locations?.filter(
                                    (item) => item.id === location?.id
                                )[0]?.id ?? ''
                            }
                            onChange={(value) => {
                                setLocation(
                                    locations.filter((i) => i.id === value)[0]
                                )
                            }}
                            error=''
                        />
                    </Box>
                    <Button
                        variant='contained'
                        onClick={() => {
                            setLocation(undefined)
                            setLocality(undefined)
                            setLocations(undefined)
                        }}>
                        Reset Filters
                    </Button>
                </Stack>
            </Box>
        )
    }

    useEffect(() => {
        console.log('ME: ', me)
    }, [me])

    return (
        <AuthContainer>
            <ThemeProvider theme={muiTheme}>
                <div className='findASessionBody'>
                    <NavBarNew
                        setTab={() => {}}
                        selectedTab={SelectedTab.FindASession}
                    />
                    <div className='mainBody'>
                        {localities !== null && renderFilterBar()}
                        <div className='findASessionContainer'>
                            {me && (
                                <>
                                    <CalendarTemp
                                        canCreate={false}
                                        queryName='sessions'
                                        query={findSessionQuery}
                                        width={
                                            window.innerWidth < 600
                                                ? '95%'
                                                : '62%'
                                        }
                                        setDate={(date: Date) => setDate(date)}
                                        reloadCalendar={reloadCalendar}
                                        createSession={(
                                            date
                                        ) => /*{setcreateSessionDate(date)}*/ {}}
                                        LOCALITY_ID={
                                            locality ? locality.id : undefined
                                        }
                                        LOCATION_ID={
                                            location ? location.id : undefined
                                        }
                                        recurrenceEnabled={true}
                                        filterFilled={true}
                                        filterPending={false}
                                        staff_type={me.staff_type}
                                        hideSessionStatus={true}
                                    />

                                    <div className='find-session-day-info-container'>
                                        <DayInfo
                                            width='25vw'
                                            date={selectedDate ?? new Date()}
                                            cardClicked={(cardId, session) => {
                                                if (
                                                    isRecurringSession(
                                                        session
                                                    ) === true
                                                ) {
                                                    console.log(
                                                        'SESSION: ',
                                                        session
                                                    )
                                                    recurrenceCardClicked(
                                                        session
                                                    )
                                                } else {
                                                    cardClicked(cardId)
                                                }
                                            }}
                                            filterFilled={true}
                                            filterPending={false}
                                            LOCALITY_ID={
                                                locality
                                                    ? locality.id
                                                    : undefined
                                            }
                                            LOCATION_ID={
                                                location
                                                    ? location.id
                                                    : undefined
                                            }
                                            // recurrenceEnabled={true}
                                            staff_type={me.staff_type}
                                            hideSessionStatus={true}
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                        {me &&
                            selectedDate !== null &&
                            window.innerWidth <= 600 && (
                                <DayInfoModalMobile
                                    show={selectedDate !== null}
                                    date={selectedDate}
                                    close={() => {
                                        setSelectedDate(null)
                                    }}
                                    cardClicked={(cardId, session) => {
                                        if (
                                            isRecurringSession(session) === true
                                        ) {
                                            console.log('SESSION: ', session)
                                            recurrenceCardClicked(session)
                                        } else {
                                            cardClicked(cardId)
                                        }
                                    }}
                                    staff_type={me.staff_type}
                                    hideSessionStatus={true}
                                />
                            )}
                        {selectedSessionId !== null && (
                            <SessionModalNew
                                closeModal={(reload: boolean) => {
                                    setSelectedSessionId(null)
                                    if (reload === true) {
                                        window.location.reload()
                                    }
                                }}
                                id={selectedSessionId}
                                show={selectedSessionId !== null}
                            />
                        )}

                        {/* Recurrence Modals */}
                        {selectedSession && (
                            <SessionModalNew
                                show={true}
                                id={''}
                                sessionModel={selectedSession}
                                closeModal={(reload: boolean) => {
                                    closeSessionModal(reload)
                                }}
                            />
                        )}
                    </div>
                    <Footer />
                </div>
            </ThemeProvider>
        </AuthContainer>
    )
}

export default FindASession
