import { FunctionComponent, useContext, useEffect, useState } from 'react'
import { ThemeContext } from '../../App'
import AddIcon from '../../assets/icons/ic_add_circle.svg'
import Footer from '../../components/Footer'
import NavBarNew from '../../components/NavBarNew'
import InviteUserModal from '../../components/modals/InviteUserModal'
import PracticeModal from '../../components/modals/PracticeModal'
import StaffModalNew from '../../components/modals/StaffModalNew/StaffModalNew'
import LocationList from '../../components/practices-list'
import Text from '../../components/text'
import AuthContainer from '../../container/AuthContainer'
import {
    EmptyLocation,
    LocationModel,
    SelectedTab,
    StaffModel,
} from '../../libary/Models'
import useFeatures from '../../libary/hooks/useFeatures'
import StaffList from '../staffList'
import './styles.css'
import { AdminProps } from './types'

const StaffHubs: FunctionComponent<AdminProps> = (props: AdminProps) => {
    const theme = useContext(ThemeContext)

    const [staff, setStaff] = useState<StaffModel | null>(null)
    const [location, setLocation] = useState<LocationModel | null>(null)
    const [showAddUser, setShowAddUser] = useState<boolean>(false)
    const { features } = useFeatures()

    useEffect(() => {
        if (features === null) {
            return
        }
        if (features.viewStaffList !== true) {
            console.log('NO: ', features)
            window.location.replace('/no')
        }
    }, [features])

    const closeModal = () => {
        setStaff(null)
        setLocation(null)
    }

    const selectPractice = (newLocation) => {
        setStaff(null)
        setLocation(newLocation)
    }
    const selectStaff = (newStaff) => {
        setStaff(newStaff)
        setLocation(null)
    }

    return (
        <AuthContainer>
            <div className='adminBody'>
                <NavBarNew
                    setTab={() => {}}
                    selectedTab={SelectedTab.StaffHubs}
                />
                <div className='mainBody'>
                    <div className='adminContainer'>
                        <div className='practiceContainer'>
                            <Text
                                type='header'
                                color='black'
                                text='Locations'
                            />
                            <div
                                className='seperatorLine'
                                style={{
                                    backgroundColor: theme.colors.secondary,
                                }}
                            />
                            <LocationList
                                select={(newLocation) => {
                                    selectPractice(newLocation)
                                }}
                                createPractice={(locality_id) => {
                                    setLocation({
                                        ...EmptyLocation,
                                        locality_id,
                                        location_type: 'PRACTICE',
                                    })
                                }}
                            />
                        </div>
                        <div className='staffContainer'>
                            <div className='staff-container-header'>
                                <Text
                                    type='header'
                                    color='black'
                                    text='Staff'
                                />
                                <img
                                    className='staff-container-header-plus'
                                    src={AddIcon}
                                    onClick={() => setShowAddUser(true)}
                                />
                            </div>
                            <div
                                className='seperatorLine'
                                style={{
                                    backgroundColor: theme.colors.secondary,
                                }}
                            />
                            <StaffList
                                select={(newStaff) => {
                                    selectStaff(newStaff)
                                }}
                            />
                        </div>

                        {location !== null && (
                            <PracticeModal
                                show={location !== null}
                                locationType={'PRACTICE'}
                                locality_id={location.locality_id}
                                location_id={location.id.toString()}
                                closeModal={closeModal}
                                practiceCallback={(location: LocationModel) => {
                                    window.location.reload()
                                }}
                                createPractice={location.id === ''}
                            />
                        )}

                        {staff !== null && (
                            <StaffModalNew
                                open={staff != null}
                                id={staff.id}
                                closeModal={closeModal}
                            />
                        )}
                        {showAddUser === true && (
                            <InviteUserModal
                                show={showAddUser}
                                closeModal={() => setShowAddUser(false)}
                            />
                        )}
                    </div>
                </div>
                <Footer />
            </div>
        </AuthContainer>
    )
}

export default StaffHubs
