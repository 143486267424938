import React, { FunctionComponent, useEffect, useState } from 'react';
import Footer from '../../components/Footer';
import LoadingSpinner from '../../components/loadingSpinner';
import NavBarNew from '../../components/NavBarNew';
import AuthContainer from '../../container/AuthContainer';
import client from '../../libary/apollo';
import useToken from '../../libary/hooks/useToken';
import { SelectedTab, StaffModel } from '../../libary/Models';
import StaffInformation from '../staffInformation';
import { staffQuery } from './queries';
import './styles.css';

const userId = (id) => {
    return `USER#${id}`
}

const StaffMemberPage: FunctionComponent = () => {
    const id = new URLSearchParams(window.location.search).get("id")

    const token = useToken()
    const [staff, setStaff] = useState<StaffModel | null>(null)
    // const { getAccessTokenSilently } = useAuth0()
    const [loading, setLoading] = useState<boolean>(false)
    useEffect(() => {
        if (token === null) { return }
        setLoading(true)
        client.query({
            query: staffQuery,
            variables: {
                id: userId(id)
            },
            context: {
                headers: {
                    authorization: token
                }
            }

        }).then((response) => {
            setStaff(response.data.staffById)
            setLoading(false)
        })
    }, [id, token])

    return (
        <AuthContainer>
            <div className='staffPageContainer'>
                <NavBarNew selectedTab={SelectedTab.None} setTab={() => { }} />
                <div className='mainBody'>
            
                    <div className="staff-page-info-container">
                        {loading && (
                            <LoadingSpinner />
                        )}
                        {staff === null && (
                            <div>
                                Staff member Doesn't exist
                            </div>
                        )}
                        {staff !== null && (
                            <StaffInformation staff={staff} />
                        )}
                    </div>
                </div>
                <Footer />
            </div>
        </AuthContainer>
    )
}

export default StaffMemberPage