import moment from 'moment'
import { RecurrenceFrequency, RecurrenceSubModel } from '../../../libary/Models'

export const renderRecurrenceItemText = (recc: RecurrenceSubModel) => {
    const d = moment()
    switch (recc.frequency) {
        case RecurrenceFrequency.EVERY_DAY:
            return 'Every Day'
        case RecurrenceFrequency.EVERY_WEEK:
            d.set('d', recc.day)
            return `Every Week on ${d.format('dddd')}`
        case RecurrenceFrequency.EVERY_MONTH:
            d.set('D', recc.date)
            return `Every Month on the ${d.format('Do')}`
    }
}
