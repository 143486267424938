import React, { FunctionComponent, useContext } from 'react'
import { LoginProps } from './types'
import Auth from '../../libary/auth'
import './styles.css'
import { withCookies } from 'react-cookie';
import { useAuth0 } from '@auth0/auth0-react';
import { ThemeContext } from '../../App';


const Login: FunctionComponent<LoginProps> = (props: LoginProps) => {

    const theme = useContext(ThemeContext)
    const { loginWithRedirect, handleRedirectCallback, getAccessTokenSilently, user } = useAuth0()
    handleRedirectCallback().then(() => {
        getAccessTokenSilently().then((token) => {
            Auth.newRegister(token, user.email)
            .then((response) => {
            })
        })
    })
        return (
            <div className="loginContainer">
                <div className="leftContainer">
                    <div className="loginFormLogoContainer"><img className="login-form-logo" src={theme.images.logo}/></div>
                    <div className="sign-in-scrollable-container">
                        <div className='sign-in-form'>
                            <button className="login-button" onClick={() => {loginWithRedirect()}}>Login / Sign up</button>
                        </div>
                    {/* <form className="sign-in-form" onSubmit={(event) => {this.submit(event)}}>
                        <h1 className="sign-in-title">Sign in to continue</h1>
                        <input name="email" className="sign-in-input" placeholder="Email Address" ref={(input) => this.email = input}/>
                        <input name="password" type="password" className="sign-in-input" placeholder="Password" ref={(input) => this.password = input}/>

                        <button type="submit" className='sign-in-button'>Continue</button>
                        <a className="sign-in-sign-up"href='/register'>Dont have an account? <span className="underline">Sign up Now</span></a>
                        <a className="sign-in-sign-up underline"href='/passwordresetrequest'>Forgot your password?</a>
                    </form> */}
                    <p className="sign-in-disclaimer">* By signing in, you confirm you have permission from the relevant authorities</p>
                    </div>
                    <div className="sign-in-footer">
                    <a style={{color: theme.colors.lightText}} className="sign-up-footer-text" href='/gdpr'>GDPR</a>
                        {/* <span className="sign-in-footer-separator">|</span>
                        <a className="sign-in-footer-text">Privacy Policy</a>
                        <span className="sign-in-footer-separator">|</span>
                        <a className="sign-in-footer-text">Cookie Policy</a> */}
                    </div>
                </div>
                <div className = "login-image-right">
                </div> 
            </div>  
        )
    
}

export default withCookies(Login)