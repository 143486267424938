import { ConnectedRouter } from 'connected-react-router'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { CookiesProvider } from 'react-cookie'
import { Provider } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import DashboardContainer from './container'
import store, { history } from './libary/redux/store'
import Navigator from './pages/navigator'

import About from './pages/About'

// import AddDocuments from './pages/registerOld/add-documents';
import { ApolloProvider } from '@apollo/client'
import { Auth0Provider } from '@auth0/auth0-react'
import ReactGA4 from 'react-ga4'
import { getConfig } from './libary/Config'
import { Features, StaffModel } from './libary/Models'
import client from './libary/apollo'
import { FeaturesProvider } from './libary/hooks/useFeatures'
import { MeProvider } from './libary/hooks/useMe'
import Account from './pages/Account'
import Admin from './pages/Admin'
import Analytics from './pages/Analytics'
import CreateSessionPermissionRequests from './pages/CreateSessionPermissionsRequest'
import PracticeDisclaimer from './pages/Disclaimers/PracticeDisclaimer'
import StaffDisclaimer from './pages/Disclaimers/StaffDisclaimer'
import DocumentApproval from './pages/DocumentApproval'
import EditAccount from './pages/EditAccount'
import GDPR from './pages/GDPR'
import NoPermissionsPage from './pages/NoPermissionsPage'
import PermissionRequests from './pages/PermissionRequests'
import PermissionsHome from './pages/PermissionsHome'
import { PermissionHomeType } from './pages/PermissionsHome/PermissionsHome'
import RecurrenceSessions from './pages/Recurrences/RecurrenceSessions/RecurrenceSessions'
import Recurrences from './pages/Recurrences/Recurrences'
import SelectAccountTypePage from './pages/SelectAccountTypePage'
import Session from './pages/Session'
import StaffApproval from './pages/StaffApproval'
import StaffHubs from './pages/StaffHubs'
import StaffMemberPage from './pages/StaffMemberPage'
import Welcome from './pages/Welcome'
import Rota from './pages/rota'
import StaffDashboard from './staff/pages/dashboard'
import FindASession from './staff/pages/find-a-session'
import { FedworksTheme, GMWorkforceTheme } from './themes'

export const ThemeContext = React.createContext(FedworksTheme)
// const trackingId = "UA-110854076-3"; // Replace with your Google Analytics tracking ID

//GMWorkforce
const trackingId = () => {
    if (process.env.NODE_ENV !== 'production') {
        return 'test'
    }
    if (window.location.hostname.includes('fedworks')) {
        return 'G-0YXWRTGFHS'
        // return "UA-110854076-3"
    } else if (window.location.hostname.includes('gmworkforce')) {
        // return "G-K5W7L9YZED"
        return 'G-TPYEHXMTTX'
    } else {
        return 'tst'
    }
}

const App: FunctionComponent = () => {
    useEffect(() => {
        // ReactGA.initialize(trackingId());
        ReactGA4.initialize(trackingId())
        history.listen((update) => {
            if (update.location) {
                // ReactGA.set({ page: update.location.pathname }); // Update the user's current page
                // ReactGA.pageview(update.location.pathname); // Record a pageview for the given page\
                ReactGA4.send({
                    hitType: 'pageview',
                    page: update.location.pathname,
                })
                window.history.pushState(
                    {},
                    update.location.pathname,
                    update.location.pathname
                )
            }
        })
    }, [])

    const getTheme = () => {
        if (process.env.NODE_ENV !== 'production') {
            return GMWorkforceTheme
        }
        if (window.location.hostname.includes('fedworks')) {
            return FedworksTheme
        } else if (window.location.hostname.includes('gmworkforce')) {
            return GMWorkforceTheme
        } else {
            return FedworksTheme
        }
    }

    const [features, setFeatures] = useState<Features | null>(null)
    const [me, setMe] = useState<StaffModel | null>(null)
    const config = getConfig()

    return (
        <Auth0Provider
            clientId={config.auth0.clientId}
            domain={config.auth0.domain}
            redirectUri={config.auth0.redirectUri}
            audience={config.auth0.audience}
            useRefreshTokens={config.auth0.useRefreshTokens}
            cacheLocation={config.auth0.cacheLocation}>
            <ApolloProvider client={client}>
                <CookiesProvider>
                    <Provider store={store}>
                        <FeaturesProvider
                            features={features}
                            setFeatures={setFeatures}>
                            <MeProvider me={me} setMe={setMe}>
                                <ThemeContext.Provider value={getTheme()}>
                                    <ConnectedRouter history={history}>
                                        <Switch>
                                            <Route
                                                path='/'
                                                exact
                                                render={() => <Navigator />}
                                            />
                                            <Route
                                                path='/onboarding-account-type'
                                                exact
                                                render={() => (
                                                    <SelectAccountTypePage />
                                                )}
                                            />
                                            <Route
                                                path='/onboarding-disclaimer-creator'
                                                exact
                                                render={() => (
                                                    <PracticeDisclaimer />
                                                )}
                                            />
                                            <Route
                                                path='/onboarding-disclaimer-staff'
                                                exact
                                                render={() => (
                                                    <StaffDisclaimer />
                                                )}
                                            />
                                            <Route
                                                path='/admin'
                                                exact
                                                render={() => <Admin />}
                                            />
                                            <Route
                                                path='/dashboard'
                                                exact
                                                render={() => (
                                                    <DashboardContainer />
                                                )}
                                            />
                                            <Route
                                                path='/rota'
                                                exact
                                                render={() => <Rota />}
                                            />
                                            <Route
                                                path='/staffhubs'
                                                exact
                                                render={() => <StaffHubs />}
                                            />
                                            <Route
                                                path='/documentapproval'
                                                exact
                                                render={() => (
                                                    <DocumentApproval />
                                                )}
                                            />
                                            <Route
                                                path='/staffapproval'
                                                exact
                                                render={() => <StaffApproval />}
                                            />
                                            <Route
                                                path='/staffdashboard'
                                                exact
                                                render={() => (
                                                    <StaffDashboard />
                                                )}
                                            />
                                            <Route
                                                path='/diary'
                                                exact
                                                render={() => (
                                                    <StaffDashboard />
                                                )}
                                            />
                                            <Route
                                                path='/search'
                                                exact
                                                render={() => <FindASession />}
                                            />
                                            <Route
                                                path='/account'
                                                exact
                                                render={() => <Account />}
                                            />
                                            <Route
                                                path='/about'
                                                exact
                                                render={() => <About />}
                                            />
                                            <Route
                                                path='/editaccount'
                                                exact
                                                render={() => <EditAccount />}
                                            />
                                            <Route
                                                path='/session'
                                                exact
                                                render={() => <Session />}
                                            />
                                            <Route
                                                path='/staff'
                                                exact
                                                render={() => (
                                                    <StaffMemberPage />
                                                )}
                                            />
                                            {/* <Route path='/create-session' exact render={() => <CreateSessionPage />} /> */}
                                            {/* <Route path='/passwordreset/:token' exact render={() => <PasswordReset />} />
              <Route path='/passwordresetRequest' exact render={() => <PasswordResetRequest />} /> */}
                                            <Route
                                                path='/permissions-home'
                                                exact
                                                render={() => (
                                                    <PermissionsHome
                                                        type={
                                                            PermissionHomeType.HOME
                                                        }
                                                    />
                                                )}
                                            />
                                            <Route
                                                path='/permissions-create-home'
                                                exact
                                                render={() => (
                                                    <PermissionsHome
                                                        type={
                                                            PermissionHomeType.CREATE
                                                        }
                                                    />
                                                )}
                                            />
                                            <Route
                                                path='/permissions-apply-home'
                                                exact
                                                render={() => (
                                                    <PermissionsHome
                                                        type={
                                                            PermissionHomeType.APPLY
                                                        }
                                                    />
                                                )}
                                            />
                                            <Route
                                                path='/permissions-requests'
                                                exact
                                                render={() => (
                                                    <PermissionRequests />
                                                )}
                                            />
                                            <Route
                                                path='/permissions-create-session-request'
                                                exact
                                                render={() => (
                                                    <CreateSessionPermissionRequests />
                                                )}
                                            />
                                            <Route
                                                path='/no'
                                                exact
                                                render={() => (
                                                    <NoPermissionsPage />
                                                )}
                                            />
                                            <Route
                                                path='/gdpr'
                                                exact
                                                render={() => <GDPR />}
                                            />
                                            <Route
                                                path='/onboarding-welcome'
                                                exact
                                                render={() => <Welcome />}
                                            />
                                            <Route
                                                path='/analytics'
                                                exact
                                                render={() => <Analytics />}
                                            />
                                            <Route
                                                path='/recurrences'
                                                exact
                                                render={() => <Recurrences />}
                                            />
                                            <Route
                                                path='/recurrences/:id'
                                                exact
                                                render={() => (
                                                    <RecurrenceSessions />
                                                )}
                                            />
                                            {/* <Route path='/addDocuments' render={() => <AddDocuments}/> */}
                                        </Switch>
                                    </ConnectedRouter>
                                </ThemeContext.Provider>
                            </MeProvider>
                        </FeaturesProvider>
                    </Provider>
                </CookiesProvider>
            </ApolloProvider>
        </Auth0Provider>
    )
}

export default App
