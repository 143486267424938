import { ThemeProvider } from '@emotion/react'
import {
    Card,
    CardContent,
    Tab,
    Tabs,
    createTheme,
    useMediaQuery,
} from '@mui/material'
import { FC, useState } from 'react'
import { StaffModel } from '../../libary/Models'
import useFeatures from '../../libary/hooks/useFeatures'
import StaffInformation from '../../pages/staffInformation'
import StaffSessionsTab from './StaffSessionsTab'

interface StaffViewProps {
    staff: StaffModel
}

const theme = createTheme({
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    marginBottom: '8px',
                },
            },
        },
    },
    palette: {
        primary: {
            main: '#692068',
        },
        secondary: {
            main: '#00868B',
        },
        text: {
            primary: '#000000',
            secondary: 'black',
        },
    },
})

const StaffView: FC<StaffViewProps> = ({ staff }) => {
    const matches = useMediaQuery(theme.breakpoints.down('sm'))
    const { features } = useFeatures()
    const [activeTab, setActiveTab] = useState(0)

    return (
        <ThemeProvider theme={theme}>
            <Card
                sx={{
                    borderColor: '#979797',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    width: matches ? '100vw' : 'auto',
                    height: matches ? '100vh' : 'auto',
                    maxHeight: '80vh',
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                <CardContent>
                    <Tabs
                        value={activeTab}
                        onChange={(_, newValue) => setActiveTab(newValue)}
                        aria-label='Session tabs'
                        style={{
                            marginBottom: '16px',
                            overflow: 'scroll',
                        }}>
                        <Tab label='Information' />
                        {features.viewStaffList && <Tab label='Sessions' />}
                    </Tabs>
                    {activeTab === 0 && <StaffInformation staff={staff} />}
                    {activeTab === 1 && features.viewStaffList && (
                        <StaffSessionsTab id={staff.id} />
                    )}
                </CardContent>
            </Card>
        </ThemeProvider>
    )
}

export default StaffView
