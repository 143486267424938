import gql from "graphql-tag";

const sessionQuery = gql`
query session($id: String) {
    session(id: $id) {
        id
        session_status
        session_date
        start_time
        end_time
        break
        pricePerHour
        staff_type
        staff{
          id
          name_value
            staff_type
        }
        location {
            id
            name_value
        }
        employer {
            id
            name_value
        }
        applicants {
            id
            name_value
            image
        }
        has_applied
      }
}`

export { sessionQuery }