import { gql } from '@apollo/client'
import { Result } from 'true-myth'
import { RecurrenceModel } from '../../libary/Models'
import query from '../../libary/network/apollo'

const getSessionsQuery = gql`
    query SessionsForDate(
        $date: String!
        $staffType: String
        $localityId: String
        $locationId: String
        $employerId: String
        $filterFilled: Boolean
        $filterPending: Boolean
    ) {
        sessionsForDate(
            date: $date
            staff_type: $staffType
            LOCALITY_ID: $localityId
            LOCATION_ID: $locationId
            EMPLOYER_ID: $employerId
            filterFilled: $filterFilled
            filterPending: $filterPending
        ) {
            id
            session_status
            session_date
            start_time
            end_time
            pricePerHour
            break
            staff_type
            RECURRENCE_ID
            length_of_appointments
            session_description
            payment_process
            payment_process_info

            staff {
                id
                name_value
                staff_type
            }
            it_system {
                id
                name_value
            }
            locality {
                id
                name_value
            }
            location {
                id
                name_value
            }
            applicants {
                id
                name_value
            }
        }
    }
`

const getRecurrencesForDateQuery = gql`
    query RecurrencesForDate($date: String!) {
        recurrencesForDate(date: $date) {
            id
            start_date
            end_date
            models {
                frequency
                date
                day
            }
            session_template {
                name_value
                session_status
                session_date
                start_time
                end_time
                pricePerHour
                break
                paid
                has_applied
                system_id
                it_system {
                    id
                    name_value
                }
                location {
                    id
                    name_value
                }
                is_assigned
                staff_type
                session_description
                travel_info
                parking_info
                payment_process
                payment_process_info
                length_of_appointments
                RECURRENCE_ID
            }
            staff_type
        }
    }
`

const getSessions = (
    input: {
        date: string
        staffType: string
        localityId: string
        locationId: string
        employerId: string
        filterFilled: boolean
        filterPending: boolean
    },
    token: string
) => {
    return query(
        getSessionsQuery,
        token,
        'sessionsForDate',
        { ...input },
        'network-only'
    )
}
const getRecurrencesForDate = (
    date: string,
    token: string
): Promise<Result<RecurrenceModel[], Error>> => {
    return query(
        getRecurrencesForDateQuery,
        token,
        'recurrencesForDate',
        {
            date,
        },
        'network-only'
    )
}

export { getRecurrencesForDate, getSessions }
