import { FunctionComponent } from 'react'
import { NewModal } from '../../NewModal'
import DayInfo from '../../day-info'
import './styles.css'

interface DayInfoModalMobileProps {
    show: boolean
    close: Function
    cardClicked: Function
    date: Date
    staff_type?: string
    hideSessionStatus?: boolean
}

const DayInfoModalMobile: FunctionComponent<DayInfoModalMobileProps> = (
    props: DayInfoModalMobileProps
) => {
    return (
        <NewModal show={props.show} close={props.close}>
            <DayInfo
                date={props.date}
                cardClicked={props.cardClicked}
                width='95vw'
                height='100vh'
                shortDate={true}
                close={props.close}
                filterFilled={true}
                filterPending={false}
                staff_type={props.staff_type}
                hideSessionStatus={props.hideSessionStatus}
            />
        </NewModal>
    )
}

export default DayInfoModalMobile
