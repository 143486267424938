
import client from '../apollo'
import { gql } from '@apollo/client'
import { SessionModel } from '../Models';

interface MutationResponse {
    success: boolean
    error: string
    id: string
}

class Mutations {

    static changeProfileImage = async (token: string) => {
        const mutation = gql`
        mutation changeProfileImage {
            changeProfileImage {
              url
            }
          }
        `

        const response = await client.mutate({
            mutation, 
            context: {
                headers: {
                    "Authorization": token
                }
            }
        })
        return response.data.changeProfileImage.url
    }

    static addStaffDocument = async (documentName: string, token: string): Promise<string | undefined> => {
        const mutation = gql`
        mutation($documentName: String) {
            addStaffDocument(documentName: $documentName) {
                id
              url
            }
          }
        `
        const response = await client.mutate({
            mutation,
            variables: {
                documentName
            },
            context: {
                headers: {
                    "Authorization": token
                }
            }
        })

        return response.data.addStaffDocument.url
    }

    static applyToSession = (sessionId: string, session_date: string, session_start_time: number, session_end_time: number, session_location_name: string, token: string) => {
        const mutation = gql`
        mutation($sessionId: String, $session_date: String!, $session_start_time: Float!, $session_end_time: Float!, $session_location_name: String!) {
            applyForSession(sessionId: $sessionId, session_date: $session_date, session_start_time: $session_start_time, session_end_time: $session_end_time, session_location_name: $session_location_name) {
              id
              success
              error
            }
          }
        `
        return new Promise((resolve, reject) => {
            client.mutate({
                mutation,
                variables: {
                    sessionId: sessionId,
                    session_date: session_date,
                    session_start_time: session_start_time,
                    session_end_time: session_end_time,
                    session_location_name: session_location_name
                },
                context: {
                    headers: {
                        "Authorization": token
                    }
                }
            }).then((response) => {
                if (response.data.applyForSession.success  === true) {
                    resolve(response.data.applyForSession)
                } else {
                    reject(response)
                }
            })
        })
    }
    static createSession = (session: SessionModel, token) => {
        const mutation = gql`
        mutation createSession(
            $name_value: String
            $LOCALITY_ID: String!, 
            $locality_name: String!,
            $LOCATION_ID: String!,
            $location_name: String!,
            $EMPLOYER_ID: String!,
            $employer_name: String!,
            $it_system_id: String!,
            $staff_type: String,
            $session_date: String,
            $start_time: Float,
            $end_time: Float,
            $pricePerHour: Int,
            $break: Int,
            $another_doctor: Boolean,
            $nurse: Boolean,
            $catchup: Boolean,
            $extra_information: String,
            $session_description: String,
            $travel_info: String,
            $parking_info: String,
            $payment_process: String!, 
            $payment_process_info: String
            $length_of_appointments: String
            ) {
              createSession(session: {
                name_value: $name_value,
                session_date: $session_date, 
                start_time: $start_time,
                end_time: $end_time,
                LOCALITY_ID: $LOCALITY_ID, 
                locality_name: $locality_name
                LOCATION_ID: $LOCATION_ID,
                location_name: $location_name,
                EMPLOYER_ID: $EMPLOYER_ID, 
                employer_name: $employer_name
                staff_type: $staff_type,
                pricePerHour: $pricePerHour,
                break: $break, 
                another_doctor: $another_doctor,
                nurse: $nurse,
                catchup: $catchup, 
                extra_information: $extra_information,
                it_system_id: $it_system_id,
                session_description: $session_description,
                travel_info: $travel_info,
                parking_info: $parking_info,
                payment_process: $payment_process,
                payment_process_info: $payment_process_info, 
                length_of_appointments: $length_of_appointments
              }) {
                id
                error
                success    
              }
            }
`
        return new Promise((resolve, reject) => {
            client.mutate({
                mutation: mutation,
                variables: {
                    name_value: session.name_value,
                    session_date: session.session_date,
                    start_time: session.start_time,
                    end_time: session.end_time,
                    LOCALITY_ID: session.LOCALITY_ID,
                    locality_name: session.locality_name,
                    LOCATION_ID: session.location.id,
                    location_name: session.location.name_value,
                    EMPLOYER_ID: session.EMPLOYER_ID,
                    employer_name: session.employer_name,
                    staff_type: session.staff_type,
                    pricePerHour: session.pricePerHour,
                    break: session.break,
                    another_doctor: session.another_doctor,
                    nurse: session.nurse,
                    catchup: session.catchup,
                    extra_information: session.extra_information,
                    it_system_id: session.it_system_id,
                    session_description: session.session_description,
                    travel_info: session.travel_info,
                    parking_info: session.parking_info,
                    payment_process: session.payment_process,
                    payment_process_info: session.payment_process_info,
                    length_of_appointments: session.length_of_appointments
                },
                context: { headers: { "Authorization": token } }
            })
                .then((response) => {
                    if (response.data.createSession.success  === true) {
                        resolve(response)
                    } else {
                        reject(response)
                    }
                })
        })
    }

    static deleteDocument = (id, token) => {
        const mutation = gql`
        mutation{
            deleteMyDocument(id: "${id}"){
              success
              error
            }
          }
        `

        return new Promise((resolve, reject) => {
            client.mutate({ mutation: mutation, context: { headers: { "Authorization": token } } })
                .then((response) => {
                    if (response.data.deleteMyDocument) {
                        resolve(response.data.deleteMyDocument)
                    } else {
                        reject(response)
                    }
                })
        })
    }

    static deleteSystem = (id, token) => {
        const mutation = gql`
        mutation{
            deleteMyItSystem(it_system_id: "${id}"){
              success
              error
            }
          }
        `

        return new Promise((resolve, reject) => {
            client.mutate({ mutation: mutation, context: { headers: { "Authorization": token } } })
                .then((response) => {
                    if (response.data.deleteMyItSystem) {
                        resolve(response.data.deleteMyItSystem)
                    } else {
                        reject(response)
                    }
                })
        })
    }
    static deleteQualification = (id, token) => {
        const mutation = gql`
        mutation{
            deleteMyQualification(qualification_id: "${id}"){
              success
              error
            }
          }
        `

        return new Promise((resolve, reject) => {
            client.mutate({ mutation: mutation, context: { headers: { "Authorization": token } } })
                .then((response) => {
                    if (response.data.deleteMyQualification) {
                        resolve(response.data.deleteMyQualification)
                    } else {
                        reject(response)
                    }
                })
        })
    }
    static deleteLanguage = (id, token) => {
        const mutation = gql`
        mutation{
            deleteMyLanguage(language_id: "${id}"){
              success
              error
            }
          }
        `

        return new Promise((resolve, reject) => {
            client.mutate({ mutation: mutation, context: { headers: { "Authorization": token } } })
                .then((response) => {
                    if (response.data.deleteMyLanguage.success  === true) {
                        resolve(response)
                    } else {
                        reject(response)
                    }
                })
        })
    }
    static deleteExperience = (id, token): Promise<MutationResponse> => {
        const mutation = gql`
        mutation{
            deleteExperience(id: "${id}"){
              success
              error
            }
          }
        `

        return new Promise((resolve, reject) => {
            client.mutate({ mutation: mutation, context: { headers: { "Authorization": token } } })
                .then((response) => {
                    if (response.data.deleteExperience.success  === true) {
                        resolve(response.data.deleteExperience)
                    } else {
                        reject(response)
                    }
                })
        })
    }

    static addSystem = (system_id, system_name, token): Promise<MutationResponse> => {
        const mutation = gql`
        mutation addMyItSystem($system_id: String!, $system_name: String!){
            addMyItSystem(system_id: $system_id, system_name: $system_name){
              id
              success
              error
            }
          }
        `

        return new Promise((resolve, reject) => {
            client.mutate({
                mutation: mutation,
                variables: {
                    system_id,
                    system_name
                },
                context: { headers: { "Authorization": token } }
            })
                .then((response) => {
                    if (response.data.addMyItSystem.success  === true) {
                        resolve(response.data.addMyItSystem)
                    } else {
                        reject(response)
                    }
                })
        })
    }
    static addQualification = (qualification, token): Promise<MutationResponse> => {
        const mutation = gql`
        mutation addMyQualification($qualification: String!) {
            addMyQualification(qualification: $qualification){
              id
              success
              error
            }
          }
        `

        return new Promise((resolve, reject) => {
            client.mutate({
                mutation: mutation,
                variables: {
                    qualification
                },
                context: { headers: { "Authorization": token } }
            })
                .then((response) => {
                    if (response.data.addMyQualification.success  === true) {
                        resolve(response.data.addMyQualification)
                    } else {
                        reject(response)
                    }
                })
        })
    }

    static addLanguage = (language_id, language_name, short_name, token): Promise<MutationResponse> => {
        const mutation = gql`
        mutation addMyLanguage($language_id: String!, $language_name: String!, $short_name: String!) {
            addMyLanguage(language_id: $language_id, language_name: $language_name, short_name: $short_name){
              id
              success
              error
            }
          }
        `

        return new Promise((resolve, reject) => {
            client.mutate({
                mutation: mutation,
                variables: {
                    language_id,
                    language_name,
                    short_name
                },
                context: { headers: { "Authorization": token } }
            })
                .then((response) => {
                    if (response.data.addMyLanguage.success  === true) {
                        resolve(response.data.addMyLanguage)
                    } else {
                        reject(response)
                    }
                })
        })
    }
    static addExperience = (workExperience, token): Promise<MutationResponse> => {
        const mutation = gql`
        mutation addMyWorkExperience($workExperience: String!){
            addMyWorkExperience(workExperience: $workExperience){
                id
              success
              error
            }
          }
        `

        return new Promise((resolve, reject) => {
            client.mutate({
                mutation: mutation,
                variables: {
                    workExperience
                },
                context: { headers: { "Authorization": token } }
            })
                .then((response) => {
                    if (response.data.addMyWorkExperience.success  === true) {
                        resolve(response.data.addMyWorkExperience)
                    } else {
                        reject(response)
                    }
                })
        })
    }

    static requestPasswordReset = (email) => {
        const mutation = gql`
        mutation{
            requestPasswordReset(email:"${email}"){
              success
              error
            }
          }
        `

        return new Promise((resolve, reject) => {
            client.mutate({ mutation: mutation })
                .then((response) => {
                    if (response.data.requestPasswordReset.success  === true) {
                        resolve(response.data.requestPasswordReset)
                    } else {
                        reject(response)
                    }
                })
        })
    }

    static resetPassword = (token, password) => {
        const mutation = gql`
        mutation{
            resetPassword(token:"${token}", password:"${password}"){
              success
              error
            }
          }
        `

        return new Promise((resolve, reject) => {
            client.mutate({ mutation: mutation })
                .then((response) => {
                    if (response.data.resetPassword.success  === true) {
                        resolve(response.data.resetPassword)
                    } else {
                        reject(response)
                    }
                })
        })
    }

    static approveDocument = (documentId, staffId, token): Promise<{success: boolean}> => {
        const mutation = gql`
        mutation approveDocument($documentId: String!, $staffId: String!) {
            approveDocument(documentId: $documentId, staffId: $staffId) {
                success
                error
              }
        }
        `

        return new Promise((resolve, reject) => {
            client.mutate({ 
                mutation, 
                variables: {
                    documentId, 
                    staffId
                },
                context: { 
                    headers: { 
                        "Authorization": token 
                    } 
                } 
              })
                .then((response) => {
                    if (response.data.approveDocument) {
                        resolve(response.data.approveDocument)
                    } else {
                        reject()
                    }
                })
        })
    }

    static rejectDocument = (documentId, staffId, token): Promise<{success: boolean}> => {
        const mutation = gql`
        mutation rejectDocument($documentId: String!, $staffId: String!) {
            rejectDocument(documentId: $documentId, staffId: $staffId) {
                success
                error
              }
        }
        `

        return new Promise((resolve, reject) => {
            client.mutate({ 
                mutation, 
                variables: {
                    documentId, 
                    staffId
                },
                context: { 
                    headers: { 
                        "Authorization": token 
                    } 
                } 
              })
                .then((response) => {
                    if (response.data.rejectDocument) {
                        resolve(response.data.rejectDocument)
                    } else {
                        reject()
                    }
                })
        })
    }

    static withdrawFromSession = (sessionId, token) => {
        const mutation = gql`
        mutation withdrawFromSession($sessionId: String) {
            withdrawFromSession(id: $sessionId){
              success
              id
              error
            }
          }
        `
        return new Promise((resolve, reject) => {
            client.mutate({
                mutation,
                variables: {
                    sessionId
                },
                context: {
                    headers: {
                        "Authorization": token
                    }
                }
            }).then((response) => {
                    if (response.data.withdrawFromSession) {
                        resolve(response.data.withdrawFromSession)
                    } else {
                        reject()
                    }
                })
        })
    }

    static deleteSession = (sessionId: string, token: string) => {
        const mutation = gql`
        mutation($sessionId: String) {
            deleteSession(sessionId: $sessionId) {
              id
              success
              error
            }
          }
        `
        return new Promise((resolve, reject) => {
            client.mutate({
                mutation,
                variables: {
                    sessionId: sessionId
                },
                context: {
                    headers: {
                        "Authorization": token
                    }
                }
            })
                .then((response) => {
                    if (response.data.deleteSession.success) {
                        resolve(response.data.deleteSession)
                    } else {
                        reject()
                    }
                })
        })
    }

    static assignToSession = (sessionId: string, staffId: string, token: string) => {
        const mutation = gql`
        mutation($sessionId: String, $staffId: String) {
            assignToSession(sessionId: $sessionId, staffId: $staffId) {
              success
              error
              id
            }
          }
        `
        return new Promise((resolve, reject) => {
            client.mutate({
                mutation,
                variables: {
                    sessionId,
                    staffId
                },
                context: {
                    headers: {
                        "Authorization": token
                    }
                }
            }).then((response) => {
                if (response.data.assignToSession.success) {
                    resolve(response.data.assignToSession)
                } else {
                    reject()
                }
            })
        })
    }

    static removeFromSession = (sessionId: string, staffId: string, token: string) => {
        const mutation = gql`
        mutation($sessionId: String, $staffId: String) {
            removeFromSession(sessionId: $sessionId, staffId: $staffId) {
              success
              error
              id
            }
          }
        `
        return new Promise((resolve, reject) => {
            client.mutate({
                mutation,
                variables: {
                    sessionId,
                    staffId
                },
                context: {
                    headers: {
                        "Authorization": token
                    }
                }
            }).then((response) => {
                if (response.data.removeFromSession.success) {
                    resolve(response.data.removeFromSession)
                } else {
                    reject()
                }
            })
        })
    }
}

export default Mutations;



