import { Grid, Typography } from '@mui/material'
import moment from 'moment'
import { FC, useState } from 'react'
import Footer from '../../../components/Footer'
import NavBarNew from '../../../components/NavBarNew/NavBarNew'
import CalendarTemp from '../../../components/calendarTemp'
import DayInfo from '../../../components/day-info'
import SessionModalNew from '../../../components/modals/SessionModalNew/SessionModalNew'
import AuthContainer from '../../../container/AuthContainer'
import { SelectedTab, SessionModel } from '../../../libary/Models'
import useFeatures from '../../../libary/hooks/useFeatures'

const RecurrenceSessions: FC = () => {
    const [reloadCalendar, setReloadCalendar] = useState(false)
    const { features } = useFeatures()

    const searchParams = new URLSearchParams(window.location.search)
    const [selectedDate, setSelectedDate] = useState(
        searchParams.get('currentMonth') !== null
            ? moment(parseFloat(searchParams.get('currentMonth'))).toDate()
            : new Date()
    )
    const [selectedSession, setSelectedSession] = useState<
        SessionModel | undefined
    >(undefined)
    const recurrenceCardClicked = (session: SessionModel) => {
        setSelectedSession(session)
    }

    const closeSessionModal = (reload: boolean) => {
        setSelectedSession(null)
        if (reload === true) {
            setReloadCalendar(!reloadCalendar)
        }
    }

    return (
        <AuthContainer>
            <div className='adminBody'>
                <NavBarNew
                    setTab={() => {}}
                    selectedTab={SelectedTab.Recurrences}
                />
                <div className='mainBody'>
                    <Grid container sx={{ width: '100%' }}>
                        <Grid item xs={12}>
                            <Typography variant='h5'>
                                Sessions for Schedule
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <CalendarTemp
                                reloadCalendar={reloadCalendar}
                                disabled={false}
                                setMonth={(newMonth) => {}}
                                canCreate={true}
                                queryName='sessions'
                                query={``}
                                recurrenceEnabled={true}
                                width={window.innerWidth < 600 ? '95%' : '62%'}
                                setDate={(date: Date) => {
                                    setSelectedDate(date)
                                }}
                                createSession={(date) => {}}
                                filterFilled={false}
                                filterPending={false}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <DayInfo
                                reload={reloadCalendar}
                                width='100%'
                                date={selectedDate}
                                cardClicked={(cardId, session) => {
                                    recurrenceCardClicked(session)
                                }}
                                recurrenceEnabled={false}
                            />
                        </Grid>
                    </Grid>
                </div>
                <Footer />
            </div>
            {selectedSession && features.newSessionModal === true && (
                <SessionModalNew
                    show={true}
                    id={''}
                    sessionModel={selectedSession}
                    closeModal={(reload: boolean) => {
                        closeSessionModal(reload)
                    }}
                />
            )}
        </AuthContainer>
    )
}

export default RecurrenceSessions
