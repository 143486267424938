import { gql } from "@apollo/client";

const requestPermissionMutation = gql`
mutation requestPermission ($permission: String!, $objectId: String!, $objectName: String, $localityId: String)  {
    requestPermission(permission: $permission, object_id: $objectId, object_name: $objectName, locality_id: $localityId) {
      success
    }
  }
`

export { 
    requestPermissionMutation
}