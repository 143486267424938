import { gql } from '@apollo/client'
import { Result } from 'true-myth'
import { MutationResponse, mutation } from '../../../libary/network/apollo'

const assignToSessionMutation = gql`
    mutation ($sessionId: String, $staffId: String) {
        assignToSession(sessionId: $sessionId, staffId: $staffId) {
            success
            error
            id
        }
    }
`

const assignToSessionFromRecurrenceMutation = gql`
    mutation AssignToSessionFromRecurrence(
        $recurrenceId: String!
        $date: String!
        $staffId: String!
    ) {
        assignToSessionFromRecurrence(
            recurrenceId: $recurrenceId
            date: $date
            staff_id: $staffId
        ) {
            id
            success
            error
        }
    }
`

const removeFromSessionMutation = gql`
    mutation RemoveFromSession($sessionId: String, $staffId: String) {
        removeFromSession(sessionId: $sessionId, staffId: $staffId) {
            success
            error
            id
        }
    }
`

const createRecurrenceExclusionMutation = gql`
    mutation CreateRecurrenceExclusion($input: CreateRecurrenceExclusionInput) {
        createRecurrenceExclusion(input: $input) {
            success
            error
            id
        }
    }
`

const hideSessionMutation = gql`
    mutation HideSession($id: String!) {
        hideSession(id: $id) {
            id
            success
            error
        }
    }
`

const makeSessionVisibleMutation = gql`
    mutation MakeSessionVisibile($id: String!) {
        makeSessionVisible(id: $id) {
            id
            success
            error
            session_status
        }
    }
`

const assignToSession = async (
    sessionId: string,
    staffId: string,
    token: string
): Promise<Result<MutationResponse, Error>> => {
    console.log('ASSIGNING IN MUTATION: ', {
        sessionId,
        staffId,
    })
    return await mutation(assignToSessionMutation, token, 'assignToSession', {
        sessionId,
        staffId,
    })
}

interface AssignToSessionFromRecurrenceInput {
    recurrenceId: string
    date: string
    staffId: string
}
const assignToSessionFromRecurrence = async (
    input: AssignToSessionFromRecurrenceInput,
    token: string
): Promise<Result<MutationResponse, Error>> => {
    return mutation(
        assignToSessionFromRecurrenceMutation,
        token,
        'assignToSessionFromRecurrence',
        { ...input }
    )
}

const removeFromSession = async (
    sessionId,
    staffId,
    token
): Promise<Result<MutationResponse, Error>> => {
    return mutation(removeFromSessionMutation, token, 'removeFromSession', {
        sessionId,
        staffId,
    })
}
interface CreateRecurrenceExclusionInput {
    id: string
    session_date: string
}
const createRecurrenceExclusion = async (
    input: CreateRecurrenceExclusionInput,
    token: string
): Promise<Result<MutationResponse, Error>> => {
    return mutation(
        createRecurrenceExclusionMutation,
        token,
        'createRecurrenceExclusion',
        { input }
    )
}

const hideSession = (
    id: string,
    token: string
): Promise<Result<MutationResponse, Error>> => {
    return mutation(hideSessionMutation, token, 'hideSession', { id })
}

interface MakeSessionVisibleResponse extends MutationResponse {
    session_status: string
}
const makeSessionVisible = (
    id: string,
    token: string
): Promise<Result<MakeSessionVisibleResponse, Error>> => {
    return mutation(makeSessionVisibleMutation, token, 'makeSessionVisible', {
        id,
    })
}

export {
    assignToSession,
    assignToSessionFromRecurrence,
    createRecurrenceExclusion,
    hideSession,
    makeSessionVisible,
    removeFromSession,
}
