import { FunctionComponent, useState } from "react";
import Mutations from "../../../libary/apollo/mutations";
import useToken from "../../../libary/hooks/useToken";
import DragDropUpload from "../../DragDropUpload";
import LoadingSpinner from "../../loadingSpinner";
import { NewModal } from "../../NewModal";
import './styles.css'

interface AddDocumentModalProps {
    show: boolean
    closeModal: Function
    imageAdded: Function
}

const ChangeProfileImageModal: FunctionComponent<AddDocumentModalProps> = (props: AddDocumentModalProps) => {
    const [file, setFile] = useState(null)
    const [loading, setLoading] = useState<boolean>(false)
    const token = useToken()

    const addImage = async () => {
        setLoading(true)
        const signedUrl = await Mutations.changeProfileImage(token)

        fetch(
            signedUrl,
            {
                method: 'PUT',
                body: file,
            }
        )
            .then((result) => {
                props.imageAdded(file)
                setLoading(false)
                props.closeModal(true)
            })
            .catch((error) => {
                setLoading(false)
            });
    }

    return (
        <NewModal show={props.show} close={props.closeModal}>
            {loading  === true && <LoadingSpinner text="Uploading..." padding="8px" />}
            {loading  === false && (
                <div className='modal-add-container'>
                    <p className='modal-add-title'>Upload Profile Image</p>
                    <div className='drag-upload-container'>
                        <DragDropUpload setFile={setFile} />
                    </div>
                    <div onClick={() => {addImage()}} className={file === null ? 'modal-add-button disabled' : 'modal-add-button'}>Add</div>
                </div>
            )}
        </NewModal>
    )
}

export default ChangeProfileImageModal