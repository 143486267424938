import React, { FunctionComponent } from 'react'

import './styles.css'
import { SessionModel } from '../../../libary/Models';
import moment from 'moment';


interface ListCardProps {
    session?: SessionModel;
    onClick?: Function;
}

const ListCard: FunctionComponent<ListCardProps> = (props: ListCardProps) => {

    return (
        <div className="listCardContainer" onClick={() => { props.onClick() }}>
            <div className="staffListCardText">{props.session.location.name_value}</div>
            <div className="listCardMultilayer">
                <div className="staffListCardText">{moment(props.session.start_time).format("HH:mm")} - {moment(props.session.end_time).format("HH:mm")}</div>
                <div className="staffListCardText">{props.session.break} minutes break</div>
            </div>
            <div className="staffListCardText">£{props.session.pricePerHour} p/h</div>
        </div>
    )
}

export default ListCard;