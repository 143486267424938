import { ApolloClient, FetchPolicy, InMemoryCache } from '@apollo/client'
import { Result } from 'true-myth'

const apolloUrl = () => {
    if (window.location.hostname.includes('fedworks')) {
        return 'https://4aj0g6srld.execute-api.eu-west-2.amazonaws.com/'
    } else if (window.location.hostname.includes('gmworkforce')) {
        return 'https://qlojsk981f.execute-api.eu-west-2.amazonaws.com'
    } else {
        return 'https://8czojkh8zh.execute-api.eu-west-2.amazonaws.com'
    }
}

const client = new ApolloClient({
    cache: new InMemoryCache(),
    uri: apolloUrl,
})

const query = async (
    apolloQuery: any,
    token: string,
    queryName: string,
    variables?: object,
    fetchPolicy?: FetchPolicy
): Promise<Result<any, Error>> => {
    const response = await client.query({
        query: apolloQuery,
        context: {
            headers: {
                authorization: token,
            },
        },
        variables,
        fetchPolicy: fetchPolicy,
    })
    if (response.error) {
        return Result.err(new Error('Test Error'))
    } else {
        let data = response.data[queryName]
        if (data) {
            return Result.ok(data)
        } else {
            return Result.err(new Error('Parsing Error'))
        }
    }
}

export interface MutationResponse {
    success: boolean
    id?: string
    error?: string
}

const mutation = async <T>(
    apolloMutation: any,
    token: string,
    mutationName: string,
    variables?: object
): Promise<Result<T, Error>> => {
    const response = await client.mutate({
        mutation: apolloMutation,
        context: {
            headers: {
                authorization: token,
            },
        },
        variables,
    })
    if (response.errors) {
        return Result.err(new Error('Test Error'))
    } else {
        let data: T = response.data[mutationName]
        if (data) {
            return Result.ok(data)
        } else {
            return Result.err(new Error('Parsing Error'))
        }
    }
}

export default query

export { mutation }
