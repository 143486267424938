import { useAuth0 } from "@auth0/auth0-react";
import { FunctionComponent, useContext, useEffect, useState } from "react";
import client from "../../../libary/apollo";
import { Notification } from "../../../libary/Models";
import LoadingSpinner from "../../loadingSpinner";
import NotificationItem from "../NotificationItem";
import { notificationsQuery } from "../queries";

import CloseIcon from '../../../assets/icons/ic_close.svg'

import './styles.css'
import { markAllReadMutation } from "../mutations";
import { ThemeContext } from "../../../App";

interface FloatingNotificationsPanelProps {
    closeNotifications: Function
}

const FloatingNotificationsPanel: FunctionComponent<FloatingNotificationsPanelProps> = (props: FloatingNotificationsPanelProps) => {
    const theme = useContext(ThemeContext)

    const [loading, setLoading] = useState<boolean>(false)
    const [notifications, setNotifications] = useState<Notification[]|null>(null)
    const [token, setToken] = useState<string|null>(null)
    const { getAccessTokenSilently } = useAuth0()

    useEffect(() => {
        (async () => {
            setLoading(true)
            const newToken = await getAccessTokenSilently()
            setToken(newToken)
            client.query({
                query: notificationsQuery, 
                context: {
                    headers: {
                        "Authorization": newToken
                    }
                },
                fetchPolicy: "network-only"
            }).then((response) => {
                const newNotifications = response.data.notifications.map((not) => {
                    return {
                        ...not, 
                        data: JSON.parse(not.data)
                    }
                })
                setNotifications(newNotifications)
                setLoading(false)
            })
        })()
    }, [])

    const renderNotifications = () => {
        if(notifications !== null) {
            if(notifications.length === 0) { 
                return <div className="floating-notifications-panel-empty">You have no notifications</div>
            }
            return notifications.map((not) => {
                return <NotificationItem notification={not}/>
            })   
        }
    }
    
    const markNotificationsRead = () => {
        setLoading(true)
        client.mutate({
            mutation: markAllReadMutation,
            context: {
                headers: {
                    "Authorization": token
                }
            }
        }).then((response) => {
            if(response.data.markNotificationsRead.success === true) {
                const newNotifications = notifications.map((not) => { return {...not, read: true} })
                setNotifications(newNotifications)
            }
            setLoading(false)
        })
    }

    return (
        <div className='floating-notifications-panel'>
            <div className='floating-notifications-header'>
                <div className='floating-notifications-title'>Notifications:</div>
                <div className='floating-notifications-mark-read-button' style={{backgroundColor: theme.colors.secondary}} onClick={() => markNotificationsRead()}>Mark all as Read</div>
                <img className='floating-notifications-close-button' src={CloseIcon} onClick={() => {props.closeNotifications()}}/>
            </div>
            {loading === true && <LoadingSpinner/>}
            <div className='floating-notifications-list'>
            {loading === false && (renderNotifications())}
            </div>
        </div>
    )
}

export default FloatingNotificationsPanel