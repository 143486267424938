import { gql } from "@apollo/client";
import { FunctionComponent, useState } from "react";
import client from "../../../libary/apollo";
import useToken from "../../../libary/hooks/useToken";
import { NewModal } from "../../NewModal";

interface InviteUserModalProps {
    show: boolean
    closeModal: Function
}

const mutation = gql`
mutation($email: String!) {
    inviteUser(email: $email) {
  success
    
    }
  }
`

const InviteUserModal: FunctionComponent<InviteUserModalProps> = (props: InviteUserModalProps) => {

    const [email, setEmail] = useState<string>("")
    const token = useToken()

    const sendInvite = () => {
        client.mutate({
            mutation, 
            variables: {
                email
            }, 
            context: {
                headers: {
                    "Authorization": token
                }
            }
        }).then((response) => {
            if(response.data.inviteUser.success === true) {
                props.closeModal()
            }
        })
    }

    const handleTextChange = (event) => {
        setEmail(event.target.value)
    }

    return (
        <NewModal show={props.show} close={props.closeModal}>
                <div className='modal-add-container'>
                    <p className='modal-add-title'>Invite User</p>
                    <input className="modal-add-experience-input" placeholder='Enter an Email address' onChange={handleTextChange}></input>
                    <div onClick={() => {
                        sendInvite()
                    }} className={email.length > 0 ? 'modal-add-button' : 'modal-add-button disabled'}>Add</div>
                </div>
        </NewModal>
    )
}

export default InviteUserModal