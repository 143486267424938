import { useAuth0 } from '@auth0/auth0-react'
import moment from 'moment'
import { FunctionComponent, useEffect, useState } from 'react'
import ChevronLeft from '../../assets/icons/ic_chevron_left.svg'
import ChevronRight from '../../assets/icons/ic_chevron_right.svg'
import { SessionCounts } from '../../libary/Models'
import { legacyTheme } from '../../themes'
import CalendarDayCell from '../calendar-day-cell'
import { getSessionCounts } from './queries'
import './styles.css'
var dateFns = require('date-fns')

interface CalendarTempProps {
    width: string
    setDate: Function
    // cookies?: Cookies;
    query
    queryName: string
    createSession: Function
    canCreate: boolean
    practice_id?: string
    currentMonth?: Date
    setMonth?: Function
    disabled?: boolean
    LOCALITY_ID?: string
    LOCATION_ID?: string
    EMPLOYER_ID?: string
    reloadCalendar?: boolean
    recurrenceEnabled?: boolean
    filterFilled: boolean
    filterPending: boolean
    recurrenceId?: string
    staff_type?: string
    hideSessionStatus?: boolean
}

const sanitizeMonth = (month: number): string => {
    if (month < 10) {
        return `0${month}`
    }
    return month.toString()
}

const CalendarTemp: FunctionComponent<CalendarTempProps> = (
    props: CalendarTempProps
) => {
    const searchParams = new URLSearchParams(window.location.search)
    const { getAccessTokenSilently } = useAuth0()

    const [currentMonth, setCurrentMonth] = useState<Date>(
        searchParams.get('currentMonth') !== null
            ? moment(parseFloat(searchParams.get('currentMonth'))).toDate()
            : new Date()
    )
    const [selectedDate, setSelectdDate] = useState<Date>(new Date())
    const [sessionCounts, setSessionCounts] = useState<
        SessionCounts[] | undefined
    >(undefined)

    useEffect(() => {
        load()
    }, [
        props.LOCALITY_ID,
        props.LOCATION_ID,
        props.EMPLOYER_ID,
        props.reloadCalendar,
        currentMonth,
    ])

    const load = async () => {
        getAccessTokenSilently().then(async (token) => {
            const month = sanitizeMonth(currentMonth.getMonth() + 1)
            const year = currentMonth.getFullYear().toString()
            if (props.recurrenceId) {
                const result = await getSessionCounts(
                    {
                        month,
                        year,
                        staff_type: props.staff_type,
                        recurrenceId: props.recurrenceId,
                    },
                    token
                )
                if (result.isOk) {
                    setSessionCounts(result.value)
                }
            } else {
                const result = await getSessionCounts(
                    {
                        month,
                        year,
                        localityId: props.LOCALITY_ID,
                        locationId: props.LOCATION_ID,
                        employerId: props.EMPLOYER_ID,
                        filter: {
                            filled: props.filterFilled,
                            pending: props.filterPending,
                        },
                        staff_type: props.staff_type,
                    },
                    token
                )
                if (result.isOk) {
                    setSessionCounts(result.value)
                }
            }
        })
    }

    const renderHeader = () => {
        const dateFormat = 'MMMM yyyy'
        return (
            <div
                className='calendar-header'
                style={{ backgroundColor: legacyTheme().colors.brand }}>
                <div className='DateHeader'>
                    <img
                        className='chevronImage'
                        src={ChevronLeft}
                        onClick={() => prevMonth()}
                    />
                </div>
                <div className='DateHeader'>
                    <div>{dateFns.format(currentMonth, dateFormat)}</div>
                </div>
                <div className='DateHeader' onClick={() => nextMonth()}>
                    <img
                        className='chevronImage'
                        src={ChevronRight}
                        onClick={() => nextMonth()}
                    />
                </div>
            </div>
        )
    }

    const renderDays = () => {
        const days = []

        let startDate = dateFns.startOfWeek(currentMonth)

        for (let i = 0; i < 7; i++) {
            if (window.innerWidth > 700) {
                days.push(
                    <div className='col col-center' key={i}>
                        {moment(dateFns.addDays(startDate, i)).format('dddd')}
                    </div>
                )
            } else {
                days.push(
                    <div className='col col-center' key={i}>
                        {moment(dateFns.addDays(startDate, i)).format('ddd')}
                    </div>
                )
            }
        }

        return <div className='days row'>{days}</div>
    }

    const newRenderCells = (data: SessionCounts[]) => {
        const monthStart = dateFns.startOfMonth(currentMonth)
        const monthEnd = dateFns.endOfMonth(monthStart)
        const startDate = dateFns.startOfWeek(monthStart)
        const endDate = dateFns.endOfWeek(monthEnd)
        const numberOfDays = dateFns.getDaysInMonth(selectedDate)
        const dateFormat = 'd'
        const rows = []

        let days = []
        let day = startDate
        let formattedDate = ''

        var no_applicants: Array<number> = []
        var pending: Array<number> = []
        var filled: Array<number> = []
        var hidden: Array<number> = []
        for (var i = 0; i < numberOfDays; i++) {
            no_applicants[i] = 0
            pending[i] = 0
            filled[i] = 0
            hidden[i] = 0
        }

        for (var j = 0; j < data.length; j++) {
            var date = new Date(data[j].date)
            var dayOfMonth = date.getDate() - 1
            const counts = data[j].counts
            no_applicants[dayOfMonth] = counts.no_applicants
            pending[dayOfMonth] = counts.pending
            filled[dayOfMonth] = counts.filled
            hidden[dayOfMonth] = counts.hidden
        }

        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {
                formattedDate = dateFns.format(day, dateFormat)
                let d = day.getDate() - 1
                let p = pending[d]
                let n = no_applicants[d]
                let f = filled[d]
                let h = hidden[d]
                const cloneDay = day
                days.push(
                    <CalendarDayCell
                        canCreate={props.canCreate && !props.disabled}
                        date={day}
                        counts={{
                            no_applicants: n,
                            pending: p,
                            filled: f,
                            hidden: h,
                        }}
                        cellState={
                            !dateFns.isSameMonth(day, monthStart)
                                ? 'day-disabled'
                                : dateFns.isSameDay(day, selectedDate)
                                ? 'day-selected'
                                : dateFns.isPast(day)
                                ? 'day-past'
                                : 'day'
                        }
                        key={day}
                        day={formattedDate}
                        onClick={() => onDateClick(cloneDay)}
                        createSession={props.createSession}
                        hideSessionStatus={props.hideSessionStatus}
                    />
                )
                day = dateFns.addDays(day, 1)
            }
            rows.push(
                <div className='row' key={day}>
                    {days}
                </div>
            )
            days = []
        }
        return <div className='calendarBody'>{rows}</div>
    }

    const onDateClick = (date: Date) => {
        setSelectdDate(date)
        load()
        props.setDate(date)
    }

    const nextMonth = () => {
        const newDate = dateFns.addMonths(currentMonth, 1)
        setSessionCounts([])
        setCurrentMonth(newDate)
        let params = new URLSearchParams(window.location.search)
        params.set('currentMonth', newDate.getTime())
        window.history.pushState(
            '',
            '',
            `${window.location.pathname}?${params.toString()}`
        )
    }

    const prevMonth = () => {
        const newDate = dateFns.subMonths(currentMonth, 1)
        setSessionCounts([])
        setCurrentMonth(newDate)
        // if(props.setMonth) {
        //     props.setMonth(newDate)
        // }
        let params = new URLSearchParams(window.location.search)
        params.set('currentMonth', newDate.getTime())
        window.history.pushState(
            '',
            '',
            `${window.location.pathname}?${params.toString()}`
        )
    }

    return (
        <div className='calendarContainer'>
            {renderHeader()}
            {renderDays()}
            {sessionCounts && newRenderCells(sessionCounts)}
            {/* {(props.disabled ?? false) === true && <div className="rota-disabled">
                    <div>
                        Please select a locality
                    </div>
                </div>} */}
        </div>
    )
}

export default CalendarTemp
