import { gql } from '@apollo/client'
import { Result } from 'true-myth'
import { SessionCounts } from '../../libary/Models'
import query from '../../libary/network/apollo'

const getSessionCountsQuery = gql`
    query SessionCounts(
        $month: String!
        $year: String!
        $staff_type: String
        $localityId: String
        $locationId: String
        $employerId: String
        $filterFilled: Boolean
        $filterPending: Boolean
        $recurrenceId: String
    ) {
        sessionCounts(
            month: $month
            year: $year
            staff_type: $staff_type
            LOCALITY_ID: $localityId
            LOCATION_ID: $locationId
            EMPLOYER_ID: $employerId
            filterFilled: $filterFilled
            filterPending: $filterPending
            recurrenceId: $recurrenceId
        ) {
            date
            counts {
                filled
                no_applicants
                pending
                hidden
            }
        }
    }
`
interface SessionFilter {
    filled: boolean
    pending: boolean
}

interface GetSessionCountsInput {
    month: string
    year: string
    staff_type?: string
    localityId?: string
    locationId?: string
    employerId?: string
    filter?: SessionFilter
    recurrenceId?: string
}

const getSessionCounts = (
    input: GetSessionCountsInput,
    token: string
): Promise<Result<SessionCounts[], Error>> => {
    return query(
        getSessionCountsQuery,
        token,
        'sessionCounts',
        {
            ...input,
            filterFilled: input.filter?.filled,
            filterPending: input.filter?.pending,
        },
        'network-only'
    )
}

export { getSessionCounts }
