import { useAuth0 } from "@auth0/auth0-react";
import { FunctionComponent, useEffect, useState } from "react";
import Footer from "../../components/Footer";
import LoadingSpinner from "../../components/loadingSpinner";
import GeneralConfirmModal from "../../components/modals/GeneralConfirmModal";
import StaffModal from "../../components/modals/StaffModal";
import NavBarNew from "../../components/NavBarNew";
import Text from "../../components/text";
import AuthContainer from "../../container/AuthContainer";
import client from "../../libary/apollo";
import useFeatures from "../../libary/hooks/useFeatures";
import { PermissionObject, SelectedTab } from "../../libary/Models";
import { approvePermissionRequestMutation, rejectPermissionRequestMutation } from "./mutations";
import PermissionRequestCell from "./PermissionRequestCell";
import { getPermissionRequestsQuery } from "./queries";
import './styles.css'

const PermissionsRequests: FunctionComponent = () => {

    const { getAccessTokenSilently } = useAuth0()
    const { features } = useFeatures()

    const [permissionsRequests, setPermissionsRequests] = useState<PermissionObject[] | null>(null)
    const [loading, setLoading] = useState<boolean>(true)

    const [permissionToApprove, setPermissionToApprove] = useState<PermissionObject | null>(null)
    const [permissionToReject, setPermissionToReject] = useState<PermissionObject | null>(null)
    const [staffId, setStaffId] = useState<string|null>(null)

    const [token, setToken] = useState<string>()

    useEffect(() => {
        if (features === null) { return }
        if (features.editPermissions !== true) {
            window.location.replace('/no')
        }
    }, [features])

    useEffect(() => {
        (async () => {
            const newToken = await getAccessTokenSilently()
            setToken(newToken)
            client.query({
                query: getPermissionRequestsQuery,
                context: {
                    headers: {
                        "Authorization": newToken
                    }
                }
            }).then((response) => {
                console.log("RESPONSE: ", response)
                setPermissionsRequests(response.data.getPermissionRequests)
                setLoading(false)
            })
        })()
    }, [getAccessTokenSilently])

    const approvePermission = () => {
        client.mutate({
            mutation: approvePermissionRequestMutation,
            variables: {
                user_id: permissionToApprove.user_id, 
                permission: permissionToApprove.permission,
                object_id: permissionToApprove.object_id
            },
            context: {
                headers: {
                    "Authorization": token
                }
            }
        }).then((response) => {
            if(response.data.approvePermission.success === true) {
                console.log("PERMISSION: ", permissionToApprove)
                const newPermissions = permissionsRequests.filter((perm) => !(perm  === permissionToApprove))
                
                setPermissionsRequests(newPermissions)
                setPermissionToApprove(null)
            }
        })
    }

    const rejectPermission = () => {
        client.mutate({
            mutation: rejectPermissionRequestMutation,
            variables: {
                user_id: permissionToReject.user_id, 
                permission: permissionToReject.permission,
                object_id: permissionToReject.object_id
            },
            context: {
                headers: {
                    "Authorization": token
                }
            }
        }).then((response) => {
            if(response.data.rejectPermission.success === true) {
                const newPermissions = permissionsRequests.filter((perm) => (perm.user_id !== permissionToReject.user_id && perm.permission !== permissionToReject.permission && perm.object_id !== permissionToReject.object_id))
                setPermissionsRequests(newPermissions)
                setPermissionToReject(null)
            }
        })
    }

    const renderConfirmModal = () => {
        if(permissionToApprove !== null) {
            return <GeneralConfirmModal show={permissionToApprove !== null} title="Approve permission request" closeModal={() => setPermissionToApprove(null)} confirmAction={approvePermission}/>
        } else if(permissionToReject !== null) {
            return <GeneralConfirmModal show={permissionToReject !== null} title="Reject permission request" closeModal={() => setPermissionToReject(null)} confirmAction={rejectPermission}/>
        }
    }

    const renderPermissionRequests = () => {
        if (permissionsRequests !== null) {
            const elements = permissionsRequests.map((perm) => {
                return <PermissionRequestCell
                    permissionRequest={perm}
                    approve={() => setPermissionToApprove(perm)}
                    reject={() => setPermissionToReject(perm)}
                    key={`${perm.user_id}:${perm.object_id}`}
                    viewStaff={() => setStaffId(perm.user_id)}
                />
            })
            return (
                <div className="permissions-requests-list-container">
                    {elements.length === 0  && <div>There are no permissions requests right now</div>}
                    {elements}
                </div>
            )
        }
    }

    return (
        <AuthContainer>
            <div className="permissions-requests-body">
                <NavBarNew setTab={() => { }} selectedTab={SelectedTab.PermissionsRequests} />
                <div className="permissions-requests-header">
                    <Text text="Permission Requests" type="header"/>
                </div>
                <div className="mainBody">
                    {loading === true && <LoadingSpinner />}
                    {loading === false && renderPermissionRequests()}
                </div>
                <Footer />
                {renderConfirmModal()}
                {staffId !== null && <StaffModal show={staffId !== null} closeModal={() => setStaffId(null)} id={staffId}/>}
            </div>
        </AuthContainer>
    )
}

export default PermissionsRequests