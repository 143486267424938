import React, { FunctionComponent, useEffect, useState } from 'react';
import './styles.css'
import { DocumentModel, ItSystemModel, StaffModel } from '../../libary/Models';
import StaffCell from '../../components/staff-cell';
import {  Cookies } from 'react-cookie';
import { gql } from '@apollo/client'
import client from '../../libary/apollo';
import StaffModal from '../../components/modals/StaffModal';
import Mutations from '../../libary/apollo/mutations';
import LoadingSpinner from '../../components/loadingSpinner';
import useToken from '../../libary/hooks/useToken';


const staffQuery = gql`
query staff($id: String!){
    staffById(id: $id) {
      id
      name_value
      staff_type
      title
      GMC_number
      image
      bio
      documents {
        id
        name_value
        staff_name
      }
      workExperience {
        id
        name_value
      }
      itSystems{
        id
        name_value
      }
      languages{
        id
        name_value
    }
  }
}
`

interface PendingStaffProps {
    applicants: StaffModel[], 
    cookies?: Cookies
    sessionId: string
    assignedStaffCallback: Function
}

interface MinimalStaffInformationProps {
    staff?: StaffModel
    documents?: DocumentModel[];
    itSystems?: ItSystemModel[];
}

const PendingStaff: FunctionComponent<PendingStaffProps> = (props: PendingStaffProps) => {

    const token = useToken()
    const [showStaffInfo, setShowStaffInfo] = useState<boolean>(false)
    // const [staff, setStaff] = useState<StaffModel | null>(null)
    const [staffId, setStaffId] = useState<number>(-1)
    const [staffInfoProps, setStaffInfoProps] = useState<MinimalStaffInformationProps|null>(null)
    const [assigning, setAssigning] = useState<boolean>(false)

    useEffect(() => {
        if(staffId !== -1) {
            client.query({
                query: staffQuery, 
                variables: {
                    id: staffId.toString()
                },
                context: {
                    headers: {
                        authorization: token
                    }
                }
            }).then((response) => {
                setStaffInfoProps({
                    staff: response.data.staffById, 
                    documents: response.data.documents, 
                    itSystems: response.data.itSystems
                    
                })
            })
        }
    }, [staffId])

    const _renderApplicants = () => {
        var applicants = []
        props.applicants.forEach((applicant) => {
            applicants.push(<StaffCell key={applicant.id} staff={applicant} onClick={(newStaff) => {
                setShowStaffInfo(true)
                setStaffId(newStaff.id)
            }}
            assignButtonCallback={() => {
                setAssigning(true)
                Mutations.assignToSession(props.sessionId, applicant.id, token)
                .then((response) => {
                    setAssigning(false)
                    props.assignedStaffCallback(applicant)
                })
                .catch((erorr) => {
                    setAssigning(false)
                    alert("Failed to assign to session")
                })
            }}/>) 
        })
        return applicants
    }

    if(assigning) {
        return <LoadingSpinner text='Assigning to session'/>
    }

    if(showStaffInfo) {
        return (
            <div className="pendingContainerShowingStaffInfo">
                {staffInfoProps === null && (<LoadingSpinner/>)}
                {staffInfoProps !== null && (<StaffModal showPromote={false} id={staffId.toString()} show={true} closeModal={() => {setShowStaffInfo(false)}}/>)}
            </div>
        )
    } else {
        return (
            <div className="pendingContainer">
                {_renderApplicants()}
            </div>
        )
    }
}
export default PendingStaff