import { gql } from "@apollo/client"

const staffQuery = gql`
query staff($id: String!){
  staffById(id: $id) {
    id
    name_value
    staff_type
    title
    GMC_number
    image
    bio
    approved
    email
    documents {
        id
        approved
        name_value
        staff_id
        staff_name
    }
    workExperience {
      id
      name_value
    }
    itSystems{
      id
      name_value
    }
    languages{
      id
      name_value
      short_name
  }
    qualifications{
      id
      name_value
    }
  }
}
`

export {
    staffQuery
}