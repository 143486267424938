import { gql } from '@apollo/client'
import { Result } from 'true-myth'
import { SessionModel } from '../../libary/Models'
import query from '../../libary/network/apollo'

const sessionsForStaffMemberQuery = gql`
    query SessionsForStaffMember(
        $staffId: String!
        $upcoming: Boolean!
        $month: String
        $year: String
    ) {
        sessionsForStaffMember(
            staff_id: $staffId
            upcoming: $upcoming
            month: $month
            year: $year
        ) {
            id
            name_value
            session_date
            session_status
            start_time
            end_time
            staff_type
            locality {
                id
                name_value
            }
            location {
                id
                name_value
            }
        }
    }
`

const sessionsForStaffMember = async (
    input: { staffId: string; upcoming: boolean; month: string; year: string },
    token: string
): Promise<Result<SessionModel[], Error>> => {
    return query(sessionsForStaffMemberQuery, token, 'sessionsForStaffMember', {
        ...input,
    })
}

export { sessionsForStaffMember }
