import React, { createContext } from "react";
import { Features } from "../Models";

export interface FeaturesProviderProps {
  features?: Features
  setFeatures?: any
}

export interface FeaturesContextResponseInterface { features: Features, setFeatures: any}
export const FeaturesContextResponse = {features: null, setFeatures: (newFeatures) => {}}

const FeaturesContext = createContext(FeaturesContextResponse)

export const FeaturesProvider: React.FC<FeaturesProviderProps> = ({
  children,
  features,
  setFeatures
}) => {

    return <FeaturesContext.Provider value={{features: features, setFeatures}}>
      {children}
      </FeaturesContext.Provider>
};

export function useFeatures(): FeaturesContextResponseInterface {
  return React.useContext(FeaturesContext);
}

export default useFeatures;