import { useAuth0 } from '@auth0/auth0-react'
import { Avatar, Box, Button, Grid, Paper, Typography } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import SearchField from '../../../components/search-field/SearchField'
import { StaffModel } from '../../../libary/Models'
import { getStaffList } from './queries'

interface AssignStaffTabProps {
    staff_type: string
    showStaff: (id: string) => void
    onAssignStaff: (staff: StaffModel) => void
    token: string
}

const AssignStaffTab: FC<AssignStaffTabProps> = (
    props: AssignStaffTabProps
) => {
    const { getAccessTokenSilently } = useAuth0()
    const [staffList, setStaffList] = useState<StaffModel[]>([])
    const [searchTerm, setSearchTerm] = useState<string>('')

    const loadStaff = async () => {
        const result = await getStaffList(props.staff_type, props.token)
        if (result.isOk) {
            setStaffList(result.value)
        }
    }

    useEffect(() => {
        loadStaff()
    }, [getAccessTokenSilently])

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'hidden',
            }}>
            <SearchField onUpdate={(value: string) => setSearchTerm(value)} />
            <div
                style={{
                    marginTop: '8px',
                    overflowY: 'hidden',
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                <Grid container spacing={2} sx={{ overflowY: 'scroll' }}>
                    {staffList
                        .filter((staff) =>
                            staff.name_value
                                ?.toLowerCase()
                                .includes(searchTerm.toLowerCase())
                        )
                        .map((staff: StaffModel) => {
                            return (
                                <Grid key={staff.id} item xs={6}>
                                    <div
                                        style={{ cursor: 'pointer' }}
                                        onClick={() =>
                                            props.showStaff(staff.id)
                                        }>
                                        <Paper
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                padding: '8px',
                                            }}>
                                            <Avatar src={staff.image}>
                                                {staff.image === null &&
                                                    staff.name_value[0]}
                                            </Avatar>
                                            <Typography variant='subtitle1'>
                                                {staff.name_value}
                                            </Typography>
                                            <Button
                                                variant='contained'
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    props.onAssignStaff(staff)
                                                }}>
                                                Assign
                                            </Button>
                                        </Paper>
                                    </div>
                                </Grid>
                            )
                        })}
                </Grid>
            </div>
        </Box>
    )
}

export default AssignStaffTab
