import { useAuth0 } from '@auth0/auth0-react'
import { Box, Modal } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { StaffModel } from '../../../libary/Models'
import StaffView from '../../StaffView/StaffView'
import { getStaffById } from './queries'

interface StaffModalProps {
    open: boolean
    id: string
    closeModal: Function
}

const StaffModalNew: FC<StaffModalProps> = ({ open, id, closeModal }) => {
    const [staff, setStaff] = useState<StaffModel | undefined>(undefined)
    const { getAccessTokenSilently } = useAuth0()

    useEffect(() => {
        ;(async () => {
            const token = await getAccessTokenSilently()
            const result = await getStaffById(id, token)
            if (result.isOk) {
                setStaff(result.value)
            }
        })()
    }, [])

    return (
        <Modal open={open} onClose={() => closeModal()}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    outline: 'none',
                }}>
                {staff && <StaffView staff={staff} />}
            </Box>
        </Modal>
    )
}

export default StaffModalNew
