import { gql } from "@apollo/client"

const mySessionsQuery = gql`
query mySessions($upcoming: Boolean) {
    mySessions(upcoming: $upcoming) {
        id
        session_status
        session_date
        pricePerHour
        break
        start_time
        end_time
        location{
            name_value
        }
        has_applied
        staff {
            id
            name_value
        }
    }
}
`

export { 
    mySessionsQuery
}