import { useAuth0 } from '@auth0/auth0-react'
import {
    DeleteRounded,
    HorizontalRuleRounded,
    OpenInNewRounded,
    RepeatRounded,
} from '@mui/icons-material'
import {
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Paper,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material'
import moment from 'moment'
import { FC, useEffect, useState } from 'react'
import Footer from '../../components/Footer'
import MuiDropdown from '../../components/MuiWrappers/MuiDropdown/MuiDropdown'
import NavBarNew from '../../components/NavBarNew/NavBarNew'
import ConfirmDeleteModal, {
    ConfirmDeleteType,
} from '../../components/modals/ConfirmDeleteModal/ConfirmDeleteModal'
import { renderRecurrenceItemText } from '../../components/modals/CreateSessionModalNew/helpers'
import AuthContainer from '../../container/AuthContainer'
import { idWithoutHash } from '../../libary/IDHelpers'
import {
    LocalityModel,
    LocationModel,
    RecurrenceModel,
    RecurrenceSubModel,
    SelectedTab,
} from '../../libary/Models'
import { deleteRecurrence } from './mutations'
import { getLocalities, getLocations, getRecurrences } from './queries'

const Recurrences: FC = () => {
    const { getAccessTokenSilently } = useAuth0()
    const [localities, setLocalities] = useState<LocalityModel[]>(undefined)
    const [locations, setLocations] = useState<LocationModel[]>(undefined)
    const [locality, setLocality] = useState<LocalityModel>(undefined)
    const [location, setLocation] = useState<LocationModel>(undefined)
    const [recurrences, setRecurrences] = useState<
        RecurrenceModel[] | undefined
    >(undefined)

    const [deletingRecurrence, setDeletingRecurrence] = useState<
        string | undefined
    >(undefined)

    useEffect(() => {
        ;(async () => {
            const token = await getAccessTokenSilently()
            const localitiesResult = await getLocalities(token)
            if (localitiesResult.isOk) {
                setLocalities(localitiesResult.value)
            }
            console.log('LOAD')
            const localityId = locality ? locality.id : undefined
            const locationId = location ? location.id : undefined
            const result = await getRecurrences(
                {
                    localityId,
                    locationId,
                },
                token
            )
            if (result.isOk) {
                setRecurrences(result.value)
            }
        })()
    }, [locality, location])

    useEffect(() => {
        ;(async () => {
            if (locality === undefined) {
                return
            }
            const token = await getAccessTokenSilently()
            const locationResult = await getLocations(locality.id, token)
            if (locationResult.isOk) {
                setLocations(locationResult.value)
            }
        })()
    }, [locality])

    const deleteRecurrenceItem = async () => {
        const token = await getAccessTokenSilently()
        const result = await deleteRecurrence(deletingRecurrence, token)
        if (result.isOk) {
            const tempRecurrences = [...recurrences].filter(
                (item) => item.id !== deletingRecurrence
            )
            setRecurrences(tempRecurrences)
            setDeletingRecurrence(undefined)
        }
    }

    const renderModels = (models: RecurrenceSubModel[]) => {
        return models.map((model) => {
            return (
                <ListItemText>{renderRecurrenceItemText(model)}</ListItemText>
            )
        })
    }

    const renderRecurrences = () => {
        return recurrences.map((recc: RecurrenceModel) => {
            return (
                <ListItem>
                    <Paper sx={{ padding: '8px' }}>
                        <Stack direction='row' alignItems='center'>
                            <RepeatRounded sx={{ marginRight: '8px' }} />
                            <ListItemText>
                                {renderModels(recc.models)}
                            </ListItemText>
                            <IconButton
                                onClick={() => {
                                    setDeletingRecurrence(recc.id)
                                }}>
                                <Tooltip title='Delete this recurrence'>
                                    <DeleteRounded />
                                </Tooltip>
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    window.location.href = `/recurrences/${idWithoutHash(
                                        recc.id
                                    )}?currentMonth=${
                                        moment(recc.start_date).unix() * 1000
                                    }`
                                }}>
                                <Tooltip title='View sessions for this recurrence'>
                                    <OpenInNewRounded />
                                </Tooltip>
                            </IconButton>
                            <IconButton>
                                <Tooltip title='View exclusions for this recurrence'>
                                    <HorizontalRuleRounded />
                                </Tooltip>
                            </IconButton>
                        </Stack>
                        <ListItemText>
                            Start Date: {recc.start_date}
                        </ListItemText>
                        <ListItemText>End Date: {recc.end_date}</ListItemText>
                        <ListItemText>
                            Staff Type: {recc.session_template.staff_type}
                        </ListItemText>
                    </Paper>
                </ListItem>
            )
        })
    }

    return (
        <AuthContainer>
            <div className='adminBody'>
                <NavBarNew
                    setTab={() => {}}
                    selectedTab={SelectedTab.Recurrences}
                />
                <div className='mainBody'>
                    <Grid container sx={{ padding: '16px' }} spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant='h4'>Recurrences</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <MuiDropdown
                                label='Locality'
                                onChange={(item) => {
                                    setLocality(
                                        localities.filter(
                                            (i) => i.id === item
                                        )[0]
                                    )
                                }}
                                error=''
                                items={localities}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <MuiDropdown
                                label='Location'
                                onChange={(item) => {
                                    setLocation(
                                        locations.filter(
                                            (i) => i.id === item
                                        )[0]
                                    )
                                }}
                                error=''
                                items={locations}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <List>
                                {recurrences && renderRecurrences()}
                                {(!recurrences ||
                                    (recurrences &&
                                        recurrences.length === 0)) && (
                                    <ListItem>
                                        <ListItemText>
                                            There is no schedule for these
                                            locations
                                        </ListItemText>
                                    </ListItem>
                                )}
                            </List>
                        </Grid>
                    </Grid>
                </div>
                <ConfirmDeleteModal
                    closeModal={() => setDeletingRecurrence(undefined)}
                    confirmAction={() => deleteRecurrenceItem()}
                    deleteType={ConfirmDeleteType.RECURRENCE}
                    show={deletingRecurrence !== undefined}
                />
                <Footer />
            </div>
        </AuthContainer>
    )
}

export default Recurrences
