import { useAuth0 } from '@auth0/auth0-react'
import { ThemeProvider } from '@emotion/react'
import { Modal, Paper, Typography, createTheme } from '@mui/material'
import moment from 'moment'
import { FC, useEffect, useState } from 'react'
import { LocalityModel, LocationModel } from '../../../libary/Models'
import { legacyTheme } from '../../../themes'
import CreateSessionModalStepper from './CreateSessionModalStepper'
import CreateSessionDetails from './Pages/CreateSessionDetails/CreateSessionDetails'
import CreateSessionLocation from './Pages/CreateSessionLocation/CreateSessionLocation'
import CreateSessionReview from './Pages/CreateSessionReview/CreateSessionReview'
import CreateSessionSchedule from './Pages/CreateSessionSchedule/CreateSessionSchedule'
import { CreateSessionPage } from './models'
import {
    CreateRecurrenceInput,
    CreateSessionInput,
    EmptyCreateSessionInput,
    createRecurrence,
    createSession,
} from './mutations'
import { SessionConfig, getSessionConfig } from './queries'

interface CreateSessionModalNewProps {
    show: boolean
    closeModal: (reload?: boolean) => void
    createSessionCallback: Function
    createSessionDate: Date
    selectedLocality?: LocalityModel
    selectedLocation?: LocationModel
}

const theme = createTheme({
    palette: {
        primary: {
            main: legacyTheme().colors.secondary,
        },
    },
})

const CreateSessionModalNew: FC<CreateSessionModalNewProps> = (
    props: CreateSessionModalNewProps
) => {
    const { getAccessTokenSilently } = useAuth0()
    const [token, setToken] = useState<string>(undefined)
    const [sessionConfig, setSessionConfig] = useState<
        SessionConfig | undefined
    >(undefined)
    const [createRecurrenceInput, setCreateRecurrenceInput] = useState<
        CreateRecurrenceInput | undefined
    >(undefined)
    const [createSessionInput, setCreateSessionInput] =
        useState<CreateSessionInput>({
            ...EmptyCreateSessionInput,
            session_date: moment(props.createSessionDate).format('YYYY-MM-DD'),
            LOCALITY_ID: props.selectedLocality?.id,
            locality_name: props.selectedLocality?.name_value,
            LOCATION_ID: props.selectedLocation?.id,
            location_name: props.selectedLocation?.name_value,
        })

    const [createSessionPage, setCreateSessionPage] =
        useState<CreateSessionPage>(CreateSessionPage.LOCATION)
    useEffect(() => {
        ;(async () => {
            const newToken = await getAccessTokenSilently()
            setToken(newToken)
            const result = await getSessionConfig(newToken)
            if (result.isOk) {
                setSessionConfig(result.value)
            }
        })()
    }, [getAccessTokenSilently])

    const renderPage = () => {
        switch (createSessionPage) {
            case CreateSessionPage.LOCATION:
                return (
                    <CreateSessionLocation
                        token={token}
                        createSessionInput={createSessionInput}
                        goBack={() => props.closeModal()}
                        sessionLocationInfoUpdated={(info) => {
                            setCreateSessionInput({
                                ...createSessionInput,
                                LOCALITY_ID: info.locality.id,
                                locality_name: info.locality.name_value,
                                LOCATION_ID: info.location.id,
                                location_name: info.location.name_value,
                                EMPLOYER_ID: info.employer.id,
                                employer_name: info.employer.name_value,
                                staff_type: info.staffType.name_value,
                                it_system_id: info.itSystem.id,
                            })
                            setCreateSessionPage(CreateSessionPage.DETAILS)
                        }}
                    />
                )
            case CreateSessionPage.DETAILS:
                return (
                    <CreateSessionDetails
                        location_name={createSessionInput.location_name}
                        staff_type={createSessionInput.staff_type}
                        sessionConfig={sessionConfig}
                        token={token}
                        goBack={() =>
                            setCreateSessionPage(CreateSessionPage.LOCATION)
                        }
                        createSessionInput={createSessionInput}
                        sessionDetailsUpdated={(info) => {
                            setCreateSessionInput({
                                ...createSessionInput,
                                ...info,
                                length_of_appointments:
                                    sessionConfig.hiddenFields
                                        .lengthOfAppointments === true
                                        ? '0'
                                        : info.length_of_appointments,
                            })
                            setCreateSessionPage(CreateSessionPage.RECURRENCE)
                        }}
                    />
                )
            case CreateSessionPage.RECURRENCE:
                return (
                    <CreateSessionSchedule
                        sessionDate={props.createSessionDate}
                        sessionScheduleUpdated={(
                            start_date,
                            end_date,
                            recurrenceModels
                        ) => {
                            setCreateRecurrenceInput({
                                start_date:
                                    moment(start_date).format('YYYY-MM-DD'),
                                end_date: moment(end_date).format('YYYY-MM-DD'),
                                models: recurrenceModels,
                                session_template: createSessionInput,
                                staff_type: createSessionInput.staff_type,
                                LOCALITY_ID: createSessionInput.LOCALITY_ID,
                                LOCATION_ID: createSessionInput.LOCATION_ID,
                            })
                            setCreateSessionPage(CreateSessionPage.REVIEW)
                        }}
                        skipSchedule={() => {
                            setCreateSessionPage(CreateSessionPage.REVIEW)
                        }}
                        goBack={() =>
                            setCreateSessionPage(CreateSessionPage.DETAILS)
                        }
                    />
                )
            case CreateSessionPage.REVIEW:
                return (
                    <CreateSessionReview
                        createRecurrenceInput={createRecurrenceInput}
                        createSessionInput={createSessionInput}
                        createSession={async () => {
                            if (createRecurrenceInput) {
                                const result = await createRecurrence(
                                    createRecurrenceInput,
                                    token
                                )
                                if (result.isOk) {
                                    props.closeModal(true)
                                }
                            } else {
                                const result = await createSession(
                                    createSessionInput,
                                    token
                                )
                                if (result.isOk) {
                                    props.closeModal(true)
                                }
                            }
                        }}
                        goBack={() =>
                            setCreateSessionPage(CreateSessionPage.RECURRENCE)
                        }
                    />
                )
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <Modal
                open={props.show}
                onClose={() => {
                    props.closeModal()
                }}>
                <div
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        outline: 'none',
                        minWidth: '40%',
                    }}>
                    <Paper sx={{ padding: '16px' }}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}>
                            <Typography
                                variant='h5'
                                sx={{ marginBottom: '8px' }}>
                                Create Session
                            </Typography>
                            <div style={{ marginBottom: '16px' }}>
                                <CreateSessionModalStepper
                                    activeStep={createSessionPage}
                                />
                            </div>
                            {token && sessionConfig && renderPage()}
                        </div>
                    </Paper>
                </div>
            </Modal>
        </ThemeProvider>
    )
}

export default CreateSessionModalNew
