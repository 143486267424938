import { Auth0ContextInterface } from "@auth0/auth0-react";
import jwt from "jsonwebtoken";

class UserHelper {
  static token = async (
    cookies: any,
    auth0: Auth0ContextInterface,
    setCookie: any
  ) => {
    if (cookies["token"] !== undefined) {
      const token = cookies.token;
      const { exp } = jwt.decode(token);
      const now = new Date().getTime();
      if (now <= exp * 1000) {
        return token;
      } else {
        const newToken = await this.getTokenFromAuth0(auth0);
        setCookie("token", newToken);
        return newToken;
      }
    } else {
      const newToken = await this.getTokenFromAuth0(auth0);
      setCookie("token", newToken);
      return newToken;
    }
  };
  static getTokenFromAuth0 = async (
    auth0: Auth0ContextInterface
  ): Promise<string> => {
    const { getAccessTokenSilently } = auth0;
    return await getAccessTokenSilently();
  };
}

export default UserHelper;
