import Config from "./Config";

const DevConfig: Config = {
    auth0: {
        clientId: "554ZR5Y64UbKI9ix7Aq1sAVdSwv7aRmS",
        domain: "dev-rgyntzi9.eu.auth0.com",
        redirectUri: "http://localhost:3000",
        audience: "https://graphql.fedworks.org",
        useRefreshTokens: true,
        cacheLocation: "localstorage"
    }
}

export default DevConfig