import React, { FunctionComponent, useEffect, useState } from 'react'

import './styles.css'
import StaffCell from '../../../components/staff-cell';
import { gql } from '@apollo/client';
import client from '../../../libary/apollo';
import { StaffModel } from '../../../libary/Models';
import { useAuth0 } from '@auth0/auth0-react';
import loadingSpinner from '../../../components/loadingSpinner';

interface AdminStaffListProps {
    select?: Function
}

const query = gql`
query staff($staffType: String) {
    staff(staff_type: $staffType) {
      id
      name_value
      staff_type
      image
    }
  }
  `

const AdminStaffList: FunctionComponent<AdminStaffListProps> = (props: AdminStaffListProps) => {
    const [staff, setStaff] = useState<StaffModel[] | null>(null)

    const { getAccessTokenSilently } = useAuth0()

    useEffect(() => {
        loadStaff()
    }, [])

    const loadStaff = () => {
        (async () => {
            const token = await getAccessTokenSilently();
            client.query({
                query,
                variables: {
                    staffType: "ADMIN"
                },
                context: {
                    headers: {
                        authorization: token
                    }
                }
            }).then((response) => {
                setStaff(response.data.staff)
            })
        })()
    }

    const showStaffModal = (staffMember) => {
        props.select(staffMember)
    }

    const renderStaff = () => {
        return staff
            .filter((model) => model.staff_type === "ADMIN")
            .map((staffModel) => {
                return <StaffCell staff={staffModel} onClick={(staff) => showStaffModal(staffModel)} key={staffModel.id} />
            })
    }

    if (staff !== null) {
        return (
            <div className="staff-list-container">
                <div className='admin-staff-list-title'>Admins: </div>
                <div className="staff-list-list">
                    {renderStaff()}
                </div>
            </div>
        )
    } else {
        return loadingSpinner({})
    }
}

export default AdminStaffList