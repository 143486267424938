import React, { FunctionComponent, useState } from 'react'
import Mutations from '../../../libary/apollo/mutations'
import useToken from '../../../libary/hooks/useToken'
import { NewModal } from '../../NewModal'

import '../styles.css'
import './styles.css'

interface AddWorkExperienceModalProps {
    show: boolean
    closeModal: Function
    qualificationAdded: Function
}

const AddQualificationModal: FunctionComponent<AddWorkExperienceModalProps> = (props: AddWorkExperienceModalProps) => {
    
    const [newQualification, setNewQualification] = useState<string>("")
    const token = useToken()
    const handleTextChange = (e) => {
        setNewQualification(e.target.value)
    }

    const addQualification = async () => {
        if(newQualification !== "") {
            Mutations.addQualification(newQualification, token)
            .then((response) => {
                if (response.success === true) {
                    props.closeModal()
                    props.qualificationAdded({id: response.id, name_value: newQualification})
                } else {
                    alert("Failed to add system")
                }
            })
        }
    }

    return (
        <NewModal show={props.show} close={props.closeModal}>
                <div className='modal-add-container'>
                    <p className='modal-add-title'>Add Qualification</p>
                    <input className="modal-add-qualification-input" placeholder='Enter qualification name' onChange={handleTextChange}></input>
                    <div onClick={() => addQualification()} className={newQualification.length === 0 ? 'modal-add-button disabled' : 'modal-add-button'}>Add</div>
                </div>
        </NewModal>
    )
}

export default AddQualificationModal