import moment from 'moment';
import React, { FunctionComponent } from 'react';
import { SessionModel } from '../../libary/Models';
import ListCard from '../../staff/components/list-card';
import './styles.css';
import { SessionsListProps } from './types';


function dynamicSort(property: string) {
    var sortOrder = 1;
    if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a: any, b: any) {
        /* next line works with strings and numbers, 
         * and you may want to customize it to your needs
         */
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
}

// const dateSort = (a: any,b: any) => {
//     /* next line works with strings and numbers, 
//      * and you may want to customize it to your needs
//      */
//     var result = (moment(a.session_date).unix() < moment(b.session_date).unix()) ? -1 : (moment(a.session_date).unix() > moment(b).unix()) ? 1 : 0;
//     return result * 1;
// }


const SessionsList: FunctionComponent<SessionsListProps> = (props: SessionsListProps) => {

    const sessionClicked = (session) => {
        props.onClick(session)
    }

    const renderSessions = (sessions: SessionModel[]) => {

        var sortedSessions = sessions.slice().sort(dynamicSort("session_date"))
        // return <div></div>
        return sortedSessions.map((session: SessionModel) => {
            return (
                <div>
                    <div key={session.id + "_title"}>{moment(session.session_date).format('dddd Do MMMM YYYY')}</div>
                    <ListCard session={session} onClick={() => { sessionClicked(session) }} key={session.id} />
                </div>
            )
        })
    }

    return (
        <div className='session_list'>
            {renderSessions(props.sessions)}
        </div>
    )
}

export default SessionsList;