import { gql } from "@apollo/client";

const updateSessionStaffTypeMutation = gql`
mutation updateSessionStaffType($sessionId: String!, $staffType: String!) {
    updateSessionStaffType(session_id: $sessionId, staff_type: $staffType) {
      success
    }
  }
`

export { 
    updateSessionStaffTypeMutation
}