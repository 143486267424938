import { Avatar, Box, Button, Grid, Paper, Typography } from '@mui/material'
import { FC } from 'react'
import { StaffModel } from '../../../libary/Models'

interface AssignStaffTabProps {
    applications: StaffModel[]
    showStaff: (id: string) => void
    onAssignStaff: (staff: StaffModel) => void
}

const ApplicationsTab: FC<AssignStaffTabProps> = (
    props: AssignStaffTabProps
) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'hidden',
            }}>
            <div
                style={{
                    marginTop: '8px',
                    overflowY: 'hidden',
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                <Grid container sx={{ overflowY: 'scroll' }}>
                    {props.applications.map((staff: StaffModel) => {
                        const id = `USER#${staff.id.split('#')[2]}`
                        console.log('ID', id)
                        const tempStaff = {
                            ...staff,
                            id: id,
                        }
                        return (
                            <Grid key={tempStaff.id} item xs={6}>
                                <div
                                    style={{ cursor: 'pointer' }}
                                    onClick={() =>
                                        props.showStaff(tempStaff.id)
                                    }>
                                    <Paper
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            padding: '8px',
                                        }}>
                                        <Avatar src={tempStaff.id}>
                                            {tempStaff.image === null &&
                                                tempStaff.name_value[0]}
                                        </Avatar>
                                        <Typography variant='subtitle1'>
                                            {tempStaff.name_value}
                                        </Typography>
                                        <Button
                                            variant='contained'
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                props.onAssignStaff(tempStaff)
                                            }}>
                                            Assign
                                        </Button>
                                    </Paper>
                                </div>
                            </Grid>
                        )
                    })}
                </Grid>
            </div>
        </Box>
    )
}

export default ApplicationsTab
