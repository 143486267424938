import { FunctionComponent, useContext } from "react";
import { ThemeContext } from "../../App";
import './styles.css'

const NoPermissionsPage: FunctionComponent = () => {
    const theme = useContext(ThemeContext)

    return (
        <div className="no-permissions-container">
            <img src={theme.images.logo} className="no-permissions-logo"/>
            <h1 className="no-permissions-text">You don't have permission to access this page. <a href="/" className="no-permissions-text-link">Click Here to return to homepage</a></h1>
        </div>
    )
}

export default NoPermissionsPage