import { useAuth0 } from '@auth0/auth0-react'
import { FunctionComponent, useEffect, useState } from 'react'
import { Cookies } from 'react-cookie'
import Footer from '../../../components/Footer'
import NavBarNew from '../../../components/NavBarNew'
import LoadingSpinner from '../../../components/loadingSpinner'
import SessionModal from '../../../components/modals/SessionModal'
import SessionsList from '../../../components/sessions-list'
import Text from '../../../components/text'
import AuthContainer from '../../../container/AuthContainer'
import {
    ApplicationModel,
    SelectedTab,
    SessionModel,
} from '../../../libary/Models'
import client from '../../../libary/apollo'
import useFeatures from '../../../libary/hooks/useFeatures'
import PendingSessions from './PendingSessions'
import { mySessionsQuery } from './queries'
import './styles.css'

interface StaffDashboardProps {
    cookies?: Cookies
}

const StaffDashboard: FunctionComponent<StaffDashboardProps> = (
    props: StaffDashboardProps
) => {
    const [loading, setLoading] = useState<boolean>(true)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [startDate, setStartDate] = useState<Date>(new Date())
    const [endDate, setEndDate] = useState<Date>(new Date())
    const [upcoming, setUpcoming] = useState<boolean>(true)
    const [sessions, setSessions] = useState<SessionModel[] | null>(null)
    const [session, setSession] = useState<SessionModel | null>(null)
    const [sessionId, setSessionId] = useState<string | undefined>(undefined)
    const { getAccessTokenSilently } = useAuth0()
    const { features } = useFeatures()

    useEffect(() => {
        if (features === null) {
            return
        }
        if (features.findSessions !== true) {
            window.location.replace('/no')
        }
    }, [features])

    useEffect(() => {
        var tempEndDate = new Date()
        tempEndDate.setDate(tempEndDate.getDate() + 31)
        setEndDate(tempEndDate)
    }, [])

    useEffect(() => {
        loadSessions()
    }, [endDate])

    const loadSessions = async () => {
        const token = await getAccessTokenSilently()
        client
            .query({
                query: mySessionsQuery,
                variables: {
                    upcoming,
                },
                context: {
                    headers: {
                        authorization: token,
                    },
                },
                fetchPolicy: 'network-only',
            })
            .then((response) => {
                setLoading(false)
                setSessions(response.data.mySessions)
            })
    }

    const clickSession = (newSession) => {
        setSession(newSession)
    }

    const pastSessionsSelected = () => {
        setUpcoming(false)
        setStartDate(new Date('1970-01-01'))
        setEndDate(new Date())
    }

    const upcomingSessionsSelected = () => {
        var tempEndDate = new Date()
        tempEndDate.setDate(tempEndDate.getDate() + 31)
        setUpcoming(true)
        setStartDate(new Date())
        setEndDate(tempEndDate)
    }

    const renderSessions = () => {
        if (sessions !== null) {
            if (sessions.length > 0) {
                return (
                    <SessionsList
                        sessions={sessions}
                        onClick={(session) => {
                            clickSession(session)
                        }}
                    />
                )
            } else {
                const text = upcoming
                    ? 'You have no upcoming sessions'
                    : 'You have no past sessions'
                return (
                    <div className='emptySessionsContainer'>
                        {text}
                        <div
                            className='find-sessions-button'
                            onClick={() => {
                                window.history.pushState(null, null, '/search')
                                window.location.replace('/search')
                            }}>
                            Find sessions
                        </div>
                    </div>
                )
            }
        } else {
            return
        }
    }

    return (
        <AuthContainer>
            <div className='dasboardBody'>
                <NavBarNew
                    setTab={() => {}}
                    selectedTab={SelectedTab.MyDiary}
                />
                <div className='mainBody'>
                    <div className='staff-dashboard-session-list-container'>
                        <PendingSessions
                            sessionSelected={(
                                sessionSelected: ApplicationModel
                            ) => {
                                setSessionId(sessionSelected.id)
                            }}
                        />
                    </div>
                    <div className='staff-dashboard-session-list-container'>
                        <div className='staff-dashboard-header-row'>
                            <Text
                                onClick={() => upcomingSessionsSelected()}
                                type='header'
                                text='Upcoming Sessions'
                                color={upcoming ? '#692068' : 'black'}
                                underline={upcoming}
                            />
                            <Text
                                onClick={() => pastSessionsSelected()}
                                type='header'
                                text='Past Sessions'
                                marginLeft='16px'
                                color={upcoming ? 'black' : '#692068'}
                                underline={!upcoming}
                            />
                        </div>
                        {loading === true && <LoadingSpinner />}
                        {renderSessions()}
                        {session !== null && (
                            <SessionModal
                                show={session !== null}
                                id={`${session.id}`}
                                closeModal={(reload: boolean) => {
                                    if (reload) {
                                        window.location.reload()
                                    } else {
                                        setSession(null)
                                    }
                                }}
                                showOpenInNewWindow={false}
                            />
                        )}
                        {sessionId !== undefined && (
                            <SessionModal
                                show={sessionId !== null}
                                id={sessionId}
                                closeModal={(reload: boolean) => {
                                    if (reload) {
                                        window.location.reload()
                                    } else {
                                        setSessionId(undefined)
                                    }
                                }}
                                showOpenInNewWindow={false}
                            />
                        )}
                    </div>
                </div>
                <Footer />
            </div>
        </AuthContainer>
    )
}

export default StaffDashboard
