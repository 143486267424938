import { Step, StepLabel, Stepper } from '@mui/material'
import React, { FC } from 'react'
import { CreateSessionPage } from './models'

interface CreateSessionModalStepperProps {
    activeStep: CreateSessionPage
}

const CreateSessionModalStepper: FC<CreateSessionModalStepperProps> = ({
    activeStep,
}) => {
    const steps = [
        CreateSessionPage.LOCATION,
        CreateSessionPage.DETAILS,
        CreateSessionPage.RECURRENCE,
        CreateSessionPage.REVIEW,
    ]
    const getStepTitle = (step: CreateSessionPage) => {
        switch (step) {
            case CreateSessionPage.LOCATION:
                return 'Session Location'
            case CreateSessionPage.DETAILS:
                return 'Session Details'
            case CreateSessionPage.RECURRENCE:
                return 'Session Schedule'
            case CreateSessionPage.REVIEW:
                return 'Review Session'
        }
    }
    return (
        <Stepper activeStep={activeStep}>
            {steps.map((step, index) => {
                const stepProps: { completed?: boolean } = {}
                const labelProps: {
                    optional?: React.ReactNode
                } = {}

                return (
                    <Step key={step} {...stepProps}>
                        <StepLabel {...labelProps}>
                            {getStepTitle(step)}
                        </StepLabel>
                    </Step>
                )
            })}
        </Stepper>
    )
}

export default CreateSessionModalStepper
