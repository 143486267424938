import { RepeatRounded } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
    Button,
    Grid,
    ListItem,
    ListItemIcon,
    ListItemText,
    TextField,
    Typography,
} from '@mui/material'
import moment from 'moment'
import { FC, useState } from 'react'

import MuiDatePicker from '../../../../MuiWrappers/MuiDatePicker/MuiDatePicker'
import MuiTimePicker from '../../../../MuiWrappers/MuiTimePicker/MuiTimePicker'
import { renderRecurrenceItemText } from '../../helpers'
import { CreateRecurrenceInput, CreateSessionInput } from '../../mutations'

interface CreateSessionReviewProps {
    createRecurrenceInput?: CreateRecurrenceInput
    createSessionInput: CreateSessionInput
    createSession: () => void
    goBack: () => void
}

const CreateSessionReview: FC<CreateSessionReviewProps> = ({
    createRecurrenceInput,
    createSessionInput,
    createSession,
    goBack,
}) => {
    const [creatingSession, setCreatingSession] = useState<boolean>(false)

    const renderSchedule = () => {
        return (
            <>
                <Grid item xs={12}>
                    <Typography variant='h6'>Schedule</Typography>
                </Grid>
                <Grid item xs={6}>
                    <MuiDatePicker
                        label='Start Date'
                        value={moment(
                            createRecurrenceInput.start_date
                        ).toDate()}
                    />
                </Grid>
                <Grid item xs={6}>
                    <MuiDatePicker
                        label='End Date'
                        value={moment(createRecurrenceInput.end_date).toDate()}
                    />
                </Grid>
                <Grid item xs={12}>
                    {createRecurrenceInput.models.map((recc, ind) => {
                        return (
                            <ListItem key={ind}>
                                <ListItemIcon>
                                    <RepeatRounded />
                                </ListItemIcon>
                                <ListItemText>
                                    {renderRecurrenceItemText(recc)}
                                </ListItemText>
                            </ListItem>
                        )
                    })}
                </Grid>
            </>
        )
    }
    return (
        <Grid container spacing={2}>
            {/* <Typography variant='h6'>Location</Typography> */}
            <Grid item xs={12}>
                <TextField
                    id='outlined-error-helper-text'
                    fullWidth
                    value={`${moment(createSessionInput.session_date).format(
                        'dddd Do MMMM YYYY'
                    )} @ ${createSessionInput.location_name}`}
                    disabled
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id='outlined-error-helper-text'
                    label='Staff Type'
                    fullWidth
                    value={createSessionInput.staff_type}
                    disabled
                />
            </Grid>
            <Grid item xs={6}>
                <MuiTimePicker
                    label='Start Time'
                    value={createSessionInput.start_time}
                />
            </Grid>
            <Grid item xs={6}>
                <MuiTimePicker
                    label='End Time'
                    value={createSessionInput.end_time}
                />
            </Grid>
            {createRecurrenceInput && renderSchedule()}
            <Grid item xs={6} marginTop={2}>
                <Button
                    fullWidth
                    variant='outlined'
                    onClick={() => goBack()}
                    disabled={creatingSession}>
                    Go Back
                </Button>
            </Grid>
            <Grid item xs={6} marginTop={2}>
                <LoadingButton
                    fullWidth
                    variant='contained'
                    onClick={() => {
                        setCreatingSession(true)
                        createSession()
                    }}
                    loading={creatingSession}>
                    Create Session
                </LoadingButton>
            </Grid>
        </Grid>
    )
}

export default CreateSessionReview
