import React, { createContext } from "react";
import { StaffModel } from "../Models";

export interface MeProviderProps {
    me?: StaffModel
    setMe?: any
  }
  
  export interface MeContextResponseInterface { me: StaffModel, setMe: any }
  export const MeContextResponse = {me: null, setMe: (newMe) => {}}
  
  const MeContext = createContext(MeContextResponse)
  
  export const MeProvider: React.FC<MeProviderProps> = ({
    children,
    me,
    setMe
  }) => {
  
      return <MeContext.Provider value={{me: me, setMe}}>
        {children}
        </MeContext.Provider>
  };
  
  export function useMe(): MeContextResponseInterface {
    return React.useContext(MeContext);
  }
  
  export default useMe;