import { SessionModel } from '../Models'

enum SessionDescription {
    FACE_TO_FACE_CONSULTATION = 'FACE-TO-FACE:CONSULTATION',
    FACE_TO_FACE_RESULTS = 'FACE-TO-FACE:RESULTS',
    FACE_TO_FACE_HOME_VISIT = 'FACE-TO-FACE:HOME-VISIT',
    DIGITAL_CONSULTATION = 'DIGITAL:CONSULTATION',
    DIGITAL_RESULTS = 'DIGITAL:RESULTS',
}

const sessionDescriptionText = (session_description: SessionDescription) => {
    switch (session_description) {
        case SessionDescription.FACE_TO_FACE_CONSULTATION:
            return 'Face to face consultation'
        case SessionDescription.FACE_TO_FACE_RESULTS:
            return 'Face to face results'
        case SessionDescription.FACE_TO_FACE_HOME_VISIT:
            return 'Face to face home visit'
        case SessionDescription.DIGITAL_CONSULTATION:
            return 'Telephone consultation'
        case SessionDescription.DIGITAL_RESULTS:
            return 'Telephone results'
    }
}

const AllSessionDescriptions = [
    SessionDescription.FACE_TO_FACE_CONSULTATION,
    SessionDescription.FACE_TO_FACE_HOME_VISIT,
    SessionDescription.FACE_TO_FACE_RESULTS,
    SessionDescription.DIGITAL_CONSULTATION,
    SessionDescription.DIGITAL_RESULTS,
]

const isRecurringSession = (model: SessionModel): boolean => {
    return (
        model.isRecurringSession === true ||
        model.RECURRENCE_ID != null ||
        model.id.includes('RECURRENCE')
    )
}

export {
    AllSessionDescriptions,
    SessionDescription,
    isRecurringSession,
    sessionDescriptionText,
}
