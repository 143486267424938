import React, { FunctionComponent, useContext, useEffect, useState } from 'react'

import './styles.css'
import StaffCell from '../../components/staff-cell';
import { gql } from '@apollo/client';
import client from '../../libary/apollo';
import { StaffModel } from '../../libary/Models';
import { useAuth0 } from '@auth0/auth0-react';
import loadingSpinner from '../../components/loadingSpinner';
import ReactSwitch from 'react-switch';
import { ThemeContext } from '../../App';

interface StaffListProps {
    select?: Function
}

const query = gql`
query {
    staff{
      id
      name_value
      staff_type
      image
    }
  }
  `

const StaffList: FunctionComponent<StaffListProps> = (props: StaffListProps) => {
    const theme = useContext(ThemeContext)
    const [staff, setStaff] = useState<StaffModel[] | null>(null)

    const [isGP, setIsGP] = useState<boolean>(true)
    const [isANP, setIsANP] = useState<boolean>(true)
    const [isMSK, setIsMSK] = useState<boolean>(true)
    const [isMHP, setIsMHP] = useState<boolean>(true)
    const [isNurse, setIsNurse] = useState<boolean>(true)
    const [isHCA, setIsHCA] = useState<boolean>(true)
    const [isPA, setIsPA] = useState<boolean>(true)
    const [isParamedic, setIsParamedic] = useState<boolean>(true)
    const [isReceptionist, setIsReceptionist] = useState<boolean>(true)


    const [searchTerm, setSearchTerm] = useState<string>("")
    const { getAccessTokenSilently } = useAuth0()

    useEffect(() => {
        loadStaff()
    })

    const loadStaff = () => {
        (async () => {
            const token = await getAccessTokenSilently();
            client.query({
                query,
                context: {
                    headers: {
                        authorization: token
                    }
                }
            }).then((response) => {
                setStaff(response.data.staff)
            })
        })()
    }

    const showStaffModal = (staffMember) => {
        props.select(staffMember)
    }

    const filterByStaffType = (staffModel) => {
        var types = []
        if (isGP) { types.push("GP") }
        if (isANP) { types.push("ANP") }
        if (isMSK) { types.push("MSK") }
        if (isMHP) { types.push("MHP") }
        if (isNurse) { types.push("NURSE") }
        if (isHCA) { types.push("HCA") }
        if (isPA) { types.push("PA") }
        if (isParamedic) { types.push("PARAMEDIC") }
        if (isReceptionist) { types.push("RECEPTIONIST") }
        return types.includes(staffModel.staff_type)
    }

    const renderStaff = () => {
        return staff
            .filter(filterByStaffType)
            .filter((staffModel) => staffModel.name_value != null)
            .filter((staffModel) => staffModel.name_value.toLowerCase().includes(searchTerm.toLowerCase())).map((staffModel) => {
                return <StaffCell staff={staffModel} onClick={(staff) => showStaffModal(staffModel)} key={staffModel.id} />
            })
    }

    if (staff !== null) {
        return (
            <div className="staff-list-container">
                <input className="search-bar" name='searchInput' placeholder='Search for staff:' onChange={(event) => { setSearchTerm(event.target.value) }} />
                <div className="staff-list-filter-type">
                    <h3>Staff Type:</h3>
                    <div className="staff-list-filter-type-toggles">

                        <div className="staff-list-filter-toggle">
                            <div className="staff-list-filter-label">GP</div>
                            <ReactSwitch onColor={theme.colors.brand} onChange={() => setIsGP(!isGP)} checked={isGP} />
                        </div>
                        <div className="staff-list-filter-toggle">
                            <div className="staff-list-filter-label">ANP</div>
                            <ReactSwitch onColor={theme.colors.brand} onChange={() => setIsANP(!isANP)} checked={isANP} />
                        </div>
                        <div className="staff-list-filter-toggle">
                            <div className="staff-list-filter-label">MSK</div>
                            <ReactSwitch onColor={theme.colors.brand} onChange={() => setIsMSK(!isMSK)} checked={isMSK} />
                        </div>
                        <div className="staff-list-filter-toggle">
                            <div className="staff-list-filter-label">MHP</div>
                            <ReactSwitch onColor={theme.colors.brand} onChange={() => setIsMHP(!isMHP)} checked={isMHP} />
                        </div>
                        <div className="staff-list-filter-toggle">
                            <div className="staff-list-filter-label">Nurse</div>
                            <ReactSwitch onColor={theme.colors.brand} onChange={() => setIsNurse(!isNurse)} checked={isNurse} />
                        </div>
                        <div className="staff-list-filter-toggle">
                            <div className="staff-list-filter-label">HCA</div>
                            <ReactSwitch onColor={theme.colors.brand} onChange={() => setIsHCA(!isHCA)} checked={isHCA} />
                        </div>
                        <div className="staff-list-filter-toggle">
                            <div className="staff-list-filter-label">PA</div>
                            <ReactSwitch onColor={theme.colors.brand} onChange={() => setIsPA(!isPA)} checked={isPA} />
                        </div>
                        <div className="staff-list-filter-toggle">
                            <div className="staff-list-filter-label">Paramedic</div>
                            <ReactSwitch onColor={theme.colors.brand} onChange={() => setIsParamedic(!isParamedic)} checked={isParamedic} />
                        </div>
                        <div className="staff-list-filter-toggle">
                            <div className="staff-list-filter-label">Receptionist</div>
                            <ReactSwitch onColor={theme.colors.brand} onChange={() => setIsReceptionist(!isReceptionist)} checked={isReceptionist} />
                        </div>
                    </div>
                </div>
                <div className="staff-list-list">
                    {renderStaff()}
                </div>
            </div>
        )
    } else {
        return loadingSpinner({})
    }
}

export default StaffList