import { useAuth0 } from '@auth0/auth0-react';
import React, { FunctionComponent, useState } from 'react';
import { withCookies } from 'react-cookie';
import LoadingSpinner from '../../components/loadingSpinner';
import Auth from '../../libary/auth';
import { Features, StaffModel } from '../../libary/Models';
import { NavigatorProps } from './types';
import Login from '../Login';

const Navigator: FunctionComponent<NavigatorProps> = (props: NavigatorProps) => {
  const {getAccessTokenSilently, user, isLoading, isAuthenticated} = useAuth0()
  const [ features, setFeatures ] = useState<Features|null>(null)
  const [ localUser, setLocalUser ] = useState<StaffModel|null>(null)

  const loadUser = async () => {
    (async () => {
      const token = await getAccessTokenSilently()
      Auth.authenticateUser(token, user.email).then(async (response: any) => {
        setLocalUser(response.me)
        setFeatures(response.features)
      })
    })()
  }

  if (isLoading  === true) {
    return <LoadingSpinner />
  } else if (isAuthenticated  === true) {
    if (features !== null) {

      
        switch (localUser.account_state) {
          case "ACCOUNT_TYPE":
            if(window.location.hostname.includes("fedworks")) {
              window.location.replace('/onboarding-account-type')
            } else if(window.location.hostname.includes("gmworkforce")) {
              window.location.replace('/onboarding-welcome')
            } else {
              window.location.replace('/onboarding-welcome')
            }
            break
          case "ACCOUNT_INFO":
            window.location.replace('/editaccount')
            break
          case "DISCLAIMER": 
            window.location.replace(localUser.staff_type === "CREATOR" ? "/onboarding-disclaimer-creator" : "/onboarding-disclaimer-staff")
            break
          case "COMPLETE":
            if (features.manageRota === true) {
              window.location.replace('/rota')
            } else if(features.findSessions === true) {
              window.location.replace('/staffdashboard')
            } else {
              if(localUser.staff_type === "CREATOR") {
                window.location.replace('/permissions-create-home')
              } else {
                window.location.replace('/permissions-apply-home')
              }
            }
            break
        }
    } else { loadUser() }
    return <div style={{display: 'flex', width: '100vw', height: '100vh', justifyContent: 'center', alignItems: 'center'}}>
      <LoadingSpinner/>
    </div>
  } else {
    return <Login/>
  }
}

const cookieVersion = withCookies(Navigator)

export default cookieVersion