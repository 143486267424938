import { gql } from "@apollo/client"

const getDocUrlQuery = gql`
    query($staffId: String!, $documentId: String!) {
        getSignedUrl(staffId: $staffId, documentId: $documentId) {
        error
        url
        }
    }
`

export {
    getDocUrlQuery
}