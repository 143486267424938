import moment from "moment";
import { FunctionComponent } from "react";
import { Notification, NotificationType } from "../../../libary/Models";
import './styles.css'

interface NotificationItemProps {
    notification: Notification
}

const NotificationItem: FunctionComponent<NotificationItemProps> = (props: NotificationItemProps) => {
    const renderNotificationText = () => {
        switch (props.notification.type) {
            case NotificationType.SESSION_ASSIGNED: 
                return `You have been assigned to a session at ${props.notification.data['location_name']} on ${moment(props.notification.data['session_date']).format("DD-MM-yyyy")}`
            case NotificationType.STAFF_WITHDRAWAL:
                const date = moment(props.notification.data['session_date'])
                return `A session on ${date.format("dddd do MMMM")} has become available`
        }
    }
    return (
        <div className='notification-item-container'>
            <div className='notification-item-read-icon-container'>
                {props.notification.read === false && <div className='notification-item-read-icon'/>}
            </div>
            <div className='notification-item-text'>
                {renderNotificationText()}
            </div>
        </div>
    )
}

export default NotificationItem