/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { splitFormProps, useField } from 'react-form'

const TextArea = React.forwardRef((props: any, ref) => {
    // Let's use splitFormProps to get form-specific props
    const [field, fieldOptions, rest] = splitFormProps(props);

    // Use the useField hook with a field and field options
    // to access field state
    const {
        meta: { error, isTouched, isValidating },
        getInputProps
    } = useField(field, fieldOptions);


    // Build the field
    return (
        <textarea className='editAccountBioInput' {...getInputProps({ ref, ...rest })}></textarea>
    )

});

export default TextArea