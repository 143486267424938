import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history';
import { createStore, applyMiddleware, compose } from 'redux';
import createRootReducer from './reducers/reducers';

export const history = createBrowserHistory();

const middleware = routerMiddleware(history);
const rootReducer = createRootReducer(history)
export default createStore(
    rootReducer,
    compose(applyMiddleware(middleware))
)