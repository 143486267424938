import React, { Component } from 'react'
import Rota from '../pages/rota'
import styled from 'styled-components'
import { SelectedTab } from '../libary/Models'
import { connect } from 'react-redux'
import store from '../libary/redux/store';
import { setActiveTab } from '../libary/redux/actions/siteStateActions';
import DocumentApproval from '../pages/DocumentApproval';

import { withCookies } from 'react-cookie';
import Admin from '../pages/StaffHubs';
import Account from '../pages/Account';

  export const Cont = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: absolute;
`

interface ContainerProps {
    tab: SelectedTab,
    cookies
}

class DashboardContainer extends Component<ContainerProps, {loggedIn: boolean, register: boolean}> {

    constructor(props: ContainerProps) {
        super(props)
        const { cookies } = props;
        const loggedIn = cookies.get('token') ? true : false
        
        this.state = {
            loggedIn: loggedIn,
            register: false
        }
        this._setTab = this._setTab.bind(this)
        this.goToRegister = this.goToRegister.bind(this)
        // Queries.getPractices(this.props.cookies.get('token'))
        window.history.pushState({}, window.location.pathname, window.location.pathname)
        // Queries.getDocuments()
        // Queries.getQualifications(this.props.cookies.get('token'))
        // Queries.getItSystems(this.props.cookies.get('token'))
        if(this.props.cookies.get('fed_staff') !== "ADMIN") { store.dispatch(setActiveTab(SelectedTab.MyDiary)) }
    }

    goToRegister() {
        this.setState({register: true})
    }

    _setTab(tab: SelectedTab) {
        store.dispatch(setActiveTab(tab))
    }
    
    render() {
        // if(!this.state.loggedIn) {
        //     window.location.replace('/')
        // }
        var page;
            switch(this.props.tab) {
                case SelectedTab.Rota:
                    page = <Rota/>
                    break
                case SelectedTab.Admin:
                    page = <Admin/>
                    break
                case SelectedTab.DocumentApproval:
                    page = <DocumentApproval/>
                    break
                case SelectedTab.Account:
                    page = <Account/>
                    break
                // case SelectedTab.FindASession:
                //     page = <FindASession/>
                //     break
                // case SelectedTab.MyDiary:
                //     page = <StaffDashboard/>
                //     break
            }

            return (
                <Cont>
                    <div style={{position: 'relative', top: '50px', paddingLeft: '5%', paddingRight: '5%'}}>
                        {page}
                    </div>
                </Cont>
            )
    }
}
const connected = connect((state: {siteStateReducer: {tab: SelectedTab}}) => {
    return {
        tab: state.siteStateReducer.tab
    };
  })(DashboardContainer);
  // @ts-ignore: 
  const cookieVersion = withCookies(connected)

export default cookieVersion