import { FunctionComponent } from "react"
import { PermissionObject } from "../../../libary/Models"
import "./styles.css"

import RejectImage from '../../../assets/icons/ic_cancel_circle.svg'
import ApproveImage from '../../../assets/icons/ic_check_circle.svg'

interface StaffApprovalCellProps {
    permissionRequest: PermissionObject
    viewStaff: Function
    approve: Function
    reject: Function
    key: string
}
const PermissionRequestCell: FunctionComponent<StaffApprovalCellProps> = (props: StaffApprovalCellProps) => {
    return (
        <div className="permission-request-cell-container">
            <div className="permission-request-vertical-label">
                <div className="permission-request-cell-label">Staff Name:</div>
                <div className="permission-request-cell-text" onClick={() => props.viewStaff()}>{props.permissionRequest.user_name}</div>
            </div>
            <div className="permission-request-vertical-label">
                <div className="permission-request-cell-label">Permission</div>
                <div className="permission-request-cell-text">{props.permissionRequest.permission}</div>
            </div>
            <div className="permission-request-vertical-label">
                <div className="permission-request-cell-label">For Object</div>
                <div className="permission-request-cell-text">{props.permissionRequest.object_name}</div>
            </div>
            <div className="permission-request-cell-button-container" >
                <img className="permission-request-cell-button-reject" onClick={() => { props.reject() }} src={RejectImage} />
                <img className="permission-request-cell-button-approve"  onClick={() => props.approve()} src={ApproveImage} />
            </div>
        </div>
    )
}

export default PermissionRequestCell