import gql from 'graphql-tag'
import { Result } from 'true-myth'
import { SessionModel } from '../../../libary/Models'
import query from '../../../libary/network/apollo'

const sessionQuery = gql`
    query session($id: String) {
        session(id: $id) {
            id
            name_value
            session_status
            session_date
            start_time
            end_time
            break
            pricePerHour
            staff_type
            staff {
                id
                name_value
                staff_type
                __typename
            }
            location {
                id
                name_value
            }
            locality {
                id
                name_value
            }

            applicants {
                id
                name_value
                image
                __typename
            }
            it_system {
                id
                name_value
            }
            has_applied
            is_assigned
            payment_process
            payment_process_info
            length_of_appointments
            __typename
            RECURRENCE_ID
        }
    }
`

const getSession = async (
    id: string,
    token: string
): Promise<Result<SessionModel, Error>> => {
    const result = await query(sessionQuery, token, 'session', { id })
    console.log('RESULT: ', result)
    if (result.isOk) {
        return Result.ok(result.value)
    } else if (result.isErr) {
        return Result.err(result.error)
    } else {
        return Result.err(Error('Unknown error'))
    }
}

export { getSession }
