import React, { FunctionComponent, useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";

interface DragDropUploadProps {
    setFile: Function
}

const DragDropUpload: FunctionComponent<DragDropUploadProps> = (props: DragDropUploadProps) => {
    const fileTypes = ["PNG", "PDF", "JPG"]

    const { setFile } = props
    const [localFile, setLocalFile] = useState(null);

    useEffect(() => {
        setFile(localFile)
    }, [localFile])

    const handleChange = (file) => {
        setLocalFile(file);
    }

    return (
        <FileUploader handleChange={handleChange} name="file" types={fileTypes} />
    )
}

export default DragDropUpload;