import { gql } from "@apollo/client";

const userCountByTypeQuery = gql`
    query userCountByType($staff_type: String) {
        userCountByType(staff_type: $staff_type) {
            count
        }
    }
`

const userCountBreakdownQuery = gql`
    query userCountBreakdown {
        userCountBreakdown {
            all {
                approved
                total
            }
            GP {
                approved
                total
            }
            CREATOR {
                approved
                total
            }
            OTHER {
                approved
                total
            }
        }
    }
`

const sessionsAnalyticsQuery = gql`
    query sessionsAnalytics($month: String, $year: String) {
        sessionsAnalytics(month: $month, year: $year) {
            sessionsRequestedCount
            filledSessionsCount
            unfilledSessionsCount
            fillRatio
        }
    }
`

export { 
    userCountByTypeQuery,
    userCountBreakdownQuery,
    sessionsAnalyticsQuery
}