import { gql } from "@apollo/client";

const getPermissionRequestsQuery = gql`
query {
    getPermissionRequests {
      user_id
      object_id
      permission
      object_name
      user_name
    }
  }
`

export {
    getPermissionRequestsQuery
}