import { gql } from "@apollo/client";

const setStaffTypeCreatorMutation = gql`
mutation updateAccountType($accountType: String) {
    updateAccountType(account_type: $accountType) {
      error
      success
    }
  }
`

export { setStaffTypeCreatorMutation }