import { FunctionComponent, useContext } from "react";
import { ThemeContext } from "../../App";
import ColoredButton, { ColoredButtonEmphasis } from "../../components/colored-button";
import Footer from "../../components/Footer";
import NavBarNew from "../../components/NavBarNew";
import AuthContainer from "../../container/AuthContainer";
import './styles.css'

const Welcome: FunctionComponent = () => {
    const theme = useContext(ThemeContext)

    const goToDisclaimer = () => {
        window.location.replace('/onboarding-account-type')
    }

    return (
        <AuthContainer>
            <div className="welcome-page-container">
                <NavBarNew/>
                    <div className="mainBody parallax" style={{backgroundColor: theme.colors.parallaxBackground}}>
                        <div className="welcome-page-body">
                            <div className="welcome-title center">Welcome to the GM Workforce Bank</div>
                            <div className="welcome-body-text center">The GM Workforce Bank is a new rota system that has been designed specifically for Primary Care employers across Greater Manchester.</div>
                            <div className="welcome-body-text center">Our aim is to continue to grow the system design and function along with our users by forming a task and finish group, If you wish to be part of this group please email us at <a href="mailto:gmworkforce.bank@nhs.net">gmworkforce.bank@nhs.net</a></div>
                            <br/>
                            <div className="welcome-body-text">Employers you will need to complete a disclaimer prior to registration on the next page.</div>
                            <div className="welcome-body-text">Staff will need to:</div>
                            <ul>
                                <li style={{listStyleType: "none"}}>• Complete the GM Workforce passport to enable us to approve your registration.</li>
                                <li style={{listStyleType: "none"}}>• Complete the disclaimer prior to registration on the next page.</li>
                            </ul>
                            <br/>
                            <div className="welcome-body-text">If you have any problems out team are happy to help and will respond within 24 working hours at the latest</div>
                            <a href="mailto:gmworkforce.bank@nhs.net">gmworkforce.bank@nhs.net</a>
                            <div className="welcome-body-text">Telephone: <span style={{color: theme.colors.brand}}>01204 546124</span></div>
                            <div className="welcome-header-text">Feedback</div>
                            <div className="welcome-body-text">Your feedback is very important to us, we will send you a feedback link after sessions have been completed, please take the time to complete these.</div>
                            <div className="welcome-body-text">If you wish to make a complaint please do so in writing to <a href="mailto:gmworkforce.bank@nhs.net">gmworkforce.bank@nhs.net</a></div>
                            <div className="welcome-button">
                            <ColoredButton text="Continue" onClick={() => {goToDisclaimer()}} backgroundColor={theme.colors.brand} color={theme.colors.lightText} emphasis={ColoredButtonEmphasis.HIGH}/>
                            </div>
                        </div>
                    </div>
                <Footer/>
            </div>
        </AuthContainer>
    )
}

export default Welcome