import { Button, Grid, TextField } from '@mui/material'
import { FC, useState } from 'react'
import { PaymentProcess } from '../../../../../libary/Models'
import {
    SessionDescription,
    sessionDescriptionText,
} from '../../../../../libary/models/SessionModel'
import MuiDropdown from '../../../../MuiWrappers/MuiDropdown/MuiDropdown'
import MuiTimePicker from '../../../../MuiWrappers/MuiTimePicker/MuiTimePicker'
import { CreateSessionInput } from '../../mutations'
import { SessionConfig } from '../../queries'

interface SessionDetails {
    name_value: string
    session_description: string
    length_of_appointments: string
    start_time: number
    end_time: number
    break: number
    pricePerHour: number
    payment_process: PaymentProcess
    payment_process_info: string
}

interface CreateSessionDetailsProps {
    staff_type: string
    location_name: string
    sessionConfig: SessionConfig
    createSessionInput: CreateSessionInput
    sessionDetailsUpdated: (info: SessionDetails) => void
    goBack: () => void
    token: string
}

interface CreateSessionDetailsErrors {
    name_value: string
    session_description: string
    length_of_appointments: string
    start_time: string
    end_time: string
    break: string
    pricePerHour: string
    payment_process: string
    payment_process_info: string
}

const AllSessionDescriptions = [
    { id: '1', name_value: SessionDescription.FACE_TO_FACE_CONSULTATION },
    { id: '2', name_value: SessionDescription.FACE_TO_FACE_HOME_VISIT },
    { id: '3', name_value: SessionDescription.FACE_TO_FACE_RESULTS },
    { id: '4', name_value: SessionDescription.DIGITAL_CONSULTATION },
    { id: '5', name_value: SessionDescription.DIGITAL_RESULTS },
]
const AllPaymentProcess = [
    {
        id: '1',
        name_value: 'Payments made within 4 week of receipt of invoice',
        process: PaymentProcess.FOUR_WEEKS,
    },
    {
        id: '2',
        name_value: 'Payments made on the last working day of the month',
        process: PaymentProcess.LAST_WORKING_DAY,
    },
    {
        id: '3',
        name_value: 'Other',
        process: PaymentProcess.OTHER,
        text: '',
    },
]

const CreateSessionDetails: FC<CreateSessionDetailsProps> = (
    props: CreateSessionDetailsProps
) => {
    const [sessionDetails, setSessionDetails] = useState<SessionDetails>({
        name_value: `${props.staff_type} @ ${props.location_name}`,
        session_description: props.createSessionInput.session_description,
        length_of_appointments: props.createSessionInput.length_of_appointments,
        start_time: props.createSessionInput.start_time,
        end_time: props.createSessionInput.end_time,
        break: props.createSessionInput.break,
        payment_process: AllPaymentProcess.filter(
            (item) => item.process === props.createSessionInput.payment_process
        )[0].process,
        payment_process_info: props.createSessionInput.payment_process_info,
        pricePerHour: props.createSessionInput.pricePerHour,
    })
    const [errors, setErrors] = useState<CreateSessionDetailsErrors>({
        name_value: '',
        session_description: '',
        length_of_appointments: '',
        start_time: '',
        end_time: '',
        break: '',
        pricePerHour: '',
        payment_process: '',
        payment_process_info: '',
    })
    const handleSessionDetailsUpdated = (key, value) => {
        setSessionDetails({
            ...sessionDetails,
            [key]: value,
        })
    }

    const validateInputs = () => {
        const newErrors = { ...errors }
        let valid = true
        if (sessionDetails.session_description === '') {
            newErrors.session_description =
                'Please select a session description'
            valid = false
        }
        if (
            props.sessionConfig.hiddenFields.lengthOfAppointments === false &&
            sessionDetails.length_of_appointments === '-1'
        ) {
            newErrors.length_of_appointments = 'Please enter appointment length'
            valid = false
        }
        if (sessionDetails.break === -1) {
            newErrors.break = 'Please enter break length'
            valid = false
        }
        if (sessionDetails.pricePerHour === -1) {
            newErrors.pricePerHour = 'Please enter Price Per Hour'
            valid = false
        }
        setErrors(newErrors)
        return valid
    }
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                    id='outlined-error-helper-text'
                    label='Session Name'
                    fullWidth
                    defaultValue={props.createSessionInput.name_value}
                    error={errors.name_value !== ''}
                    helperText={errors.name_value}
                    onChange={(e) =>
                        handleSessionDetailsUpdated(
                            'name_value',
                            e.target.value
                        )
                    }
                />
            </Grid>
            <Grid item xs={12}>
                <MuiDropdown
                    required={true}
                    label='Session Description'
                    error={errors.session_description}
                    defaultValue={
                        sessionDetails.session_description === ''
                            ? ''
                            : AllSessionDescriptions.filter(
                                  (item) =>
                                      item.name_value ===
                                      sessionDetails.session_description
                              )[0].id
                    }
                    placeholder='Select a session description'
                    items={AllSessionDescriptions}
                    renderText={(item: any) => sessionDescriptionText(item)}
                    onChange={(value) => {
                        handleSessionDetailsUpdated(
                            'session_description',
                            AllSessionDescriptions.filter(
                                (item) => item.id === value
                            )[0].name_value
                        )
                    }}
                />
            </Grid>
            <Grid
                item
                xs={12}
                hidden={
                    props.sessionConfig.hiddenFields.lengthOfAppointments ===
                    true
                }>
                <TextField
                    id='outlined-error-helper-text'
                    label='Length Of Appoinments'
                    fullWidth
                    type='number'
                    defaultValue={
                        sessionDetails.length_of_appointments !== '-1'
                            ? sessionDetails.length_of_appointments
                            : ''
                    }
                    // value={
                    //     sessionDetails.length_of_appointments !== -1
                    //         ? sessionDetails.length_of_appointments
                    //         : ''
                    // }
                    error={errors.length_of_appointments !== ''}
                    helperText={errors.length_of_appointments}
                    onChange={(e) =>
                        handleSessionDetailsUpdated(
                            'length_of_appointments',
                            parseInt(e.target.value)
                        )
                    }
                />
            </Grid>
            <Grid item xs={6}>
                <MuiTimePicker
                    label='Start Time'
                    value={sessionDetails.start_time}
                    onChange={(time) => {
                        handleSessionDetailsUpdated('start_time', time)
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <MuiTimePicker
                    label='End Time'
                    value={sessionDetails.end_time}
                    onChange={(time) =>
                        handleSessionDetailsUpdated('end_time', time)
                    }
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    id='outlined-error-helper-text'
                    label='Break'
                    fullWidth
                    type='number'
                    defaultValue={sessionDetails.break}
                    // value={sessionDetails.break}
                    error={errors.break !== ''}
                    helperText={errors.break}
                    onChange={(e) =>
                        handleSessionDetailsUpdated(
                            'break',
                            parseInt(e.target.value)
                        )
                    }
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    id='outlined-error-helper-text'
                    label='Price Per Hour'
                    required
                    fullWidth
                    type='number'
                    defaultValue={
                        sessionDetails.pricePerHour !== -1
                            ? sessionDetails.pricePerHour
                            : ''
                    }
                    // value={
                    //     sessionDetails.price_per_hour !== -1
                    //         ? sessionDetails.price_per_hour
                    //         : ''
                    // }
                    error={errors.pricePerHour !== ''}
                    helperText={errors.pricePerHour}
                    onChange={(e) =>
                        handleSessionDetailsUpdated(
                            'pricePerHour',
                            parseInt(e.target.value)
                        )
                    }
                />
            </Grid>
            <Grid
                item
                xs={12}
                hidden={
                    props.sessionConfig.hiddenFields.paymentProcess === true
                }>
                <MuiDropdown
                    label='Payment Process'
                    error={errors.payment_process}
                    defaultValue={
                        AllPaymentProcess.filter(
                            (item) =>
                                item.process === sessionDetails.payment_process
                        )[0].id
                    }
                    items={AllPaymentProcess}
                    onChange={(value) => {
                        handleSessionDetailsUpdated(
                            'payment_process',
                            AllPaymentProcess.filter(
                                (item) => item.id === value
                            )[0].process
                        )
                    }}
                />
            </Grid>
            {sessionDetails.payment_process === PaymentProcess.OTHER && (
                <Grid item xs={12}>
                    <TextField
                        id='outlined-error-helper-text'
                        label='Payment Process Information'
                        fullWidth
                        defaultValue={sessionDetails.payment_process_info}
                        error={errors.payment_process_info !== ''}
                        helperText={errors.payment_process_info}
                        onChange={(e) =>
                            handleSessionDetailsUpdated(
                                'payment_process_info',
                                e.target.value
                            )
                        }
                    />
                </Grid>
            )}
            <Grid item xs={6} marginTop={2}>
                <Button
                    fullWidth
                    variant='outlined'
                    onClick={() => props.goBack()}>
                    Back
                </Button>
            </Grid>
            <Grid item xs={6} marginTop={2}>
                <Button
                    fullWidth
                    variant='contained'
                    onClick={() => {
                        if (validateInputs() === true) {
                            props.sessionDetailsUpdated(sessionDetails)
                        }
                    }}>
                    Continue
                </Button>
            </Grid>
        </Grid>
    )
}

export default CreateSessionDetails
