import { FunctionComponent } from "react"
import { DocumentModel } from "../../../libary/Models"
import "./styles.css"

import RejectImage from '../../../assets/icons/ic_cancel_circle.svg'
import ApproveImage from '../../../assets/icons/ic_check_circle.svg'

interface DocumentCellProps {
    doc: DocumentModel
    viewDocument: Function
    approve: Function
    reject: Function
    key: string
}
const DocumentApprovalCell: FunctionComponent<DocumentCellProps> = (props: DocumentCellProps) => {
    return (
        <div className="document-approval-cell-container">
            <div className="document-approval-cell-label">Staff Name:</div>
            <div className="document-approval-cell-text">{props.doc.staff_name}</div>
            <div className="document-approval-cell-label">Document Name:</div>
            <div className="document-approval-cell-text">{props.doc.name_value}</div>
            <div className="document-approval-cell-button-view" onClick={() => props.viewDocument()}>View</div>

            <div className="document-approval-cell-button-container">
                <img className="document-approval-cell-button-reject" onClick={() => {props.reject()}} src={RejectImage}/>
                <img className="document-approval-cell-button-approve" onClick={() => {props.approve()}} src={ApproveImage}/>
            </div>
        </div>
    )
}

export default DocumentApprovalCell