import React, { Component } from 'react'

import './styles.css'

import profileImage from '../../assets/placeholder_profile.png'
import { StaffModel } from '../../libary/Models';

interface StaffCellProps {
    staff?: StaffModel
    onClick?: Function
    count?: string
    staffName?: string
    staffImage?: string
    assignButtonCallback?: Function
}

interface StaffCellState {

}

class StaffCell extends Component<StaffCellProps, StaffCellState> {
    render() {
        if(this.props.staff !== undefined) {
            return (
                <div className='cellContainer'>
                    <div onClick={this.props.onClick ? () => {this.props.onClick(this.props.staff)} : () => {}}>
                        <img src={this.props.staff.image ? this.props.staff.image : profileImage} className="staffImage" alt="Staff pic"/>
                        <div className="staffName">{this.props.staff.name_value ? this.props.staff.name_value : ""}</div>
                    </div>
                    <div className='staff-cell-button-container'>
                        {this.props.assignButtonCallback !== undefined && <div className='staff-cell-button-assign' onClick={() => {this.props.assignButtonCallback()}}>Assign</div>}
                    </div>
                </div>
            )
        } else {
            return (
                <div className='cellContainer' onClick={this.props.onClick ? () => {this.props.onClick(this.props.staff)} : () => {}}>
                    <div className="staffImage">
                    <img src={this.props.staffImage} style={{width: '100%', height: '100%'}} alt="Staff pic"/>
                    </div>
                    <div className={Number.parseInt(this.props.count) > 0 ? "notificationDot" : "gone"}>
                            {this.props.count}
                    </div>
                    <div className="staffName">{ this.props.staffName ? this.props.staffName : ""}</div>
                </div>
            )
        }
    }
}

export default StaffCell