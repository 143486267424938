import { SessionDescription } from './models/SessionModel'

export enum PaymentProcess {
    FOUR_WEEKS = '4_WEEKS',
    LAST_WORKING_DAY = 'LAST_WORKING_DAY',
    OTHER = 'OTHER',
}

export interface DayModel {
    sessions: SessionModel[]
}

export interface ApplicationModel {
    id: string
    name_value: string
    item_type: string
    timestamp: number
    session_date: string
    session_start_time: number
    session_end_time: number
    session_location_name: string
}

export interface SessionModel {
    id?: string
    name_value?: string
    session_status?: string
    session_date: string
    start_time: number
    end_time: number
    pricePerHour: number
    break?: number
    paid?: boolean
    has_applied?: boolean
    system_id?: number
    LOCALITY_ID: string
    locality_name: string
    LOCATION_ID: string
    location_name: string
    EMPLOYER_ID: string
    employer_name: string
    location?: LocationModel
    locality?: LocalityModel
    applicants?: StaffModel[]
    staff_id?: string
    staff?: StaffModel
    applicant_ids?: string[]
    another_doctor?: boolean
    nurse?: boolean
    catchup?: boolean
    extra_information?: string
    staff_type: string
    it_system_id: string
    it_system?: ItSystemModel
    is_assigned?: boolean
    session_description: SessionDescription
    travel_info: string
    parking_info: string
    payment_process: string
    payment_process_info?: string
    length_of_appointments?: number
    isRecurringSession?: boolean
    RECURRENCE_ID?: string
}

export interface DocumentModel {
    id: string
    name_value: string
    staff_name: string
    staff_id: string
    approved: boolean
}

export const ParkingOptions = [
    'No info',
    'Paid and near',
    'Free and near',
    'Free and onsite',
    'Parking is difficult',
]

export interface AuthResponse {
    success: boolean
    token: string
    error: string
    id: string
    me: StaffModel
    features: Features
}

export interface Features {
    manageRota: boolean
    viewRota: boolean
    findSessions: boolean
    viewStaffList: boolean
    approveStaff: boolean
    editPermissions: boolean
    viewAdmins: boolean
    requestPermissions: boolean
    canAssignSessions: boolean
    viewAnalytics: boolean
    editStaffTypes: boolean
    newSessionModal: boolean
    manageRecurrences: boolean
    canOverrideAssignSession: boolean
}

export interface PermissionObject {
    user_id: string
    permission: Permission
    object_id: string
    approved: boolean
    user_name?: string
    object_name?: string
}

export enum Permission {
    CREATE_SESSION = 'CREATE-SESSION',
    UPDATE_PRACTICE = 'UPDATE-PRACTICE',
    UPDATE_LOCATION = 'UPDATE-LOCATION',
    UPDATE_EMPLOYER = 'UPDATE-EMPLOYER',
    UPDATE_USER = 'UPDATE-USER',
    SEARCH_USERS = 'SEARCH-USERS',
    EDIT_PERMISSIONS = 'EDIT-PERMISSIONS',
    APPLY_SESSION = 'APPLY-SESSION',
    ASSIGN_TO_SESSION = 'ASSIGN-TO-SESSION',
    APPROVE_APPLICATION = 'APPROVE-APPLICATION',
    APPROVE_USER = 'APPROVE-USER',
    INVITE_USER = 'INVITE-USER',
    VIEW_USER = 'VIEW-USER',
    VIEW_ADMINS = 'VIEW-ADMINS',
}

export interface StaffModel {
    id?: string
    name_value: string
    title: string
    staff_type?: string
    email?: string
    password?: string
    image?: string
    GMC_number?: string
    documents?: DocumentModel[]
    qualifications?: QualificationModel[]
    itSystems?: ItSystemModel[]
    workExperience?: WorkExperienceModel[]
    languages?: LanguagesModel[]
    bio: string
    account_state?: string
    approved: boolean
}

export interface QualificationModel {
    id?: string
    name_value?: string
}

export interface ItSystemModel {
    id?: string
    name_value?: string
}

export interface ItSystemModelNew {
    id: string
    name_value: string
}

export interface WorkExperienceModel {
    id?: string
    name_value?: string
}

export interface LanguagesModel {
    id?: string
    name_value?: string
    short_name: string
}

export interface PracticeModel {
    id?: string
    name_value?: string
    addressLine1?: string
    addressLine2?: string
    addressLine3?: string
    town?: string
    postcode?: string
    practiceCode?: string
    travelInfo?: string
    parking?: string
    phoneNumber?: string
    mobileNumber?: string
    about?: string
}

export interface LocationModel {
    id: string
    locality_id: string
    location_type?: string
    name_value: string
    address_line1: string
    address_line2: string
    address_line3: string
    town: string
    country?: string
    postcode: string
    location_code: string
    travel_info: string
    parking: string
    phone_number: string
    mobile_number: string
    about: string
    can_edit: boolean
    contact_name?: string
    contact_email?: string
    contact_job_title?: string
    contact_telephone?: string
    latitude?: number
    longitude?: number
    website?: string
}

export interface StaffTypeModel {
    id: string
    name_value: string
}

export interface EmployerModel {
    id: string
    name_value: string
    address_line1: string
    address_line2: string
    address_line3: string
    postcode: string
    employer_code: string
    employer_type: string
    contact_name: string
    phone_number: string
    mobile_number: string
    about: string
    can_edit: boolean
    locality_id: string
}

export const EmptyLocation: LocationModel = {
    id: '',
    locality_id: '',
    name_value: '',
    address_line1: '',
    address_line2: '',
    address_line3: '',
    town: '',
    postcode: '',
    location_code: '',
    travel_info: '',
    parking: '',
    phone_number: '',
    mobile_number: '',
    about: '',
    can_edit: true,
}

export const EmptyPractice: PracticeModel = {
    id: '',
    name_value: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    town: '',
    postcode: '',
    practiceCode: '',
    travelInfo: '',
    parking: '',
    phoneNumber: '',
    mobileNumber: '',
    about: '',
}

export enum SelectedTab {
    Rota,
    StaffHubs,
    Admin,
    Account,
    DocumentApproval,
    FindASession,
    MyDiary,
    None,
    StaffApproval,
    PermissionsRequests,
    RequestPermissions,
    Analytics,
    Recurrences,
}

export interface LocalityModel {
    id: string
    name_value: string
    code: string
    about: string
    can_edit: boolean
}

var date = new Date()
date.setHours(date.getHours() + 2)

export const sampleSession: SessionModel = {
    id: '1',
    name_value: '',
    session_date: new Date().toISOString(),
    start_time: 1234356,
    session_status: 'none',
    end_time: 12345,
    break: 10,
    pricePerHour: 90,
    staff_type: 'GP',
    it_system_id: '',
    it_system: {
        id: '',
        name_value: 'Test System',
    },
    locality_name: '',
    LOCALITY_ID: '',
    staff: {
        approved: false,
        id: '1',
        title: 'Mr',
        name_value: 'Kyle Thomas',
        staff_type: 'GP',
        bio: '',
    },
    location: {
        id: '',
        locality_id: '',
        name_value: '',
        address_line1: '',
        address_line2: '',
        address_line3: '',
        town: '',
        postcode: '',
        location_code: '',
        travel_info: '',
        parking: '',
        phone_number: '',
        mobile_number: '',
        about: '',
        can_edit: false,
    },
    applicants: [],
    has_applied: false,
    is_assigned: false,
    LOCATION_ID: '',
    location_name: '',
    EMPLOYER_ID: '',
    employer_name: '',
    session_description: SessionDescription.DIGITAL_CONSULTATION,
    travel_info: '',
    parking_info: '',
    payment_process: PaymentProcess.FOUR_WEEKS,
}

export const sampleDay: DayModel = {
    sessions: [sampleSession, sampleSession],
}

export const GpDocuments = [
    'DBS',
    'Indemnity',
    'Hep B',
    'ID',
    'CV',
    'GMC number',
    'Performers list',
    'Safeguarding adults & children training',
    'Infection control training',
    'Fire safety training',
    'Information Governance training',
]

export const ANPDocuments = [
    'Nurses/HCA/ANP',
    'NMC number (not HCA)',
    'Hep B',
    'ID',
    'DBS',
    'CV',
    'Safeguarding adults & children training',
    'Infection control training',
    'Fire safety training',
    'Information Governance training',
    'CPR training',
    'Prescribing (ANP only)',
]

export const AdminDocuments = [
    'DBS',
    'Hep B',
    'ID',
    'CV',
    'CPR training',
    'Safeguarding adults & children training',
    'Infection control training',
    'Fire safety training',
    'Information Governance training',
]

export const SpokenLanguages = [
    'English',
    'French',
    'Spanish',
    'German',
    'Italian',
    'Arabic',
    'Hebrew',
]

export const ItSystems = [
    'Vision',
    'Frontdesk',
    'Adastra',
    'EMIS LV',
    'EMIS PCS',
    'EMIS web',
    'Docman',
    'SystmOne',
]

export enum NotificationType {
    SESSION_ASSIGNED = 'SESSION_ASSIGNED',
    STAFF_WITHDRAWAL = 'STAFF_WITHDRAWAL',
}

export interface Notification {
    id: string
    type: NotificationType
    read: boolean
    seen: boolean
    timestamp: number
    data: object
}

export const StaffTypes = [
    'GP',
    'ANP',
    'MSK',
    'MHP',
    'NURSE',
    'HCA',
    'PA',
    'PARAMEDIC',
    'RECEPTIONIST',
]

export interface RecurrenceModel {
    id: string
    SK: string
    session_template: SessionModel
    start_date: string
    end_date: string
    models: RecurrenceSubModel[]
    LOCATION_ID: string
    LOCALITY_ID: string
    staff_type: string
}

export enum RecurrenceFrequency {
    EVERY_DAY = 'EVERY_DAY',
    EVERY_WEEK = 'EVERY_WEEK',
    EVERY_MONTH = 'EVERY_MONTH',
}

export interface RecurrenceSubModel {
    frequency: RecurrenceFrequency
    day?: number
    date?: number
}

/* 
EVERY WEEK 
    Same Day

EVERY MONTH
    Same Date or Same Day


*/

interface SessionStatusCounts {
    no_applicants: number
    pending: number
    filled: number
    hidden: number
}

export interface SessionCounts {
    date: string
    counts: SessionStatusCounts
}
