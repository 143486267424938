import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux'
import siteStateReducer from './siteStateReducer';

const getHist = (history) => combineReducers({
  router: connectRouter(history),
  siteStateReducer: siteStateReducer,
  // whatever reducers you have
})

export default getHist