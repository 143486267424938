import { gql } from '@apollo/client'
import { Result } from 'true-myth'
import { RecurrenceModel } from '../../libary/Models'
import query from '../../libary/network/apollo'

const getLocalitiesQuery = gql`
    query getLocalities {
        getLocalities(can_create_session: true) {
            id
            name_value
        }
    }
`

const getLocationsQuery = gql`
    query getLocations($localityId: String) {
        getLocations(locality_id: $localityId, can_create_session: true) {
            id
            name_value
            location_code
        }
    }
`

const getRecurrencesQuery = gql`
    query RecurrencesForMonth($month: String!, $year: String!) {
        recurrencesForMonth(month: $month, year: $year) {
            id
            start_date
            end_date
            models {
                frequency
                date
                day
            }
            session_template {
                name_value
                session_status
                session_date
                start_time
                end_time
                pricePerHour
                break

                is_assigned
                staff_type
                session_description
                travel_info
                parking_info
                payment_process
                payment_process_info
                length_of_appointments
                RECURRENCE_ID
            }
            staff_type
        }
    }
`

const getRecurrences = async (
    month: string,
    year: string,
    token: string
): Promise<Result<RecurrenceModel[], Error>> => {
    return query(getRecurrencesQuery, token, 'recurrencesForMonth', {
        month,
        year,
    })
}

export { getLocalitiesQuery, getLocationsQuery, getRecurrences }
