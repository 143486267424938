import React, { FunctionComponent, useContext, useState } from 'react'
import { ThemeContext } from '../../../../App'
import ColoredButton, { ColoredButtonEmphasis } from '../../../../components/colored-button'
import Mutations from '../../../../libary/apollo/mutations'
import useToken from '../../../../libary/hooks/useToken'
import { QualificationModel } from '../../../../libary/Models'
import StaffInfoListItem from '../../StaffInfoListItem'

interface StaffInfoQualificationsProps {
    qualifications: QualificationModel[]
    allQualifications: QualificationModel[]
    canEdit: boolean
    addQualification: Function
    removeQualification: Function
}

const StaffInfoQualifications: FunctionComponent<StaffInfoQualificationsProps> = (props: StaffInfoQualificationsProps) => {
const theme = useContext(ThemeContext)
    const [localQualifications] = useState<QualificationModel[]>(props.qualifications)
    const [showQualifications, setShowQualifications] = useState<boolean>(false)
    const token = useToken()

    const addQualification = () => {
        props.addQualification()
    }

    const removeQualification = (qualificationId: string) => {
        Mutations.deleteQualification(qualificationId, token)
        .then((response: any) => {
            if(response.success === true) {
                props.removeQualification(qualificationId)
            } else {
                alert("Failed to delete qualification")
            }
        })
    }

    const renderQualifications = () => {

        if(props.qualifications.length === 0) {
            if(props.canEdit) {
                return <p>You have no qualifications added</p>
            } else {
                return <p>User has no qualifications added</p>
            }
        }
        var elements = localQualifications.map((system) => {
            return StaffInfoListItem({
                name: system.name_value,
                id: system.id,
                removeItem: (id) => { removeQualification(id) },
                key: system.id,
                edit: props.canEdit,
                isDoc: false
            })
        })
        return elements
    }

    return (
        <div style={{ width: '100%' }}>
            <div onClick={() => { setShowQualifications(!showQualifications) }} className="button" style={{backgroundColor: theme.colors.brand}}>
                Qualifications ({props.qualifications ? props.qualifications.length : 0})
                <div className={showQualifications ? "icon open" : "icon"}
                    style={{ paddingLeft: '4px' }}>
                    chevron_right
                </div>
            </div>
            <div className={showQualifications ? "profile-section-list-visible" : "profile-section-list-hidden"}>
                {renderQualifications()}
                {props.canEdit && (
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '8px' }}>
                        <ColoredButton
                            emphasis={ColoredButtonEmphasis.HIGH}
                            text='Add'
                            color='white'
                            backgroundColor='#692068'
                            borderRadius={8}
                            onClick={addQualification}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

export default StaffInfoQualifications