import { gql } from "@apollo/client"

const staffQuery = gql`
query staffById($id: String!){
  staffById(id: $id) {
      id
      name_value
      staff_type
      title
      GMC_number
      image
      bio
      documents {
          id
          name_value
          staff_id
          staff_name
          approved
      }
      workExperience {
        id
        name_value
      }
      itSystems{
        id
        name_value
     }
      languages{
        id
        name_value
    }
      qualifications{
        id
        name_value
      }
    }
  }
`

export {
    staffQuery
}