import { FunctionComponent } from "react";
import Footer from "../../components/Footer";
import NavBarNew from "../../components/NavBarNew";
import './styles.css'

const GDPR: FunctionComponent = () => {
    return (
        <div className="gdpr-page-container">
            <NavBarNew loggedOut={true} />
            <div className="mainBody">
                <div className="gdpr-page-body">
                    <iframe title="GDPR Policy" className="gdpr-pdf-container" src="/gdpr.html" />
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default GDPR