import { useAuth0 } from "@auth0/auth0-react"
import { useEffect, useState } from "react"
import { useCookies } from "react-cookie"
import UserHelper from "../auth/UserHelper"

const useToken = () => {
    const [token, setToken] = useState<string|null>(null)
    useEffect(() => {
    }, [token])
    const auth0 = useAuth0()
    const [cookies, setCookie] = useCookies();
    UserHelper.token(cookies, auth0, setCookie).then((newToken) => setToken(newToken))
    return token
}

export default useToken