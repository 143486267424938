import styled from 'styled-components'

export const Container = styled.div`
    height: 10vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: white;
`

export const AccountButtonContainer = styled.div`
    diplay: flex; 
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 32px;
`
