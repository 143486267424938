import React, { FunctionComponent } from 'react'
import './styles.css'

interface BioProps {
    canEdit: boolean
    bio: string
}

const Bio: FunctionComponent<BioProps> = (props: BioProps) => {

    return (
        <div className="bio-container">
            <div className="bio-text-container">
                <div className="bio-text">
                    {(props.bio === "" || props.bio === undefined) && "User has no bio"}
                    {(props.bio !== undefined) && `${props.bio}`}
                </div>
            </div>
        </div>
    )
}

export default Bio