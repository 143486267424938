import { FunctionComponent } from "react";
import Footer from "../../components/Footer";
import NavBarNew from "../../components/NavBarNew";
import AuthContainer from "../../container/AuthContainer";
import PermissionsButton, { PermissionsButtonImage } from "./PermissionsButton";
import './styles.css'

export enum PermissionHomeType {
    CREATE, 
    APPLY,
    HOME
}

interface PermissionHomeProps {
    type: PermissionHomeType
}
const PermissionsHome: FunctionComponent<PermissionHomeProps> = (props: PermissionHomeProps) => {
    switch(props.type) {
        case PermissionHomeType.CREATE:
        return (
            <AuthContainer>
                <div className="permissions-home-container">
                    <NavBarNew setTab={() => { }} />
                    <div className="mainBody">
                        <div className="permissions-home-body">
                            <h1>You don't currently have any permissions</h1>
                            <h2>Use the buttons below to request permissions</h2>
                            <div className="permissions-home-button-grid">
                                <PermissionsButton title="Request Create Shift Permissions" image={PermissionsButtonImage.CREATE_SESSION} action={() => {
                                    window.location.replace('/permissions-create-session-request')
                                }}/>
                                {/* <PermissionsButton title="Request Create Employer Permissions" image={PermissionsButtonImage.EDIT} action={() => {}}/>
                                <PermissionsButton title="Request Create Location Permissions" image={PermissionsButtonImage.LOCATION} action={() => {}}/> */}
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </AuthContainer>
        )
    case PermissionHomeType.APPLY:
        return (
            <AuthContainer>
                <div className="permissions-home-container">
                    <NavBarNew setTab={() => { }} />
                    <div className="mainBody">
                        <div className="permissions-home-body">
                            <h1>You don't currently have any permissions</h1>
                            <h2>Someone will be in touch once your account is approved</h2>
                            <h3>In the meantime, please make sure you've filled out your GMPassport</h3>
                            {/* <h2>Use the buttons below to request permissions</h2> */}
                            {/* <div className="permissions-home-button-grid"> */}
                                {/* <PermissionsButton title="Request Apply For Shift Permissions" image={PermissionsButtonImage.EDIT} action={() => {}}/> */}
                            {/* </div> */}
                        </div>
                    </div>
                    <Footer />
                </div>
            </AuthContainer>
        )
    case PermissionHomeType.HOME:
        return (
            <AuthContainer>
                <div className="permissions-home-container">
                    <NavBarNew setTab={() => { }} />
                    <div className="mainBody">
                        <div className="permissions-home-body">
                            <h1>Welcome to the GM Workforce bank, Please select one of the following</h1>

                        </div>
                    </div>
                    <Footer />
                </div>
            </AuthContainer>
        )
    }
}

export default PermissionsHome