import React, { FunctionComponent, useContext } from 'react'
import { Grid } from 'react-loader-spinner'
import { ThemeContext } from '../../App'
import './styles.css'

interface LoadingSpinnerProps {
    text?: string
    padding?: string
    size?: string
}

const LoadingSpinner: FunctionComponent<LoadingSpinnerProps> = (props: LoadingSpinnerProps) => {
    const theme = useContext(ThemeContext)

    return (
        <div className="loadingSpinnerContainer" style={{padding: props.padding ? props.padding : '0px'}}>
            <Grid
                height={props.size ? props.size : "100"}
                width={props.size ? props.size : "100"}
                wrapperClass='loading'
                color={theme.colors.brand}
                ariaLabel='loading'
            />
            {}
            {props.text !== undefined && (
                <div className='loadingText' style={{color: theme.colors.secondary}}>{props.text}</div>
            )}
        </div>
    )
}

export default LoadingSpinner