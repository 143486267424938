import {
    CloseOutlined,
    DeleteOutline,
    RepeatRounded,
    VisibilityOffOutlined,
    VisibilityOutlined,
} from '@mui/icons-material'
import {
    Card,
    CardContent,
    CardHeader,
    Grid,
    IconButton,
    Tab,
    Tabs,
    ThemeProvider,
    Tooltip,
    Typography,
    createTheme,
    useMediaQuery,
} from '@mui/material'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import LoadingSpinner from '../../../components/loadingSpinner/LoadingSpinner'
import ConfirmDeleteModal, {
    ConfirmDeleteType,
} from '../../../components/modals/ConfirmDeleteModal/ConfirmDeleteModal'
import { SessionConfig } from '../../../components/modals/CreateSessionModalNew/queries'
import GeneralConfirmModal from '../../../components/modals/GeneralConfirmModal/GeneralConfirmModal'
import StaffModal from '../../../components/modals/StaffModal/StaffModal'
import { SessionModel, StaffModel } from '../../../libary/Models'
import Mutations from '../../../libary/apollo/mutations'
import useFeatures from '../../../libary/hooks/useFeatures'
import { isRecurringSession } from '../../../libary/models/SessionModel'
import ApplicationsTab from './ApplicationsTab'
import AssignStaffTab from './AssignStaffTab'
import SessionInformationTab from './SessionInformationTab'
import StaffMemberTab from './StaffMemberTab'
import {
    assignToSession,
    assignToSessionFromRecurrence,
    createRecurrenceExclusion,
    hideSession,
    makeSessionVisible,
    removeFromSession,
} from './mutations'
import { staffAvailabilityCheck } from './queries'

interface SessionViewProps {
    token: string
    session: SessionModel
    sessionConfig: SessionConfig
    onApply: () => void
    onClose?: () => void
    assignedStaffCallback?: (
        staffMember: StaffModel,
        new_session_id?: string
    ) => void
    removedStaffCallback?: () => void
    sessionDeletedCallback?: () => void
    updateSession?: (session_updates: {}) => void
}

const theme = createTheme({
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    marginBottom: '8px',
                },
            },
        },
    },
    palette: {
        primary: {
            main: '#692068',
        },
        secondary: {
            main: '#00868B',
        },
        text: {
            primary: '#000000',
            secondary: 'black',
        },
    },
})

const SessionView: React.FC<SessionViewProps> = ({
    token,
    session,
    sessionConfig,
    onApply,
    onClose,
    assignedStaffCallback,
    removedStaffCallback,
    sessionDeletedCallback,
    updateSession,
}) => {
    const [activeTab, setActiveTab] = useState<number>(0)
    const [loading, setLoading] = useState<boolean>(false)
    const [staffId, setStaffId] = useState<string | undefined>(undefined)

    const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false)
    const [showConfirmDeleteRecurrence, setShowConfirmDeleteRecurrence] =
        useState<boolean>(false)
    const [showConfirmVisibility, setShowConfirmVisibility] =
        useState<boolean>(false)
    const [assigningStaff, setAssigningStaff] = useState<
        { staff: StaffModel; available: boolean } | undefined
    >(undefined)
    const [assigningStaffFromApplication, setAssigningStaffFromApplication] =
        useState<{ staff: StaffModel; available: boolean } | undefined>(
            undefined
        )
    const { features } = useFeatures()
    const handleTabChange = (event: any, newValue: number) => {
        setActiveTab(newValue)
    }
    useEffect(() => {
        console.log('PROP SESSION: ', session)
    }, [session])

    const matches = useMediaQuery(theme.breakpoints.down('sm'))
    const getHeaderColor = () => {
        switch (session.session_status) {
            case 'no_applicants':
                return 'var(--noapps-color)'
            case 'pending':
                return 'var(--pending-color)'
            case 'filled':
                return 'var(--filled-color)'
            case 'hidden':
                return 'lightGrey'
        }
    }

    const setVisibility = async () => {
        if (session.session_status === 'hidden') {
            const result = await makeSessionVisible(session.id, token)
            if (result.isOk) {
                setShowConfirmVisibility(false)
                updateSession({ session_status: result.value.session_status })
            }
        } else {
            const result = await hideSession(session.id, token)
            if (result.isOk) {
                setShowConfirmVisibility(false)
                updateSession({ session_status: 'hidden' })
            }
        }
    }

    const checkAvailability = async (id: string) => {
        try {
            const availabilityResult = await staffAvailabilityCheck(
                {
                    sessionDate: session.session_date,
                    staffId: id,
                },
                token
            )

            if (availabilityResult.isOk) {
                const available = availabilityResult.value.available
                return available
            } else {
                return false
            }
        } catch (error) {}
    }

    const assignStaff = async () => {
        if (assigningStaffFromApplication === undefined) {
            console.log('SESSION TO ASSIGN: ', session)
            if (session.id.includes('RECURRENCE') === true) {
                const result = await assignToSessionFromRecurrence(
                    {
                        recurrenceId: session.RECURRENCE_ID,
                        date: session.session_date,
                        staffId: assigningStaff.staff.id,
                    },
                    token
                )
                console.log('ASSIGNING: ', {
                    recurrenceId: session.RECURRENCE_ID,
                    date: session.session_date,
                    staffId: assigningStaff.staff.id,
                })
                if (result.isOk) {
                    assignedStaffCallback(
                        assigningStaff.staff ??
                            assigningStaffFromApplication.staff,
                        result.value.id
                    )
                    setAssigningStaff(undefined)
                    setActiveTab(1)
                } else {
                    alert('Something went wrong when assigning to session')
                }
            } else {
                const result = await assignToSession(
                    session.id,
                    assigningStaff.staff.id,
                    token
                )
                if (result.isOk) {
                    assignedStaffCallback(
                        assigningStaff.staff ??
                            assigningStaffFromApplication.staff
                    )
                    setAssigningStaff(undefined)
                    setActiveTab(1)
                } else {
                    alert('Something went wrong when assigning to session')
                }
            }
        } else {
            const id = assigningStaff
                ? assigningStaff.staff.id
                : assigningStaffFromApplication
                ? assigningStaffFromApplication.staff.id
                : ''
            if (session.id.includes('RECURRENCE') === true) {
                const result = await assignToSessionFromRecurrence(
                    {
                        recurrenceId: session.RECURRENCE_ID,
                        date: session.session_date,
                        staffId: id,
                    },
                    token
                )
                if (result.isOk) {
                    assignedStaffCallback(
                        assigningStaff?.staff ??
                            assigningStaffFromApplication.staff,
                        result.value.id
                    )
                    setAssigningStaffFromApplication(undefined)
                    setActiveTab(1)
                } else {
                    alert('Something went wrong when assigning to session')
                }
            } else {
                const result = await assignToSession(session.id, id, token)
                if (result.isOk) {
                    assignedStaffCallback(
                        assigningStaff?.staff ??
                            assigningStaffFromApplication.staff
                    )
                    setAssigningStaffFromApplication(undefined)
                    setActiveTab(1)
                } else {
                    alert('Something went wrong when assigning to session')
                }
            }
        }
    }

    const removeStaffFromSession = async () => {
        const result = await removeFromSession(
            session.id,
            session.staff?.id,
            token
        )

        if (result.isOk && result.value.success === true) {
            removedStaffCallback()
        }
    }

    const renderAssigningStaff = () => {
        let name_value = assigningStaff
            ? assigningStaff.staff.name_value
            : assigningStaffFromApplication
            ? assigningStaffFromApplication.staff.name_value
            : ''

        let available = assigningStaff
            ? assigningStaff.available
            : assigningStaffFromApplication
            ? assigningStaffFromApplication.available
            : false
        if (
            (!available && features.canOverrideAssignSession === true) ||
            available
        ) {
            return (
                <GeneralConfirmModal
                    show={
                        assigningStaff !== undefined ||
                        assigningStaffFromApplication !== undefined
                    }
                    closeModal={() => {
                        setAssigningStaff(undefined)
                        setAssigningStaffFromApplication(undefined)
                    }}
                    title={
                        available
                            ? `Do you want to assign ${name_value}?`
                            : `User already has a session on this date, Do you want to assign ${name_value}?`
                    }
                    confirmAction={assignStaff}
                />
            )
        } else if (!available && features.canOverrideAssignSession === false) {
            alert('This user alrady has a session on this date.')
            return (
                <>
                    <p>Test</p>
                </>
            )
        } else {
            return (
                <>
                    <p>Test</p>
                </>
            )
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <Card
                sx={{
                    borderColor: '#979797',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    width: matches ? '100vw' : 'auto',
                    height: matches ? '100vh' : 'auto',
                    maxHeight: '80vh',
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                <CardHeader
                    title={
                        <Grid container>
                            <Grid item xs={6}>
                                {isRecurringSession(session) && (
                                    <Tooltip title='This session is part of a Recurrence'>
                                        <RepeatRounded
                                            style={{ color: 'white' }}
                                        />
                                    </Tooltip>
                                )}
                                <Typography
                                    variant='h5'
                                    component='div'
                                    color='white'>
                                    {session.name_value && (
                                        <>
                                            {session.name_value} @{' '}
                                            {session.location?.name_value}
                                        </>
                                    )}
                                    {!session.name_value && (
                                        <>
                                            {session.staff_type} @{' '}
                                            {session.location?.name_value}
                                        </>
                                    )}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                display='flex'
                                flexDirection='column'
                                alignItems='flex-end'>
                                <Grid
                                    container
                                    display='flex'
                                    flexDirection='row'
                                    justifyContent='flex-end'
                                    spacing={1}>
                                    {!session.id.includes('RECURRENCE') && (
                                        <Grid item>
                                            <Tooltip
                                                title={
                                                    session.session_status ===
                                                    'hidden'
                                                        ? 'This session is Hidden. Set it to visible?'
                                                        : 'This session is Visible. Set it to Hidden?'
                                                }>
                                                <IconButton
                                                    style={{
                                                        padding: '0px',
                                                        color: 'white',
                                                    }}
                                                    onClick={() =>
                                                        setShowConfirmVisibility(
                                                            true
                                                        )
                                                    }>
                                                    {session.session_status ===
                                                        'hidden' && (
                                                        <VisibilityOffOutlined />
                                                    )}
                                                    {session.session_status !==
                                                        'hidden' && (
                                                        <VisibilityOutlined />
                                                    )}
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    )}
                                    {session.id.includes('SESSION') &&
                                        features.manageRota && (
                                            <Grid item>
                                                <Tooltip title='Delete this session.'>
                                                    <IconButton
                                                        style={{
                                                            padding: '0px',
                                                            color: 'white',
                                                        }}
                                                        onClick={() =>
                                                            setShowConfirmDelete(
                                                                true
                                                            )
                                                        }>
                                                        <DeleteOutline />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        )}
                                    {session.id.includes('RECURRENCE') &&
                                        features.manageRota && (
                                            <Grid item>
                                                <Tooltip title='Exlude this session from recurrence.'>
                                                    <IconButton
                                                        style={{
                                                            padding: '0px',
                                                            color: 'white',
                                                        }}
                                                        onClick={() =>
                                                            setShowConfirmDeleteRecurrence(
                                                                true
                                                            )
                                                        }>
                                                        <DeleteOutline />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        )}
                                    <Grid item>
                                        <IconButton
                                            style={{
                                                padding: '0px',
                                                color: 'white',
                                            }}
                                            onClick={() => onClose()}>
                                            <CloseOutlined />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                                <Typography
                                    variant='h6'
                                    component='div'
                                    color='white'>
                                    {session.session_date}
                                </Typography>
                                <Typography variant='body2' color='white'>
                                    {moment(session.start_time).format('HH:mm')}{' '}
                                    - {moment(session.end_time).format('HH:mm')}
                                </Typography>
                            </Grid>
                        </Grid>
                    }
                    subheader={session.locality_name}
                    style={{ backgroundColor: getHeaderColor() }} // Updated background color
                />
                {loading === true && <LoadingSpinner />}
                {loading === false && (
                    <CardContent
                        sx={{
                            flexGrow: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            overflowY: 'hidden',
                        }}>
                        <Tabs
                            value={activeTab}
                            onChange={handleTabChange}
                            aria-label='Session tabs'
                            style={{
                                marginBottom: '16px',
                                overflow: 'scroll',
                            }}>
                            <Tab label='Information' />
                            {session.session_status !== 'filled' &&
                                features.canAssignSessions === true && (
                                    <Tab label='Assign Staff Member' />
                                )}
                            {session.session_status === 'pending' &&
                                features.canAssignSessions === true && (
                                    <Tab label='Applications' />
                                )}
                            {session.session_status === 'filled' &&
                                features.canAssignSessions === true && (
                                    <Tab label='Staff Member' />
                                )}
                        </Tabs>
                        {activeTab === 0 && (
                            <SessionInformationTab
                                token={token}
                                session={session}
                                sessionConfig={sessionConfig}
                                onApply={() => {
                                    onApply()
                                }}
                            />
                        )}
                        {activeTab === 1 &&
                            features.canAssignSessions &&
                            session.session_status !== 'filled' && (
                                <AssignStaffTab
                                    staff_type={session.staff_type}
                                    showStaff={(id: string) => setStaffId(id)}
                                    onAssignStaff={async (
                                        staffModel: StaffModel
                                    ) => {
                                        const res = await checkAvailability(
                                            staffModel.id
                                        )
                                        setAssigningStaff({
                                            staff: staffModel,
                                            available: res,
                                        })
                                    }}
                                    token={token}
                                />
                            )}
                        {activeTab === 1 &&
                            features.canAssignSessions &&
                            session.session_status === 'filled' && (
                                <StaffMemberTab
                                    id={session.staff?.id}
                                    sessionId={session.id}
                                    token={token}
                                    removeStaffMember={() =>
                                        removeStaffFromSession()
                                    }
                                />
                            )}
                        {activeTab === 2 &&
                            features.manageRota &&
                            session.session_status === 'pending' && (
                                <ApplicationsTab
                                    applications={session.applicants}
                                    showStaff={(id: string) => setStaffId(id)}
                                    onAssignStaff={async (
                                        staffModel: StaffModel
                                    ) => {
                                        const res = await checkAvailability(
                                            staffModel.id
                                        )
                                        setAssigningStaffFromApplication({
                                            staff: staffModel,
                                            available: res,
                                        })
                                    }}
                                />
                            )}
                    </CardContent>
                )}
            </Card>
            {staffId && (
                <StaffModal
                    id={staffId}
                    show={staffId !== undefined}
                    closeModal={() => setStaffId(undefined)}
                />
            )}
            <ConfirmDeleteModal
                show={showConfirmDelete}
                closeModal={() => setShowConfirmDelete(false)}
                deleteType={ConfirmDeleteType.SESSION}
                confirmAction={() => {
                    setLoading(true)
                    Mutations.deleteSession(session.id, token).then(
                        (response: any) => {
                            setLoading(false)
                            if (response.success === true) {
                                if (sessionDeletedCallback) {
                                    sessionDeletedCallback()
                                } else {
                                    window.location.reload()
                                }
                            } else {
                                alert('Deleting session failed')
                                setShowConfirmDelete(false)
                            }
                        }
                    )
                }}
            />
            <ConfirmDeleteModal
                show={showConfirmDeleteRecurrence}
                closeModal={() => setShowConfirmDeleteRecurrence(false)}
                deleteType={ConfirmDeleteType.SCHEDULE_SESSION}
                confirmAction={async () => {
                    setLoading(true)
                    const result = await createRecurrenceExclusion(
                        { id: session.id, session_date: session.session_date },
                        token
                    )
                    if (result.isOk) {
                        if (sessionDeletedCallback) {
                            sessionDeletedCallback()
                        } else {
                            window.location.reload()
                        }
                    } else {
                        alert('Deleting session failed')
                        setShowConfirmDeleteRecurrence(false)
                    }
                }}
            />
            <GeneralConfirmModal
                show={showConfirmVisibility}
                title={
                    session.session_status === 'hidden'
                        ? 'Do you want to make this session visible?'
                        : 'Do you want to hide this session?'
                }
                closeModal={() => setShowConfirmVisibility(false)}
                confirmAction={() => setVisibility()}
            />
            {(assigningStaff || assigningStaffFromApplication) &&
                renderAssigningStaff()}
        </ThemeProvider>
    )
}

export default SessionView
