import { useAuth0 } from "@auth0/auth0-react";
import { FunctionComponent, useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../App";
import ColoredButton, { ColoredButtonEmphasis } from "../../components/colored-button";
import Footer from "../../components/Footer";
import LoadingSpinner from "../../components/loadingSpinner";
import NavBarNew from "../../components/NavBarNew";
import AuthContainer from "../../container/AuthContainer";
import client from "../../libary/apollo";
import { setStaffTypeCreatorMutation } from "./mutations";
import { accountStateQuery } from "./queries";

import './styles.css'

const SelectAccountTypePage: FunctionComponent = () => {
    const theme = useContext(ThemeContext)
    const { getAccessTokenSilently } = useAuth0()
    const [ token, setToken ] = useState<string|null>(null)
    const [ loading, setLoading ] = useState<boolean>(true)

    useEffect(() => {
        (async() => {
            const newToken = await getAccessTokenSilently()
            setToken(newToken)
            client.query({
                query: accountStateQuery,
                context: {
                    headers: {
                        "Authorization": newToken
                    }
                }
            }).then((response) => {
                if(response.data.me.account_state !== "ACCOUNT_TYPE") { window.location.replace("/") }
                else { setLoading(false) }
            })
        })()
    }, [getAccessTokenSilently])

    const accountTypeSelected = (account_type?: string) => {
        ( async () => {
            setLoading(true)
            client.mutate({
                mutation: setStaffTypeCreatorMutation,
                variables: {
                    accountType: account_type
                },
                context: {
                    headers: {
                        "Authorization": token
                    }
                }
            }).then((response) => {
                if( response.data.updateAccountType.success === false) {
                    alert("Unable to set staff type")
                } else {
                    window.location.replace("/")
                }
            })
        })()
    }

    return (
        <div style={{ display: 'flex', width: '100vw', height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
            <AuthContainer>
                <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', width: '100vw' }}>
                    <NavBarNew hideButtons={true}/>
                    <div className='mainBody'>
                    {loading === false && 
                        <div className='account-type-modal-container'>
                            <p className='account-type-modal-title'>Please select which best describes your use of the system</p>
                            <div className='account-type-button-container'>
                                <ColoredButton text='I want to post shifts on behalf of a Practice, PCN or Federation' emphasis={ColoredButtonEmphasis.HIGH} backgroundColor={theme.colors.brand} color={theme.colors.lightText} margin={'4px'} onClick={() => { accountTypeSelected("CREATOR") }} />
                                <ColoredButton text='I want to apply to work shifts' emphasis={ColoredButtonEmphasis.HIGH} backgroundColor={theme.colors.brand} color={theme.colors.lightText} margin={'4px'} onClick={() => { accountTypeSelected(null) }} />
                            </div>
                        </div>
                    }
                    {loading === true && <LoadingSpinner/>}
                    </div>
                    <Footer />
                </div>
            </AuthContainer>
        </div>
    )
}

export default SelectAccountTypePage