import { useAuth0 } from "@auth0/auth0-react";
import { FunctionComponent, useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../App";
import ColoredButton, { ColoredButtonEmphasis } from "../../components/colored-button";
import Footer from "../../components/Footer";
import NavBarNew from "../../components/NavBarNew";
import AuthContainer from "../../container/AuthContainer";
import client from "../../libary/apollo";
import ApprovedUnapprovedCard from "./Cards/ApprovedUnapprovedCard";
import NumberCard from "./Cards/NumberCard";
import { sessionsAnalyticsQuery, userCountBreakdownQuery } from "./queries";
import './styles.css'

interface ApprovedUnapproved {
    approved?: number
    total?: number
}

const emptyApprovedUnapproved: ApprovedUnapproved = {
    approved: null,
    total: null
}

interface UserCountBreakdown {
    all: ApprovedUnapproved
    GP: ApprovedUnapproved
    CREATOR: ApprovedUnapproved
    OTHER: ApprovedUnapproved
}

interface SessionsAnalyticsBreakdown {
    sessionsRequestedCount?: number
    filledSessionsCount?: number
    unfilledSessionsCount?: number
    fillRatio?: number
}

const initialUserCountBreakdown: UserCountBreakdown = {
    all: emptyApprovedUnapproved,
    GP: emptyApprovedUnapproved,
    CREATOR: emptyApprovedUnapproved,
    OTHER: emptyApprovedUnapproved
}

const initialSessionsAnalyticsBreakdown: SessionsAnalyticsBreakdown = {
    sessionsRequestedCount: null,
    filledSessionsCount: null,
    unfilledSessionsCount: null,
    fillRatio: null
}

const AllMonths = [
    {
        name: "All",
        key: "00"
    },
    {
        name: "January",
        key: "01"
    },
    {
        name: "Febuary",
        key: "02"
    },
    {
        name: "March",
        key: "03"
    },
    {
        name: "April",
        key: "04"
    },
    {
        name: "May",
        key: "05"
    },
    {
        name: "June",
        key: "06"
    },
    {
        name: "July",
        key: "07"
    },
    {
        name: "August",
        key: "08"
    },
    {
        name: "September",
        key: "09"
    },
    {
        name: "October",
        key: "10"
    },
    {
        name: "November",
        key: "11"
    },
    {
        name: "December",
        key: "12"
    },
]

const AllYears = ["2022", "2023"]

const AnalyticsPage: FunctionComponent = () => {
    const theme = useContext(ThemeContext)
    const [month, setMonth] = useState<string>("00")
    const [year, setYear] = useState<string>("2023")
    const { getAccessTokenSilently } = useAuth0()
    const [userCount, setUserCount] = useState<UserCountBreakdown>(initialUserCountBreakdown)
    const [sessionsAnalytics, setSessionsAnalytics] = useState<SessionsAnalyticsBreakdown>(initialSessionsAnalyticsBreakdown)

    useEffect(() => {
        (async () => {
            const token = await getAccessTokenSilently()
            client.query({
                query: userCountBreakdownQuery,
                context: {
                    headers: {
                        "Authorization": token
                    }
                }
            }).then((response) => {
                console.log("RESPONSE: ", response.data)
                setUserCount(response.data.userCountBreakdown)
            })
        })()
    }, [getAccessTokenSilently])

    useEffect(() => {
        (async () => {
            setSessionsAnalytics(initialSessionsAnalyticsBreakdown)
            const token = await getAccessTokenSilently()
            client.query({
                query: sessionsAnalyticsQuery,
                variables: {
                    month, 
                    year
                },
                context: {
                    headers: {
                        "Authorization": token
                    }
                }
            }).then((response) => {
                setSessionsAnalytics(response.data.sessionsAnalytics)
            })
        })()
    }, [getAccessTokenSilently, month, year])

    return (
        <AuthContainer>
            <div className="analytics-container">
                <NavBarNew />
                <div className="mainBody">
                    <div className="analytics-body">
                        <div className="analytics-filter-bar-container">
                            <div className="analytics-filter-bar-filter-container">
                                <label>Month</label>
                                <select className="dropdown" onChange={(event) => setMonth(AllMonths[event.target.selectedIndex].key)}>
                                    {AllMonths.map((month) => {
                                        return <option>{month.name}</option>
                                    })}
                                </select>
                            </div>
                            <div className="analytics-filter-bar-filter-container">
                                <label>Year</label>
                                <select defaultValue={"2023"} className="dropdown" onChange={(event) => setYear(AllYears[event.target.selectedIndex])}>
                                    {AllYears.map((year) => {
                                        return <option>{year}</option>
                                    })}
                                </select>
                            </div>
                            <div className="rota-filter-bar-button-container">
                    <ColoredButton
                        text='Reset Filters'
                        onClick={() => {
                            setYear("2023")
                            setMonth("00")
                        }}
                        emphasis={ColoredButtonEmphasis.HIGH}
                        backgroundColor={theme.colors.brand}
                        color={theme.colors.lightText}
                    />
                </div>
                        </div>
                        <div className="analytics-header">User Numbers</div>
                        <div className="analytics-section">
                            <ApprovedUnapprovedCard title="Total Users" approvedNumber={userCount.all.approved} totalNumber={userCount.all.total} />
                            <ApprovedUnapprovedCard title="GP" approvedNumber={userCount.GP.approved} totalNumber={userCount.GP.total} />
                            <ApprovedUnapprovedCard title="CREATOR" approvedNumber={userCount.CREATOR.approved} totalNumber={userCount.CREATOR.total} />
                            <ApprovedUnapprovedCard title="OTHER" approvedNumber={userCount.OTHER.approved} totalNumber={userCount.OTHER.total} />
                        </div>
                        <div className="analytics-header">Sessions Analytics</div>
                        <div className="analytics-section">
                            <NumberCard title="Sessions Requested" number={sessionsAnalytics.sessionsRequestedCount} />
                            <NumberCard title="Filled Sessions" number={sessionsAnalytics.filledSessionsCount} />
                            <NumberCard title="Unfilled Sessions" number={sessionsAnalytics.unfilledSessionsCount} />
                            <NumberCard title="Session Fill Ratio" number={sessionsAnalytics.fillRatio} percentage={true} />
                        </div>
                    </div>
                </div>
                <Footer />

            </div>
        </AuthContainer>
    )
}

export default AnalyticsPage