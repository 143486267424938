import { gql } from '@apollo/client'
import { Result } from 'true-myth'
import {
    EmployerModel,
    ItSystemModelNew,
    LocalityModel,
    LocationModel,
    StaffTypeModel,
} from '../../../../../libary/Models'
import query from '../../../../../libary/network/apollo'

const getLocalitiesQuery = gql`
    query GetLocalities {
        getLocalities(can_create_session: true) {
            id
            name_value
        }
    }
`

const getLocationsQuery = gql`
    query GetLocations($localityId: String) {
        getLocations(locality_id: $localityId, can_create_session: true) {
            id
            name_value
        }
    }
`

const getEmployersQuery = gql`
    query GetEmployers($localityId: String) {
        getEmployers(locality_id: $localityId, can_create_session: true) {
            id
            name_value
        }
    }
`

const getStaffTypesQuery = gql`
    query GetStaffTypes {
        getStaffTypes {
            id
            name_value
        }
    }
`

const getItSystemsQuery = gql`
    query GetItSystems {
        itSystems {
            id
            name_value
        }
    }
`

const getLocalities = (
    token: string
): Promise<Result<LocalityModel[], Error>> => {
    return query(getLocalitiesQuery, token, 'getLocalities')
}

const getLocations = (
    localityId: string,
    token: string
): Promise<Result<LocationModel[], Error>> => {
    return query(getLocationsQuery, token, 'getLocations', { localityId })
}

const getEmployers = (
    localityId: string,
    token: string
): Promise<Result<EmployerModel[], Error>> => {
    return query(getEmployersQuery, token, 'getEmployers', { localityId })
}

const getStaffTypes = (
    token: string
): Promise<Result<StaffTypeModel[], Error>> => {
    return query(getStaffTypesQuery, token, 'getStaffTypes')
}

const getItSystems = (
    token: string
): Promise<Result<ItSystemModelNew[], Error>> => {
    return query(getItSystemsQuery, token, 'itSystems')
}

export {
    getEmployers,
    getItSystems,
    getLocalities,
    getLocations,
    getStaffTypes,
}
