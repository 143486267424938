import { useAuth0 } from '@auth0/auth0-react'
import { FunctionComponent, useEffect, useState } from 'react'
import Text from '../../../../components/text'
import { ApplicationModel } from '../../../../libary/Models'
import client from '../../../../libary/apollo'
import PendingSessionCard from './PendingSessionCard'
import { getMyPendingSessionsQuery } from './queries'
import './styles.css'

interface PendingSessionsProps {
    sessionSelected: (sessionSelected: ApplicationModel) => void
}

const PendingSessions: FunctionComponent<PendingSessionsProps> = (
    props: PendingSessionsProps
) => {
    const { getAccessTokenSilently } = useAuth0()
    const [pendingSessions, setPendingSessions] = useState<
        ApplicationModel[] | undefined
    >(undefined)

    useEffect(() => {
        ;(async () => {
            const token = await getAccessTokenSilently()
            client
                .query({
                    query: getMyPendingSessionsQuery,
                    context: {
                        headers: {
                            Authorization: token,
                        },
                    },
                })
                .then((response) => {
                    setPendingSessions(response.data.myPendingSessions)
                })
        })()
    })

    const renderApplications = () => {
        if (pendingSessions === undefined) {
            return
        }
        return (
            pendingSessions.map((application) => {
                return (
                    <PendingSessionCard
                        application={application}
                        onClick={() => props.sessionSelected(application)}
                    />
                )
            }) ?? []
        )
    }
    return (
        <div className='pending-session-container'>
            <Text text='Pending Applications:' type='header' />
            {renderApplications()}
        </div>
    )
}

export default PendingSessions
