import React, { FunctionComponent, useContext, useState } from 'react'
import { ThemeContext } from '../../../../App'
import ColoredButton, { ColoredButtonEmphasis } from '../../../../components/colored-button'
import Mutations from '../../../../libary/apollo/mutations'
import useToken from '../../../../libary/hooks/useToken'
import { WorkExperienceModel } from '../../../../libary/Models'
import StaffInfoListItem from '../../StaffInfoListItem'

interface StaffInfoWorkExperienceProps {
    workExperience: WorkExperienceModel[]
    canEdit: boolean
    addWorkExperience: Function
    removeWorkExperience: Function
}

const StaffInfoWorkExperience: FunctionComponent<StaffInfoWorkExperienceProps> = (props: StaffInfoWorkExperienceProps) => {
    const theme = useContext(ThemeContext)
    const [showWorkExperience, setShowWorkExperience] = useState<boolean>(false)
    const token = useToken()

    const addWorkExperience = (event) => {
        props.addWorkExperience()
    }

    const removeWorkExperience = (workExperienceId: string) => {
        Mutations.deleteExperience(workExperienceId, token)
            .then((response) => {
                if (response.success === true) {
                    props.removeWorkExperience(workExperienceId)
                } else {
                    alert("Failed to delete work experience")
                }
            })
    }

    const renderWorkExperience = () => {
        if (props.workExperience.length === 0) {
            if (props.canEdit) {
                return <p>You have no work experience added</p>
            } else {
                return <p>User has no work experience added</p>
            }
        }
        var elements = props.workExperience.map((system) => {
            return StaffInfoListItem({
                name: system.name_value,
                id: system.id,
                removeItem: (id) => { removeWorkExperience(id) },
                key: system.id,
                edit: props.canEdit,
                isDoc: false
            })
        })
        return elements
    }

    return (
        <div style={{ width: '100%' }}>
            <div onClick={() => { setShowWorkExperience(!showWorkExperience) }} className="button" style={{backgroundColor: theme.colors.brand}}>
                Previous Work Experience ({props.workExperience ? props.workExperience.length : 0})
                <div className={showWorkExperience ? "icon open" : "icon"}
                    style={{ paddingLeft: '4px' }}>
                    chevron_right
                </div>
            </div>
            <div className={showWorkExperience ? "profile-section-list-visible" : "profile-section-list-hidden"}>
                {renderWorkExperience()}
                {props.canEdit && (
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '8px' }}>
                        <ColoredButton
                            emphasis={ColoredButtonEmphasis.HIGH}
                            text='Add'
                            color='white'
                            backgroundColor='#692068'
                            borderRadius={8}
                            onClick={addWorkExperience}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

export default StaffInfoWorkExperience