import { useAuth0 } from '@auth0/auth0-react'
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Grid,
    List,
    ListItem,
    ListItemText,
    Paper,
    Stack,
    Typography,
} from '@mui/material'
import {
    DateCalendar,
    DayCalendarSkeleton,
    LocalizationProvider,
    PickersDay,
    PickersDayProps,
} from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from 'dayjs'
import moment from 'moment'
import { FC, useEffect, useState } from 'react'
import { SessionModel } from '../../libary/Models'
import { sessionsForStaffMember } from './queries'

interface StaffSessionsTabProps {
    id: string
}

const StaffSessionsTab: FC<StaffSessionsTabProps> = ({ id }) => {
    const { getAccessTokenSilently } = useAuth0()
    const [loading, setLoading] = useState<boolean>(true)
    const [sessions, setSessions] = useState<SessionModel[] | undefined>(
        undefined
    )
    const [selectedMonth, setSelectedMonth] = useState<Date>(new Date())
    const [selectedDate, setSelectedDate] = useState<Date>(new Date())

    useEffect(() => {
        ;(async () => {
            const token = await getAccessTokenSilently()
            const result = await sessionsForStaffMember(
                {
                    staffId: id,
                    upcoming: true,
                    month: moment(selectedMonth).format('MM'),
                    year: moment(selectedMonth).format('YYYY'),
                },
                token
            )
            if (result.isOk) {
                setSessions(result.value)
                setLoading(false)
            }
        })()
    }, [selectedMonth])

    const renderDay = (props: PickersDayProps<Dayjs>) => {
        const { day, outsideCurrentMonth, ...other } = props
        const isSelected = sessions
            ? sessions.filter(
                  (session) => session.session_date === day.format('YYYY-MM-DD')
              ).length > 0
            : false

        return (
            <PickersDay
                sx={{
                    backgroundColor: isSelected
                        ? 'rgba(0, 255, 0, 0.4)'
                        : 'transparent',
                }}
                {...other}
                outsideCurrentMonth={outsideCurrentMonth}
                day={day}
            />
        )
    }

    const getHeaderColor = (session_status: string) => {
        switch (session_status) {
            case 'no_applicants':
                return 'var(--noapps-color)'
            case 'pending':
                return 'var(--pending-color)'
            case 'filled':
                return 'var(--filled-color)'
        }
    }

    return (
        <Box>
            <Stack direction='row' spacing={2}>
                <Paper sx={{ padding: '8px' }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateCalendar
                            sx={{
                                '.MuiDayCalendar-weekContainer': {
                                    paddingTop: '4px',
                                },
                            }}
                            defaultValue={dayjs()}
                            loading={loading}
                            onMonthChange={(i) => {
                                setSelectedMonth(dayjs(i).toDate())
                            }}
                            onChange={(date) => {
                                setSelectedDate(dayjs(date).toDate())
                            }}
                            renderLoading={() => <DayCalendarSkeleton />}
                            slots={{
                                day: renderDay,
                            }}
                            slotProps={{}}
                        />
                    </LocalizationProvider>
                </Paper>
                <Stack direction='column'>
                    <Paper sx={{ padding: '8px' }}>
                        <Typography>Sessions:</Typography>
                        <List>
                            {sessions &&
                                sessions.filter(
                                    (session) =>
                                        session.session_date ===
                                        moment(selectedDate).format(
                                            'YYYY-MM-DD'
                                        )
                                ).length === 0 && (
                                    <ListItem key='no-sessions'>
                                        <ListItemText>
                                            User has no sessions on this day
                                        </ListItemText>
                                    </ListItem>
                                )}
                            {sessions &&
                                sessions
                                    .filter(
                                        (session) =>
                                            session.session_date ===
                                            moment(selectedDate).format(
                                                'YYYY-MM-DD'
                                            )
                                    )
                                    .map((item) => {
                                        return (
                                            <ListItem key={item.id}>
                                                <Card>
                                                    <CardHeader
                                                        sx={{
                                                            backgroundColor:
                                                                getHeaderColor(
                                                                    item.session_status
                                                                ),
                                                        }}
                                                    />
                                                    <CardContent>
                                                        <Grid container>
                                                            <Grid item xs={12}>
                                                                {
                                                                    item.name_value
                                                                }
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                {
                                                                    item
                                                                        .locality
                                                                        ?.name_value
                                                                }
                                                                :{' '}
                                                                {
                                                                    item
                                                                        .location
                                                                        ?.name_value
                                                                }
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                Staff Type:{' '}
                                                                {
                                                                    item.staff_type
                                                                }
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                Start Time:{' '}
                                                                {moment
                                                                    .unix(
                                                                        item.start_time /
                                                                            1000
                                                                    )
                                                                    .format(
                                                                        'HH:mm'
                                                                    )}
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                End Time:{' '}
                                                                {moment
                                                                    .unix(
                                                                        item.end_time /
                                                                            1000
                                                                    )
                                                                    .format(
                                                                        'HH:mm'
                                                                    )}
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                            </ListItem>
                                        )
                                    })}
                        </List>
                    </Paper>
                </Stack>
            </Stack>
        </Box>
    )
}

export default StaffSessionsTab
