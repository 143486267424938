import React from "react";

import "./styles.css";

import { useForm } from "react-form";
import SelectField from "./Fields/SelectField";
import InputField from "./Fields/InputField";
import { StaffModel, StaffTypeModel } from "../../../libary/Models";
import TextArea from "./Fields/TextArea";

interface EditAccountFormProps {
    defaultMe: StaffModel
    submitForm: Function
    staffTypes: StaffTypeModel[]
}

function EditAccountForm(props: EditAccountFormProps) {
    let defaultValues = props.defaultMe
    const {
        Form,
        meta: { canSubmit }
    } = useForm({
        defaultValues,
        onSubmit: values => {
            props.submitForm(values)
        }
    });

    return (
        <Form>
            <table className='editAccountTable'>
                <tbody style={{width: '100%'}}>

                    {props.defaultMe.staff_type !== "CREATOR" && (
                        <tr style={{width: '100%'}}>
                        <td className='edit-account-label-container'>
                            <label className="edit-account-label">
                                Staff Type:{" "}
                            </label>
                        </td>
                        <td>
                            <SelectField
                                value="GP"
                                field="staff_type"
                                options={props.staffTypes.map((staffType: StaffTypeModel) => staffType.name_value)}
                                validate={value => (!value ? "Required" : false)}
                            />
                        </td>
                    </tr>
                    )}
                    <tr>
                        <td className='edit-account-label-container'>
                            <label className="edit-account-label">
                                Title:
                            </label>
                        </td>
                        <td>
                            <InputField field="title" default={props.defaultMe.title} />
                        </td>
                    </tr>
                    <tr>
                        <td className='edit-account-label-container'>
                            <label className="edit-account-label">
                                Name:
                            </label>
                        </td>
                        <td>
                            <InputField field="name_value" default={props.defaultMe.name_value} />
                        </td>
                    </tr>

                    <tr>
                        <td className='edit-account-label-container'>
                            <label className="edit-account-label">
                                Bio:
                            </label>
                        </td>
                        <td>
                            <TextArea className='editAccountBioInput' field="bio" default={props.defaultMe.bio} />
                        </td>
                    </tr>
                </tbody>
            </table>

            <div className="edit-account-button-container">
                <button className='edit-account-button' type="submit" disabled={!canSubmit}>
                    Submit
                </button>
            </div>
        </Form>
    );
}

export default EditAccountForm
