import Config from "./Config";

const FedworksConfig: Config = {
    auth0: {
        clientId: "554ZR5Y64UbKI9ix7Aq1sAVdSwv7aRmS",
        domain: "dev-rgyntzi9.eu.auth0.com",
        redirectUri: process.env.NODE_ENV !== "production" ? "http://localhost:3000" : "https://fedworks.org",
        audience: "https://graphql.fedworks.org",
        useRefreshTokens: true,
        cacheLocation: "localstorage"
    }
}

export default FedworksConfig