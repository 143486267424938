import { gql } from "@apollo/client"

const locationQuery = gql`
query location($locality_id: String!, $location_id: String!){
    getLocation(locality_id: $locality_id, location_id: $location_id){
      id
      locality_id
      name_value
      address_line1
      address_line2
      address_line3
      town
      country
      postcode
      location_code
      travel_info
      parking
      phone_number
      mobile_number
      about
      can_edit
      contact_name
      contact_email
      contact_job_title
      contact_telephone
      latitude
      longitude
      website
    }
  }
`

export { 
  locationQuery
}