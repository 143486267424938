import { gql } from "@apollo/client";

const acceptCreatorTermsMutation = gql`
mutation { 
    acceptCreatorTerms {
      success
    }
    }
`

export { 
    acceptCreatorTermsMutation
}