import { RepeatRounded } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import moment from 'moment'
import { FunctionComponent, useContext, useState } from 'react'
import { ThemeContext } from '../../App'
import CloseImage from '../../assets/icons/ic_close.svg'
import OpenNewImage from '../../assets/icons/ic_open_new.svg'
import ColoredButton, {
    ColoredButtonEmphasis,
} from '../../components/colored-button'
import LoadingSpinner from '../../components/loadingSpinner'
import ConfirmDeleteModal from '../../components/modals/ConfirmDeleteModal'
import { ConfirmDeleteType } from '../../components/modals/ConfirmDeleteModal/ConfirmDeleteModal'
import GeneralConfirmModal from '../../components/modals/GeneralConfirmModal'
import { idWithoutHash } from '../../libary/IDHelpers'
import { SessionModel, StaffModel } from '../../libary/Models'
import client from '../../libary/apollo'
import Mutations from '../../libary/apollo/mutations'
import useFeatures from '../../libary/hooks/useFeatures'
import useToken from '../../libary/hooks/useToken'
import { isRecurringSession } from '../../libary/models/SessionModel'
import PendingStaff from '../pendingStaff'
import StaffInformation from '../staffInformation'
import AssignStaffSection from './AssignStaffSection'
import { updateSessionStaffTypeMutation } from './mutations'
import './styles.css'

const getTimeString = (time: number): string => {
    return moment(time).format('HH:mm')
}

interface SessionInformationProps {
    session: SessionModel
    setLoading?: Function
    applyCallback?: Function
    closeCallback?: Function
    assignedStaffCallback?: Function
    removedStaffCallback?: Function
    showOpenInNewWindow?: boolean
    sessionDeletedCallback?: Function
    showPractice?: Function
}

const SessionInformation: FunctionComponent<SessionInformationProps> = (
    props: SessionInformationProps
) => {
    const theme = useContext(ThemeContext)

    const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false)
    const [showWithdraw, setShowWithdraw] = useState<boolean>(false)
    const [applying, setApplying] = useState<boolean>(false)
    const [removingAssignee, setRemovingAssignee] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [assigningStaff, setAssigningStaff] = useState<StaffModel | null>(
        null
    )
    const [editing, setEditing] = useState<boolean>(false)
    const [hidePending, setHidePending] = useState<boolean>(false)
    const { features } = useFeatures()

    const token = useToken()
    const user: StaffModel = JSON.parse(sessionStorage.getItem('user'))

    const assignStaffMember = () => {
        Mutations.assignToSession(
            props.session.id,
            assigningStaff.id,
            token
        ).then((response: any) => {
            if (response.success === true) {
                props.assignedStaffCallback(assigningStaff)
                setAssigningStaff(null)
            }
        })
    }

    const applyForSession = async () => {
        setApplying(true)
        props.setLoading(false)
        Mutations.applyToSession(
            props.session.id,
            props.session.session_date,
            props.session.start_time,
            props.session.end_time,
            props.session.location.name_value,
            token
        ).then((response) => {
            props.setLoading(false)
            setApplying(false)
            props.applyCallback()
        })
    }

    const renderApplyButton = () => {
        if (applying) {
            return <LoadingSpinner text='Applying...' />
        }
        if (
            props.session.has_applied === false ||
            isRecurringSession(props.session)
        ) {
            return (
                <div className='applyButtonContainer'>
                    <ColoredButton
                        emphasis={ColoredButtonEmphasis.HIGH}
                        text='Apply'
                        backgroundColor={theme.colors.secondary}
                        color='white'
                        onClick={applyForSession}
                    />
                </div>
            )
        }
    }

    const removeStaffFromSession = async () => {
        setRemovingAssignee(true)
        Mutations.removeFromSession(
            props.session.id,
            props.session.staff.id,
            token
        ).then((response: any) => {
            if (response.success !== true) {
                alert('Failed to remove from session')
            }
            props.removedStaffCallback()
            setRemovingAssignee(false)
        })
    }

    const renderBottomSection = () => {
        switch (props.session.session_status) {
            case 'no_applicants':
                return (
                    <AssignStaffSection
                        select={(staff) => setAssigningStaff(staff)}
                        setHidePending={(show) => setHidePending(show)}
                    />
                )
            case 'pending':
                if (features.canAssignSessions === true) {
                    return (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                width: '100%',
                                overflow: 'scroll',
                            }}>
                            <div
                                style={{
                                    display:
                                        hidePending === true ? 'none' : 'block',
                                }}>
                                <PendingStaff
                                    applicants={props.session.applicants || []}
                                    sessionId={props.session.id}
                                    assignedStaffCallback={(staffMember) => {
                                        props.assignedStaffCallback(staffMember)
                                    }}
                                />
                            </div>
                            <AssignStaffSection
                                select={(staff) => setAssigningStaff(staff)}
                                setHidePending={(show) => setHidePending(show)}
                            />
                        </div>
                    )
                } else {
                    return (
                        <div>
                            Once someone has been assigned to the shift they
                            will appear here
                        </div>
                    )
                }
            case 'filled':
                if (removingAssignee) {
                    return <LoadingSpinner text='Removing Assignee' />
                }
                return (
                    <div className='session_staff_info'>
                        <StaffInformation
                            staff={props.session.staff}
                            openCallback={() => {
                                const newLocation = `/staff?id=${
                                    props.session.staff.id.split('USER#')[1]
                                }`
                                window.open(newLocation)
                            }}
                            removeCallback={() => {
                                removeStaffFromSession()
                            }}
                            showPromote={false}
                        />
                    </div>
                )
        }
    }

    const sessionStatus = () => {
        if (props.session) {
            switch (props.session.session_status) {
                case 'no_applicants':
                    return 'sessionInfo noApps'
                case 'pending':
                    return 'sessionInfo pend'
                case 'filled':
                    return 'sessionInfo fill'
            }
        }
    }

    const withdrawFromSession = () => {
        setShowWithdraw(false)
        setLoading(true)
        Mutations.withdrawFromSession(props.session.id, token).then(
            (response) => {
                setLoading(false)
                window.location.reload()
            }
        )
    }

    const openInNewPage = () => {
        const newLocation = `/session?id=${idWithoutHash(props.session.id)}`
        window.open(newLocation)
    }

    const renderOpenInNewPageButton = () => {
        if (props.showOpenInNewWindow === null) {
            return (
                <img
                    src={OpenNewImage}
                    alt='open-in-new-page button'
                    className='closeButton'
                    onClick={() => {
                        openInNewPage()
                    }}
                />
            )
        } else if (props.showOpenInNewWindow === true) {
            return (
                <img
                    src={OpenNewImage}
                    alt='open-in-new-page button'
                    className='closeButton'
                    onClick={() => {
                        openInNewPage()
                    }}
                />
            )
        }
    }

    const renderCloseAndOpenButtons = () => {
        if (props.closeCallback !== undefined) {
            return (
                <div className='closeAndOpenButtonContainer'>
                    {renderOpenInNewPageButton()}

                    <img
                        src={CloseImage}
                        alt='close button'
                        className='closeButton'
                        onClick={() => {
                            props.closeCallback()
                        }}
                    />
                </div>
            )
        }
    }

    if (props.session) {
        return (
            <div className='sessionContainer'>
                <div className={sessionStatus()}>
                    {/* <div className="jobNumber">Session #{props.session.id}</div> */}
                </div>
                {editing === true && (
                    <SessionInformationHeaderEdit
                        session={props.session}
                        closeCallback={props.closeCallback}
                        setShowConfirmDelete={setShowConfirmDelete}
                        setShowWithdraw={setShowWithdraw}
                        user={user}
                        showOpenInNewWindow={props.showOpenInNewWindow}
                        token={token}
                        setLoading={setLoading}
                        staffTypeUpdated={(newStaffType) => {
                            window.location.reload()
                        }}
                    />
                )}
                {editing === false && (
                    <div className='session-header-container'>
                        <div className='session-info-header'>
                            <div className='dateContainer'>
                                <div
                                    className='session-info-practice-name'
                                    style={{
                                        color: theme.colors.text,
                                        textDecorationColor: theme.colors.text,
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}>
                                    <Tooltip
                                        title='This session is part of a Recurrence'
                                        style={{ marginRight: '8px' }}>
                                        <RepeatRounded />
                                    </Tooltip>
                                    {props.session.name_value !== '' && (
                                        <span>
                                            {props.session.name_value} @{' '}
                                        </span>
                                    )}
                                    {props.session.name_value === '' && (
                                        <span>
                                            {new Date(
                                                props.session.session_date
                                            ).toDateString()}{' '}
                                            @{' '}
                                        </span>
                                    )}
                                    {props.session.location.name_value}
                                </div>
                                <div>
                                    {new Date(
                                        props.session.session_date
                                    ).toDateString()}
                                </div>
                                <div>
                                    {getTimeString(props.session.start_time)} -{' '}
                                    {getTimeString(props.session.end_time)}
                                </div>
                            </div>
                            {renderCloseAndOpenButtons()}
                        </div>
                        <div className='sessionBottom'>
                            <div className='sessionBottomLeft'>
                                <div
                                    className='session-info-colored-text'
                                    style={{ color: theme.colors.text }}>
                                    Staff Type: {props.session.staff_type}
                                </div>
                                {JSON.parse(
                                    window.sessionStorage.getItem('user')
                                ).staff_type === 'ADMIN' && (
                                    <div className='session-info-colored-text'>
                                        Rate: £{props.session.pricePerHour}
                                    </div>
                                )}
                                <div
                                    className='session-info-colored-text'
                                    style={{ color: theme.colors.text }}>
                                    Break: {props.session.break} minutes break
                                </div>
                            </div>
                            <div className='sessionBottomRight'>
                                {user.staff_type === 'CREATOR' && (
                                    <ColoredButton
                                        emphasis={ColoredButtonEmphasis.LOW}
                                        color='black'
                                        backgroundColor='#01ff22'
                                        text='Edit'
                                        onClick={() => {
                                            setEditing(true)
                                        }}
                                    />
                                )}
                                {user.staff_type === 'CREATOR' && (
                                    <ColoredButton
                                        emphasis={ColoredButtonEmphasis.LOW}
                                        color='black'
                                        backgroundColor='#01ff22'
                                        text='Delete'
                                        onClick={() => {
                                            setShowConfirmDelete(true)
                                        }}
                                    />
                                )}

                                {((props.session.has_applied &&
                                    props.session.session_status !==
                                        'filled') ||
                                    (props.session.is_assigned &&
                                        !moment(
                                            props.session.session_date
                                        ).isBefore(moment.now()))) && (
                                    <ColoredButton
                                        emphasis={ColoredButtonEmphasis.LOW}
                                        color='black'
                                        backgroundColor='#01ff22'
                                        text='Withdraw'
                                        onClick={() => setShowWithdraw(true)}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                )}
                {features.canAssignSessions === true
                    ? renderBottomSection()
                    : ''}
                {user.staff_type !== 'CREATOR' &&
                features.findSessions === true &&
                props.session.session_status !== 'filled'
                    ? renderApplyButton()
                    : ''}
                <ConfirmDeleteModal
                    show={showConfirmDelete}
                    closeModal={() => setShowConfirmDelete(false)}
                    deleteType={ConfirmDeleteType.SESSION}
                    confirmAction={() => {
                        setLoading(true)
                        Mutations.deleteSession(props.session.id, token).then(
                            (response: any) => {
                                setLoading(false)
                                if (response.success === true) {
                                    if (props.sessionDeletedCallback) {
                                        props.sessionDeletedCallback()
                                    } else {
                                        window.location.reload()
                                    }
                                } else {
                                    alert('Deleting session failed')
                                    setShowConfirmDelete(false)
                                }
                            }
                        )
                    }}
                />
                <GeneralConfirmModal
                    title='Are you sure you want to withdraw from this session?'
                    show={showWithdraw}
                    closeModal={() => setShowWithdraw(false)}
                    confirmAction={() => {
                        withdrawFromSession()
                    }}
                />
                {assigningStaff !== null && (
                    <GeneralConfirmModal
                        title={`Are you sure you want to assign this session to ${assigningStaff.name_value}?`}
                        show={assigningStaff !== null}
                        closeModal={() => setAssigningStaff(null)}
                        confirmAction={() => assignStaffMember()}
                    />
                )}

                {loading && <LoadingSpinner />}
            </div>
        )
    } else {
        return <div></div>
    }
}

export default SessionInformation

interface SessionInformationHeaderEditProps {
    session: SessionModel
    showOpenInNewWindow: boolean
    closeCallback: Function
    user: StaffModel
    setShowConfirmDelete: Function
    setShowWithdraw: Function
    token: string
    setLoading: Function
    staffTypeUpdated: Function
}

const SessionInformationHeaderEdit: FunctionComponent<
    SessionInformationHeaderEditProps
> = (props: SessionInformationHeaderEditProps) => {
    const [newStaffType] = useState<string | null>(null)

    const saveStaffType = () => {
        props.setLoading(true)
        client
            .mutate({
                mutation: updateSessionStaffTypeMutation,
                variables: {
                    sessionId: props.session.id,
                    staffType: newStaffType,
                },
                context: {
                    headers: {
                        Authorization: props.token,
                    },
                },
            })
            .then((response) => {
                if (response.data.updateSessionStaffType.success === false) {
                    alert('Failed to update staff type')
                }
                props.setLoading(false)
                props.staffTypeUpdated(newStaffType)
            })
    }

    const openInNewPage = () => {
        const newLocation = `/session?id=${idWithoutHash(props.session.id)}`
        window.open(newLocation)
    }

    const renderOpenInNewPageButton = () => {
        if (props.showOpenInNewWindow === null) {
            return (
                <img
                    src={OpenNewImage}
                    className='closeButton'
                    onClick={() => {
                        openInNewPage()
                    }}
                />
            )
        } else if (props.showOpenInNewWindow === true) {
            return (
                <img
                    src={OpenNewImage}
                    className='closeButton'
                    onClick={() => {
                        openInNewPage()
                    }}
                />
            )
        }
    }

    const renderCloseAndOpenButtons = () => {
        if (props.closeCallback !== undefined) {
            return (
                <div className='closeAndOpenButtonContainer'>
                    {renderOpenInNewPageButton()}

                    <img
                        src={CloseImage}
                        className='closeButton'
                        onClick={() => {
                            props.closeCallback()
                        }}
                    />
                </div>
            )
        }
    }
    return (
        <div className='session-header-container'>
            <div className='session-info-header'>
                <div className='dateContainer'>
                    <div className='session-info-practice-name'>
                        {props.session.location.name_value}
                    </div>
                    <div>
                        {new Date(props.session.session_date).toDateString()}
                    </div>
                    <div>
                        {getTimeString(props.session.start_time)} -{' '}
                        {getTimeString(props.session.end_time)}
                    </div>
                </div>
                {renderCloseAndOpenButtons()}
            </div>
            <div className='sessionBottom'>
                <div className='sessionBottomLeft'>
                    {/* <div className='session-info-colored-text'>Staff Type:
                        <select defaultValue={props.session.staff_type} className="dropdown" onChange={(event) => setNewStaffType(event.target.value)}>
                            {StaffTypes.map((type) => {
                                return <option key={type}>{type}</option>
                            })}
                        </select>
                    </div> */}
                    {JSON.parse(window.sessionStorage.getItem('user'))
                        .staff_type === 'ADMIN' && (
                        <div className='session-info-colored-text'>
                            Rate: £{props.session.pricePerHour}
                        </div>
                    )}
                    <div className='session-info-colored-text'>
                        Break: {props.session.break} minutes break
                    </div>
                </div>
                <div className='sessionBottomRight'>
                    {props.user.staff_type === 'ADMIN' &&
                        newStaffType !== null && (
                            <ColoredButton
                                emphasis={ColoredButtonEmphasis.HIGH}
                                color='white'
                                backgroundColor='#692068'
                                text='Save'
                                onClick={() => {
                                    saveStaffType()
                                }}
                            />
                        )}
                    {props.user.staff_type === 'ADMIN' && (
                        <ColoredButton
                            emphasis={ColoredButtonEmphasis.LOW}
                            color='black'
                            backgroundColor='#01ff22'
                            text='Delete'
                            onClick={() => {
                                props.setShowConfirmDelete(true)
                            }}
                        />
                    )}

                    {(props.session.has_applied ||
                        (props.session.is_assigned &&
                            !moment(props.session.session_date).isBefore(
                                moment.now()
                            ))) && (
                        <ColoredButton
                            emphasis={ColoredButtonEmphasis.LOW}
                            color='black'
                            backgroundColor='#01ff22'
                            text='Withdraw'
                            onClick={() => props.setShowWithdraw(true)}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}
