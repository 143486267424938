import { useAuth0 } from '@auth0/auth0-react'
import { CloseOutlined } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { ThemeContext } from '../../App'
import { SessionModel } from '../../libary/Models'
import LoadingSpinner from '../loadingSpinner'
import SessionCard from '../session-card'
import { getSessions } from './queries'
import { DayInfoProps, ListContainer } from './styles'
import './styles.css'

const DayInfo = (props: DayInfoProps) => {
    const { getAccessTokenSilently } = useAuth0()
    const theme = useContext(ThemeContext)
    const [sessions, setSessions] = useState<SessionModel[] | null>(null)

    useEffect(() => {
        let variables: any = {
            date: moment(props.date).format('YYYY-MM-DD'),
            filterFilled: props.filterFilled ?? false,
            filterPending: props.filterPending ?? false,
            staffType: props.staff_type,
        }

        if (props.EMPLOYER_ID) {
            variables.employerId = props.EMPLOYER_ID
        } else if (props.LOCATION_ID) {
            variables.locationId = props.LOCATION_ID
        } else if (props.LOCALITY_ID) {
            variables.localityId = props.LOCALITY_ID
        }
        getAccessTokenSilently().then(async (token) => {
            const sessionsResult = await getSessions(variables, token)

            if (sessionsResult.isOk) {
                setSessions(sessionsResult.value)
            }
        })
    }, [
        props.date,
        props.LOCALITY_ID,
        props.EMPLOYER_ID,
        props.LOCATION_ID,
        props.reload,
    ])

    const cardClicked = (cardId: string, sessionModel?: SessionModel) => {
        props.cardClicked(cardId, sessionModel)
    }

    const renderSessions = () => {
        var pending = []
        var filled = []
        var no_applicants = []
        var hidden = []
        var cards = []
        sessions.forEach((session: SessionModel) => {
            var card = (
                <SessionCard
                    model={session}
                    key={session.id}
                    cardClicked={(cardId: string) => {
                        cardClicked(cardId, session)
                    }}
                    hideSessionStatus={props.hideSessionStatus}
                />
            )
            if (props.hideSessionStatus && props.hideSessionStatus === true) {
                cards.push(card)
            } else {
                switch (session.session_status) {
                    case 'no_applicants':
                        no_applicants.push(card)
                        break
                    case 'pending':
                        pending.push(card)
                        break
                    case 'filled':
                        filled.push(card)
                        break
                    case 'hidden':
                        hidden.push(card)
                        break
                }
            }
        })
        cards.push(no_applicants, pending, filled, hidden)
        return cards
    }

    const renderNoSessionsText = () => {
        if (sessions !== null) {
            if (sessions.length === 0) {
                return <div className='noSessionsText'>No sessions</div>
            }
        }
    }
    return (
        <div className='day-info-container' style={{ width: props.width }}>
            <div
                className='day-info-header'
                style={{
                    backgroundColor: theme.colors.brand,
                    color: theme.colors.lightText,
                    display: 'flex',
                    justifyContent: 'space-between',
                }}>
                <div></div>
                <span style={{ justifySelf: 'center' }}>
                    {moment(props.date).format(
                        props.shortDate
                            ? props.shortDate === true
                                ? 'DD-MM-YYYY'
                                : 'dddd Do MMMM'
                            : 'dddd Do MMMM'
                    )}
                </span>
                {props.close && (
                    <IconButton
                        onClick={() => {
                            if (props.close) {
                                props.close()
                            }
                        }}>
                        <CloseOutlined />
                    </IconButton>
                )}
            </div>
            <ListContainer>
                {sessions !== null && renderSessions()}
                {renderNoSessionsText()}
                {sessions === null && <LoadingSpinner />}
            </ListContainer>
        </div>
    )
}

export default DayInfo
