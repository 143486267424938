import { ActionInterface, SiteState } from '../interfaces'
import { SelectedTab } from '../../Models';

const defaultState: SiteState = { 
    tab: SelectedTab.Rota,
    modal: {
        visible: false,
        type: "session", 
        id: '1',
    }, 
    documents: [],
    practices: [],
    qualifications: [],
    itSystems: []
};

function siteState(state = defaultState, action: ActionInterface) {
    var tab = state.tab
    var modal = state.modal
    var practices = state.practices
    var documents = state.documents
    var qualifications = state.qualifications
    var itSystems = state.itSystems
    
    switch (action.type) {
        case "SET_ACTIVE_TAB":
            tab = action.payload.tab
            return { ...state, tab };
        case "SET_MODAL":
            modal = action.payload.modal
            return { ...state, modal};
        case "SET_PRACTICES":
            practices = action.payload.practices
            return { ...state, practices};
        case "SET_DOCUMENTS":
            documents = action.payload.documents
            return { ...state, documents};
        case "SET_QUALIFICATIONS":
            qualifications = action.payload.qualifications
            return { ...state, qualifications}
        case "SET_IT_SYSTEMS":
            itSystems = action.payload.itSystems
            return { ...state, itSystems}
        default:
            return state;
    }
}


export default siteState;