import { Button, Grid } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import {
    EmployerModel,
    ItSystemModelNew,
    LocalityModel,
    LocationModel,
    StaffTypeModel,
} from '../../../../../libary/Models'
import MuiDropdown from '../../../../MuiWrappers/MuiDropdown/MuiDropdown'
import { CreateSessionInput } from '../../mutations'
import {
    getEmployers,
    getItSystems,
    getLocalities,
    getLocations,
    getStaffTypes,
} from './queries'

interface SessionLocationInformation {
    locality: LocalityModel
    location: LocationModel
    employer: EmployerModel
    staffType: StaffTypeModel
    itSystem: ItSystemModelNew
}

interface CreateSessionLocationProps {
    createSessionInput: CreateSessionInput
    goBack: () => void
    sessionLocationInfoUpdated: (info: SessionLocationInformation) => void
    token: string
}

interface CreateSessionLocationErrors {
    locality: string
    location: string
    employer: string
    staffType: string
    itSystem: string
}

const EmptyCreateSessionLocationErrors: CreateSessionLocationErrors = {
    locality: '',
    location: '',
    employer: '',
    staffType: '',
    itSystem: '',
}

const CreateSessionLocation: FC<CreateSessionLocationProps> = (
    props: CreateSessionLocationProps
) => {
    const [localities, setLocalitities] = useState<LocalityModel[]>(undefined)
    const [locations, setLocations] = useState<LocationModel[]>(undefined)
    const [employers, setEmployers] = useState<EmployerModel[]>(undefined)
    const [staffTypes, setStaffTypes] = useState<StaffTypeModel[]>(undefined)
    const [itSystems, setItSystems] = useState<ItSystemModelNew[]>(undefined)

    const [locality, setLocality] = useState<LocalityModel>(undefined)
    const [location, setLocation] = useState<LocationModel>(undefined)
    const [employer, setEmployer] = useState<EmployerModel>(undefined)
    const [staffType, setStaffType] = useState<StaffTypeModel>(undefined)
    const [itSystem, setItSystem] = useState<ItSystemModelNew>(undefined)

    const [errors, setErrors] = useState<CreateSessionLocationErrors>(
        EmptyCreateSessionLocationErrors
    )
    useEffect(() => {
        ;(async () => {
            const localitiesResult = await getLocalities(props.token)
            const staffTypesResult = await getStaffTypes(props.token)
            const itSystemsResult = await getItSystems(props.token)
            if (
                localitiesResult.isOk &&
                staffTypesResult.isOk &&
                itSystemsResult.isOk
            ) {
                setLocalitities(localitiesResult.value)
                setStaffTypes(staffTypesResult.value)
                setItSystems(itSystemsResult.value)
                if (props.createSessionInput.LOCALITY_ID !== '-1') {
                    setLocality(
                        localitiesResult.value.filter(
                            (item) =>
                                item.id === props.createSessionInput.LOCALITY_ID
                        )[0]
                    )
                }
                if (props.createSessionInput.staff_type !== '-1') {
                    setStaffType(
                        staffTypesResult.value.filter(
                            (item) =>
                                item.name_value ===
                                props.createSessionInput.staff_type
                        )[0]
                    )
                }
                if (props.createSessionInput.it_system_id !== '-1') {
                    setItSystem(
                        itSystemsResult.value.filter(
                            (item) =>
                                item.id ===
                                props.createSessionInput.it_system_id
                        )[0]
                    )
                }
            }
        })()
    }, [])

    useEffect(() => {
        if (locality === undefined) {
            return
        }
        ;(async () => {
            const locationsResult = await getLocations(locality.id, props.token)
            const employersResult = await getEmployers(locality.id, props.token)
            if (locationsResult.isOk && employersResult.isOk) {
                setLocations(locationsResult.value)
                setEmployers(employersResult.value)
                if (props.createSessionInput.LOCATION_ID) {
                    setLocation(
                        locationsResult.value.filter(
                            (item) =>
                                item.id === props.createSessionInput.LOCATION_ID
                        )[0]
                    )
                }
                if (props.createSessionInput.EMPLOYER_ID) {
                    setEmployer(
                        employersResult.value.filter(
                            (item) =>
                                item.id === props.createSessionInput.EMPLOYER_ID
                        )[0]
                    )
                }
            }
        })()
    }, [locality])

    const validateInputs = () => {
        let valid = true
        const newErrors = { ...EmptyCreateSessionLocationErrors }
        if (locality === undefined) {
            newErrors.locality = 'Locality must be set'
            valid = false
        }
        if (location === undefined) {
            newErrors.location = 'Location must be set'
            valid = false
        }
        if (employer === undefined) {
            newErrors.employer = 'Employer must be set'
            valid = false
        }
        if (staffType === undefined) {
            newErrors.staffType = 'Staff type must be set'
            valid = false
        }
        if (itSystem === undefined) {
            newErrors.itSystem = 'IT System type must be set'
            valid = false
        }
        setErrors(newErrors)
        return valid
    }

    return (
        <form noValidate>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <MuiDropdown
                        label='Locality'
                        placeholder='Select a Locality'
                        defaultValue={props.createSessionInput.LOCALITY_ID}
                        error={errors.locality}
                        items={localities}
                        onChange={(value) => {
                            setLocality(
                                localities.filter(
                                    (item) => item.id === value
                                )[0]
                            )
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <MuiDropdown
                        label='Location'
                        placeholder='Select a Locality First'
                        defaultValue={props.createSessionInput.LOCATION_ID}
                        error={errors.location}
                        items={locations}
                        onChange={(value) => {
                            setLocation(
                                locations.filter((item) => item.id === value)[0]
                            )
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <MuiDropdown
                        label='Employer'
                        placeholder='Select a Locality First'
                        defaultValue={props.createSessionInput.EMPLOYER_ID}
                        error={errors.employer}
                        items={employers}
                        onChange={(value) => {
                            setEmployer(
                                employers.filter((item) => item.id === value)[0]
                            )
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <MuiDropdown
                        label='Staff Type'
                        placeholder=''
                        defaultValue={
                            staffTypes
                                ? staffTypes.filter(
                                      (type) =>
                                          type.name_value ===
                                          props.createSessionInput.staff_type
                                  )[0]?.id
                                : '-1'
                        }
                        error={errors.staffType}
                        items={staffTypes}
                        onChange={(value) => {
                            setStaffType(
                                staffTypes.filter(
                                    (item) => item.id === value
                                )[0]
                            )
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <MuiDropdown
                        label='It System'
                        placeholder=''
                        defaultValue={props.createSessionInput.it_system_id}
                        error={errors.itSystem}
                        items={itSystems}
                        onChange={(value) => {
                            setItSystem(
                                itSystems.filter((item) => item.id === value)[0]
                            )
                        }}
                    />
                </Grid>
                <Grid item xs={6} marginTop={2}>
                    <Button
                        fullWidth
                        variant='outlined'
                        onClick={() => props.goBack()}>
                        Cancel
                    </Button>
                </Grid>
                <Grid item xs={6} marginTop={2}>
                    <Button
                        fullWidth
                        variant='contained'
                        onClick={() => {
                            if (validateInputs()) {
                                props.sessionLocationInfoUpdated({
                                    locality,
                                    location,
                                    employer,
                                    staffType,
                                    itSystem,
                                })
                            }
                        }}>
                        Continue
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}

export default CreateSessionLocation
