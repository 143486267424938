import { FunctionComponent, useEffect, useRef } from 'react'
import loadingSpinner from '../loadingSpinner'

import './styles.css'
export interface NewModalProps {
    show: boolean
    children
    padding?: string
    close?: Function
    loading?: boolean
    borderRadius?: string
    backgroundColor?: string
    zIndex?: number
}
const NewModal: FunctionComponent<NewModalProps> = (props: NewModalProps) => {
    const node = useRef(null)

    useEffect(() => {
        document.addEventListener('mousedown', handleClick, false)
        return () => {
            document.removeEventListener('mousedown', handleClick, false)
        }
    }, [])

    const close = () => {
        if (props.show === true) {
            props.close()
        }
    }

    const handleClick = (e) => {
        if (node.current !== null && node.current.contains(e.target)) {
            return
        }
        close()
    }

    const showHideClassName = props.show ? 'modal show' : 'modal hide'
    return (
        <div className={showHideClassName} style={{ zIndex: props.zIndex }}>
            <div
                style={{
                    padding: props.padding,
                    borderRadius: props.borderRadius,
                    backgroundColor: props.backgroundColor,
                }}
                className='modalContainer'
                ref={node}>
                {props.children}
            </div>

            {props.loading !== undefined &&
                props.loading === true &&
                loadingSpinner({})}
        </div>
    )
}

export { NewModal }
