import React, { FunctionComponent } from 'react'
import { ItSystemModel } from '../../libary/Models'
import SystemIcon from '../../assets/icons/ic_computer.svg'

import './styles.css'

interface StaffSystemItemProps {
    item: ItSystemModel
    selected: boolean
    onClick
}

const StaffSystemItem: FunctionComponent<StaffSystemItemProps> = (props: StaffSystemItemProps) => {
    return (
        <div onClick={props.onClick} className={props.selected ? 'add-system-item-container selected' : 'add-system-item-container'} key={props.item.id}>
            <img className='add-system-item-image' src={SystemIcon} />
            <div className='add-system-item-text'>{props.item.name_value}</div>
        </div>
    )
}

export default StaffSystemItem