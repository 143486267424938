import React, { FunctionComponent, useEffect, useState } from 'react'

import { LocationListProps } from './types'

import './styles.css'
import { LocalityModel, LocationModel } from '../../libary/Models'
import PracticeListCard from './practice-list-card'
import { gql } from '@apollo/client'

import './styles.css'
import client from '../../libary/apollo'
import { useAuth0 } from '@auth0/auth0-react'
import PracticeListAddButton from './practice-list-add-button'

const query = gql`
        query getLocations($locality_id: String!){
            getLocations(locality_id: $locality_id){
              id
              locality_id
              name_value
            }
          }

  `

  const getLocalitiesQuery = gql`
  query getLocalities {
    getLocalities {
      id
      name_value
    }
  }
  `

const LocationList: FunctionComponent<LocationListProps> = (props: LocationListProps) => {
    const { getAccessTokenSilently } = useAuth0()
    const [locations, setLocations] = useState<LocationModel[]|null>(null)
    const [localities, setLocalities] = useState<LocalityModel[]|null>(null)
    const [locality, setLocality] = useState<LocalityModel|null>(null)

    useEffect(() => {
        (async () => {
            const token = await getAccessTokenSilently();
            client.query({
                query: getLocalitiesQuery,
                context: {
                    headers: {
                        authorization: token
                    }
                }
            }).then((response) => {
                setLocalities(response.data.getLocalities)
            })
        })()
    }, [getAccessTokenSilently])

    useEffect(() => {
        if(localities === null) { return }
        if(localities.length === 1) {
            setLocality(localities[0])
        }
    }, [localities])

    useEffect(() => {
        if(locality === null) { return }
        (async () => {
            const token = await getAccessTokenSilently();
            client.query({
                query: query,
                variables: { locality_id: locality.id },
                context: {
                    headers: {
                        authorization: token
                    }
                }
            }).then((response) => {
                setLocations(response.data.getLocations)
            })
        })();
      }, [locality]);

    const addPractice = () => {
        if(props.createPractice) {
            props.createPractice(locality.id)
        }
    }
    
    const renderLocations = () => {
        const mapped = locations.map((location) => {
            return <PracticeListCard practice={location} select={(locationModel) => { 
                props.select(location) }} 
                key={location.id}/>
        })
        
        mapped.push(<PracticeListAddButton select={addPractice} key="practice-list-add-button"/>)
        return mapped
    }
    console.log(localities)
        return (
            <div className="practiceListContainer" style={{ display: 'flex', flexDirection: 'column' }}>
                {localities !== null && (
                    <div>
                    <label>Select A Locality</label>
                    <select value={locality === null ? "Select a Locality" : undefined} className='dropdown' onChange={(event) => {
                        setLocality(localities[event.target.selectedIndex - 1])}
                    }>
                        <option disabled>Select a Locality</option>
                        {localities.map((loc) => <option key={loc.id} value={loc.name_value}>{loc.name_value}</option>)}
                    </select>
                    </div>
                )}
                {locations !== null && renderLocations()}
            </div>
        )
}

export default LocationList