import { gql } from '@apollo/client'
import { Result } from 'true-myth'
import query from '../../../libary/network/apollo'

interface SessionHiddenFields {
    lengthOfAppointments: boolean
    paymentProcess: boolean
}

export interface SessionConfig {
    hiddenFields: SessionHiddenFields
}

const getSessionConfigQuery = gql`
    query GetSessionConfig {
        sessionConfig {
            hiddenFields {
                lengthOfAppointments
                paymentProcess
            }
        }
    }
`

const getSessionConfig = async (
    token: string
): Promise<Result<SessionConfig, Error>> => {
    return query(getSessionConfigQuery, token, 'sessionConfig')
}

export { getSessionConfig }
