import React, { FunctionComponent, useState } from 'react'
import { ItSystemModel } from '../../../libary/Models'
import { NewModal } from '../../NewModal'

import '../styles.css'
import StaffSystemItem from '../../StaffSystemItem'
import Mutations from '../../../libary/apollo/mutations'
import { useCookies } from 'react-cookie'

interface AddSystemModalProps {
    show: boolean
    systems: ItSystemModel[]
    closeModal: Function
    systemAdded: Function
}

const AddSystemModal: FunctionComponent<AddSystemModalProps> = (props: AddSystemModalProps) => {
    const [selectedSystem, setSelectedSystem] = useState<ItSystemModel|null>(null)
    const cookies = useCookies()[0]
    const renderSystems = () => {
        return props.systems.map((item) => {
            return (
                <StaffSystemItem item={item} onClick={() => setSelectedSystem(item)} selected={selectedSystem !== null && selectedSystem.id === item.id} key={item.id}/>
            )
        })
    }

    const addSystem = () => {
        Mutations.addSystem(selectedSystem.id, selectedSystem.name_value, cookies['token'])
        .then((response) => {
            if (response.success === true) {
                props.closeModal()
                props.systemAdded(selectedSystem)
            } else {
                alert("Failed to add system")
            }
        })
    }

    return (
        <NewModal show={props.show} close={props.closeModal}>
                <div className='modal-add-container'>
                    <p className='modal-add-title'>Add A System</p>
                    <div className='modal-add-list-container'>
                        {renderSystems()}
                    </div>
                    <div className={selectedSystem === null ? 'modal-add-button disabled' : 'modal-add-button'}
                    onClick={() => {addSystem()}}>
                        Add
                        </div>
                </div>
        </NewModal>
    )
}

export default AddSystemModal