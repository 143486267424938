import React, { FunctionComponent, useState } from 'react'
import ColoredButton, { ColoredButtonEmphasis } from '../../components/colored-button'
import './styles.css'
import Text from '../../components/text'
import PracticeMutations from '../../libary/apollo/Mutations/PracticeMutations'
import { LocationModel } from '../../libary/Models'
import useToken from '../../libary/hooks/useToken'
import client from '../../libary/apollo'
import { createPracticeMutation } from './mutations'


interface PracticeInformationProps {
    location: LocationModel
    savePressedCallback?: Function
    onSaveCallback?: Function
    createPractice?: boolean
}

const PracticeInformation: FunctionComponent<PracticeInformationProps> = (props: PracticeInformationProps) => {


    const token = useToken()
    const [practiceName, setPracticeName] = useState<string>(props.location.name_value)

    var name
    var location_code
    var phoneNumber
    var mobileNumber
    var about
    var addressLine1
    var addressLine2
    var addressLine3
    var town
    var postcode
    var travelInfo
    var parking
    var contact_name
    var contact_email
    var contact_telephone

    const createPractice = async () => {
        console.log("LOCATION::", props.location )
        if (props.savePressedCallback !== undefined) {
            props.savePressedCallback()
        }

        client.mutate({
            mutation: createPracticeMutation,
            variables: {
                name_value: name.value,
                location_code: location_code.value,
                address_line1: addressLine1.value,
                address_line2: addressLine2.value,
                address_line3: addressLine3.value,
                town: town.value,
                postcode: postcode.value,
                travel_info: travelInfo.value,
                parking: parking.value,
                about: about.value,
                mobile_number: mobileNumber.value,
                phone_number: phoneNumber.value,
                contact_name: contact_name.value,
                contact_email: contact_email.value,
                contact_telephone: contact_telephone.value,
                locality_id: props.location.locality_id, 
                location_type: props.location.location_type
            },
            context: {
                headers: {
                    Authorization: token
                }
            }
        }).then((result) => {
            if (props.onSaveCallback !== undefined) {
                props.onSaveCallback()
            }
        })
    }

    const savePracticeInfo = async () => {
        if (props.savePressedCallback !== undefined) {
            props.savePressedCallback()
        }
        PracticeMutations.updatePractice(
            {
                id: props.location.id,
                locality_id: props.location.locality_id,
                name_value: name.value,
                location_code: location_code.value,
                address_line1: addressLine1.value,
                address_line2: addressLine2.value,
                address_line3: addressLine3.value,
                town: town.value,
                postcode: postcode.value,
                travel_info: travelInfo.value,
                parking: parking.value,
                about: about.value,
                mobile_number: mobileNumber.value,
                phone_number: phoneNumber.value,
                contact_name: contact_name.value,
                contact_email: contact_email.value,
                contact_telephone: contact_telephone.value,
                can_edit: props.location.can_edit,
            }, token)
            .then((result) => {
                if (props.onSaveCallback !== undefined) {
                    props.onSaveCallback()
                }
            })
    }

    const buttonText = props.createPractice ? props.createPractice === true ? "Create" : "Save" : "Save"

    const bottom = (location) => {
        return (
            <div className="practiceInfo">
                <div className='practice-info-columns'>
                    <div className="column">
                        <Text type="subHeader" text="Location Information" />
                        <input className="practice-info-input" placeholder="Practice Name" defaultValue={location.name_value ? location.name_value : ""} ref={(input) => name = input} onChange={(e) => setPracticeName(e.target.value)} disabled={props.location.can_edit === false} />
                        <input className="practice-info-input" placeholder="Practice Code" defaultValue={location.location_code ? location.location_code : ""} ref={(input) => location_code = input} disabled={props.location.can_edit === false} />
                        <Text type="subHeader" text="Additional Information" />
                        <input className="practice-info-input" placeholder="Phone Number" defaultValue={location.phone_number ? location.phone_number : ""} ref={(input) => phoneNumber = input} disabled={props.location.can_edit === false} />
                        <input className="practice-info-input" placeholder="Mobile Number" defaultValue={location.mobile_number ? location.mobile_number : ""} ref={(input) => mobileNumber = input} disabled={props.location.can_edit === false} />
                        <input className="practice-info-input doubleHeight" placeholder="About us" defaultValue={location.about ? location.about : ""} ref={(input) => about = input} disabled={props.location.can_edit === false} />
                    </div>
                    <div className="column">
                        <Text type="subHeader" text="Address" />
                        <input className="practice-info-input" placeholder="Address line 1" defaultValue={location.address_line1 ? location.address_line1 : ""} ref={(input) => addressLine1 = input} disabled={props.location.can_edit === false} />
                        <input className="practice-info-input" placeholder="Address line 2" defaultValue={location.address_line2 ? location.address_line2 : ""} ref={(input) => addressLine2 = input} disabled={props.location.can_edit === false} />
                        <input className="practice-info-input" placeholder="Address line 3" defaultValue={location.address_line3 ? location.address_line3 : ""} ref={(input) => addressLine3 = input} disabled={props.location.can_edit === false} />
                        <input className="practice-info-input" placeholder="Town / City" defaultValue={location.town ? location.town : ""} ref={(input) => town = input} disabled={props.location.can_edit === false} />
                        <input className="practice-info-input" placeholder="Postcode" defaultValue={location.postcode ? location.postcode : ""} ref={(input) => postcode = input} disabled={props.location.can_edit === false} />
                        <input className="practice-info-input" placeholder="Travel Info" defaultValue={location.travel_info ? location.travel_info : ""} ref={(input) => travelInfo = input} disabled={props.location.can_edit === false} />
                        <select className="practice-info-input" placeholder="Parking" defaultValue={location.parking ? location.parking : ""} ref={(input) => parking = input} disabled={props.location.can_edit === false}>
                            <option value="No parking">No Parking</option>
                            <option value="On Site">On site</option>
                        </select>
                    </div>
                </div>
                <div className='practice-info-contact'>
                    <div className='practice-info-contact-header'>
                        <Text type="subHeader" text="Contact Information" />
                    </div>
                    <div className='practice-info-columns'>
                        <div className='column'>
                            <input className="practice-info-input" placeholder="Contact Name" defaultValue={location.contact_name ? location.contact_name : ""} ref={(input) => contact_name = input} disabled={props.location.can_edit === false} />
                            <input className="practice-info-input" placeholder="Contact Email" defaultValue={location.contact_email ? location.contact_email : ""} ref={(input) => contact_email = input} disabled={props.location.can_edit === false} />
                        </div>
                        <div className='column'>
                            <input className="practice-info-input" placeholder="Contact Phone Number" defaultValue={location.contact_telephone ? location.contact_telephone : ""} ref={(input) => contact_telephone = input} disabled={props.location.can_edit === false} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    return (
        <div>
            <div className="container">
                <div className="topBar">
                    <div className="top">
                        <div className="topLeftPractice">
                            <div className="practice_name">{practiceName}</div>
                        </div>
                        <div className="topRight">
                            View Practice Managers
                        </div>
                    </div>
                </div>
            </div>
            {bottom(props.location)}
            {props.location.can_edit === true && (
                <div className="practiceInfoButtonContainer">
                    <ColoredButton emphasis={ColoredButtonEmphasis.HIGH} color="white" backgroundColor="var(--primary-purple-color)" text={buttonText} onClick={() => {
                        if (props.createPractice !== undefined) {
                            if (props.createPractice === true) {
                                createPractice()
                            } else {
                                savePracticeInfo()
                            }
                        } else {
                            savePracticeInfo()
                        }
                    }} borderRadius={10} />
                </div>
            )}
        </div>
    )
}

export default PracticeInformation;