import { FunctionComponent, useContext } from "react";
import { ThemeContext } from "../../../../App";
import LoadingSpinner from "../../../../components/loadingSpinner";
import './styles.css'

interface NumberCardProps {
    title: string
    number: number|null
    percentage?: boolean
}
const NumberCard: FunctionComponent<NumberCardProps> = (props: NumberCardProps) => {
    const theme = useContext(ThemeContext)
    const percentage = props.percentage ?? false

    return (
        <div className="number-card-container" style={{ borderColor: theme.colors.brand }}>
            <div className="number-card-title">
                {props.title}
            </div>
            {props.number == null && (
                <LoadingSpinner size="20"/>
            )}
            {props.number != null && (
                <div className="number-card-number" style={{ color: theme.colors.brand}}>
                {props.number}{percentage === true && '%'}
            </div>
            )}
        </div>
    )
}

export default NumberCard