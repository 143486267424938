import { PureComponent } from 'react'

import './styles.css'
import { PracticeModel } from '../../../libary/Models';

interface PracticeListCardProps {
    practice: PracticeModel
    select?: Function
}



class PracticeListCard extends PureComponent<PracticeListCardProps> {

    openPractice() {
        this.props.select(this.props.practice)
    }

    render() {
        return (
            <div className="listCardContainer" onClick={() => {this.openPractice()}}>
                <div className="listCardText">{this.props.practice.name_value}</div>
            </div>
        )
    }
}

export default PracticeListCard;