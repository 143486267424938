import FedworksTheme from "./fedworks"
import GMWorkforceTheme from "./gmWorkforce"
import Theme from "./theme"

export {
  FedworksTheme,
  GMWorkforceTheme
}

export const legacyTheme = (): Theme => {
  if (window.location.hostname.includes("fedworks")) {
    return FedworksTheme
  } else if (window.location.hostname.includes("gmworkforce")) {
    return GMWorkforceTheme
  } else {
    return GMWorkforceTheme
  }
}