import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    SxProps,
} from '@mui/material'
import { FC, useState } from 'react'

interface MuiDropdownProps {
    label: string
    placeholder?: string
    defaultValue?: string
    error: string
    required?: boolean
    items?: { id: string; name_value: string }[]
    renderText?: (item: any) => string
    onChange: (value: any) => void
    sx?: SxProps
}

const MuiDropdown: FC<MuiDropdownProps> = ({
    label,
    placeholder,
    defaultValue,
    error,
    required,
    items,
    renderText,
    onChange,
    sx,
}) => {
    const [value, setValue] = useState(defaultValue)
    return (
        <FormControl
            fullWidth
            error={error !== ''}
            required={required}
            sx={{
                width: '100%',
            }}>
            <InputLabel id={`${label}-error`}>{label}</InputLabel>
            <Select
                labelId={`${label}-error`}
                id={`${label}-error`}
                label={label}
                required={required}
                disabled={(items ?? []).length === 0}
                sx={{
                    width: '100%',
                }}
                value={!items || value === '-1' ? '' : value}
                defaultValue={items ? '' : defaultValue}
                onChange={(e) => {
                    setValue(e.target.value)
                    onChange(e.target.value)
                }}>
                {items === undefined && placeholder !== undefined && (
                    <MenuItem key={'-1'} disabled value={'-1'}>
                        {placeholder}
                    </MenuItem>
                )}
                {items &&
                    items.map((item) => {
                        return (
                            <MenuItem key={item.id} value={item.id}>
                                {renderText && renderText(item.name_value)}
                                {renderText === undefined && item.name_value}
                            </MenuItem>
                        )
                    })}
            </Select>
            <FormHelperText sx={{ marginTop: '0' }}>{error}</FormHelperText>
        </FormControl>
    )
}

export default MuiDropdown
