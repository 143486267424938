import React, { FunctionComponent, useEffect, useState } from 'react'
import './styles.css'
import { gql } from '@apollo/client'
import { DocumentModel, SelectedTab } from '../../libary/Models';
import Mutations from '../../libary/apollo/mutations';
import client from '../../libary/apollo';
import NavBarNew from '../../components/NavBarNew';
import { useAuth0 } from '@auth0/auth0-react';
import DocumentApprovalCell from './DocumentApprovalCell';
import { getDocUrlQuery } from './query';
import LoadingSpinner from '../../components/loadingSpinner';
import GeneralConfirmModal from '../../components/modals/GeneralConfirmModal';


const query = gql`
query { 
    documentsPendingApproval {
      id
      staff_id
      name_value
      staff_name
      approved
    }
  }
`

interface DocumentApprovalModel {
    documentId: string
    staffId: string
    approve: boolean
}

const DocumentApproval: FunctionComponent = () => {

    const [selectedDoc, setSelectedDoc] = useState<DocumentApprovalModel | null>(null)
    const [loading, setLoading] = useState<boolean>(true)
    // const [currentStaff, setCurrentStaff] = useState<StaffModel | null>(null)
    const [documents, setDocuments] = useState<DocumentModel[] | null>(null)
    const [token, setToken] = useState<string | null>(null)
    const { getAccessTokenSilently } = useAuth0()

    useEffect(() => {
        loadDocumentsPendingApproval()
    }, [])

    const loadDocumentsPendingApproval = () => {
        (async () => {
            const token = await getAccessTokenSilently();
            setToken(token)
            client.query({
                query,
                context: {
                    headers: {
                        authorization: token
                    }
                }
            }).then((response) => {
                setDocuments(response.data.documentsPendingApproval)
                setLoading(false)
            })
        })()
    }

    // const showModal = (s) => {
    //     store.dispatch(setModal({ visible: true }))
    //     setCurrentStaff(s.staffId)
    // }

    const viewDocument = (document: DocumentModel) => {
        client.query({
            query: getDocUrlQuery,
            variables: {
                staffId: document.staff_id,
                documentId: document.id
            },
            context: {
                headers: {
                    Authorization: token
                }
            }
        }).then((response) => {
            setLoading(false)
            window.open(response.data.getSignedUrl.url)
        })
    }

    const renderDocumentList = (docs: DocumentModel[]) => {
        var elements = []
        docs.forEach((s) => {
            elements.push(<DocumentApprovalCell doc={s} viewDocument={() => { viewDocument(s) }} approve={
                () => {
                    setSelectedDoc({ staffId: s.staff_id, documentId: s.id, approve: true })
                }
            }
                reject={
                    () => {
                        setSelectedDoc({ staffId: s.staff_id, documentId: s.id, approve: false })
                    }} key={s.id} />)
        })
        return elements
    }

    const rejectDoc = () => {
        Mutations.rejectDocument(selectedDoc.documentId, selectedDoc.staffId, token)
        .then((response) => {
            if(response.success === true) {
                const newDocuments = documents.filter((document) => document.id !== selectedDoc.documentId)
                setDocuments(newDocuments)
                setSelectedDoc(null)
            } else {
                alert("Failed to approve document")
            }
        })
    }

    const approveDoc = () => {
        Mutations.approveDocument(selectedDoc.documentId, selectedDoc.staffId, token)
        .then((response) => {
            if(response.success === true) {
                const newDocuments = documents.filter((document) => document.id !== selectedDoc.documentId)
                setDocuments(newDocuments)
                setSelectedDoc(null)
            } else {
                alert("Failed to approve document")
            }
        })
    }

    const renderConfirmModal = () => {
        if(selectedDoc.approve  === false) {
            return <GeneralConfirmModal show={selectedDoc !== null} title="Reject Document" closeModal={() => setSelectedDoc(null)} confirmAction={rejectDoc}/>
        } else {
            return <GeneralConfirmModal show={selectedDoc !== null} title="Approve Document" closeModal={() => setSelectedDoc(null)} confirmAction={approveDoc}/>
        }
    }

    return (
        <div>
            <NavBarNew setTab={() => { }} selectedTab={SelectedTab.DocumentApproval} />
            <div className="document-approval-container">
                <h2 className="document-approval-header">Documents Pending Approval</h2>
                {selectedDoc !== null && (
                    renderConfirmModal()
                )}
                {loading === true && (
                    <LoadingSpinner/>
                )}
                {(documents !== null && documents.length === 0) && <div className='document-approval-no-documents'>There are no documents pending approval</div>}
                {documents !== null && (
                    
                    <div className="documentList">
                        {renderDocumentList(documents)}
                    </div>
                )}
            </div>
        </div>
    )
}

export default DocumentApproval