import { gql } from "@apollo/client";

const acceptStaffTermsMutation = gql`
mutation { 
    acceptStaffTerms {
      success
    }
    }
`

export { 
  acceptStaffTermsMutation
}