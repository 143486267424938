/**
  @module
  @internal
*/
/**
 * Check if the value here is an all-consuming monstrosity which will consume
 * everything in its transdimensional rage. A.k.a. `null` or `undefined`.
 *
 * @internal
 */
export const isVoid = (value) => typeof value === 'undefined' || value === null;
/** @internal */
export function curry1(op, item) {
    return item !== undefined ? op(item) : op;
}
/**
 * Check whether a given key is in an object
 * @internal
 */
function has(value, key) {
    return typeof value === 'object' && value !== null && key in value;
}
export function safeToString(value) {
    if (has(value, 'toString') && typeof value['toString'] === 'function') {
        const fnResult = value.toString();
        return typeof fnResult === 'string' ? fnResult : JSON.stringify(value);
    }
    else {
        return JSON.stringify(value);
    }
}
