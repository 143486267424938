import React, { FunctionComponent, useEffect, useState } from 'react'
import './styles.css'
import { EmptyLocation, LocationModel } from '../../../libary/Models'
import { NewModal } from '../../NewModal'
import PracticeInformation from '../../../pages/practice-information'
import { locationQuery } from './queries'
import client from '../../../libary/apollo'
import { useAuth0 } from '@auth0/auth0-react'
import LoadingSpinner from '../../loadingSpinner'

interface PracticeModalProps {
    show: boolean
    locality_id: string
    location_id: string
    locationType?: string
    closeModal: Function
    practiceCallback?: Function
    createPractice?: boolean
}

const PracticeModal: FunctionComponent<PracticeModalProps> = (props: PracticeModalProps) => {
    const [location, setLocation] = useState<LocationModel | null>(null)
    const [loading, setLoading] = useState<boolean>(false)

    const { getAccessTokenSilently } = useAuth0()

    useEffect(() => {
        if(props.createPractice) {
            if(props.createPractice  === true) { 
                setLocation(EmptyLocation)
                return
            }
        }
        (async () => {
            const token = await getAccessTokenSilently();
            client.query({
                query: locationQuery,
                variables: {
                    locality_id: props.locality_id,
                    location_id: props.location_id
                },
                context: {
                    headers: {
                        authorization: token
                    }
                }
                
            }).then((response) => {
                setLocation(response.data.getLocation)
            })
        })()
    }, [props.location_id])
        return (
            <NewModal show={props.show} close={() => props.closeModal()} loading={loading}>
                {location === null && <LoadingSpinner/>}  
                {location !== null && <PracticeInformation location={{...location, locality_id: props.locality_id, location_type: props.locationType}} createPractice={props.createPractice} savePressedCallback={() => {setLoading(true)}} onSaveCallback={() => {
                    props.practiceCallback(location)
                    setLoading(false)
                    props.closeModal()
                }}/>}
            </NewModal>
        )
}

export default PracticeModal