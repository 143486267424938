import { gql } from "@apollo/client";

const createPracticeMutation = gql`
mutation createLocationMutation(
    $name_value: String
    $address_line1: String
    $address_line2: String
    $address_line3: String
    $town: String
    $postcode: String
    $location_code: String
    $travel_info: String
    $parking: String
    $phone_number: String
    $mobile_number: String
    $about: String,
    $locality_id: String!,
    $location_type: String!
  ) {
    createLocation(
      name_value: $name_value
      address_line1: $address_line1
      address_line2: $address_line2
      address_line3: $address_line3
      town: $town
      postcode: $postcode
      location_code: $location_code
      travel_info: $travel_info
      parking: $parking
      phone_number: $phone_number
      mobile_number: $mobile_number
      about: $about,
      locality_id: $locality_id,
      location_type: $location_type
    ) {
      success
      error
      __typename
    }
  }          
`

export { createPracticeMutation }