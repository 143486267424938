import React, { FunctionComponent, useContext } from 'react'
import { FooterProps } from './types'

import './styles.css'
import { ThemeContext } from '../../App'

const Footer: FunctionComponent<FooterProps> = (props: FooterProps) => {
    const theme = useContext(ThemeContext)
        return (
            <div className="footer" style={{ backgroundColor: theme.colors.brand, color: theme.colors.lightText}}>
                <a style={{color: theme.colors.lightText}} className="sign-up-footer-text" href='/gdpr'>GDPR</a>
                {/* <span className="sign-up-footer-separator">|</span>
                <a className="sign-up-footer-text">Privacy Policy</a>
                <span className="sign-up-footer-separator">|</span>
                <a className="sign-up-footer-text">Cookie Policy</a> */}
            </div>
        )
}

export default Footer