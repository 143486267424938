import React, { PureComponent } from 'react'

class About extends PureComponent<{}> {
    render() {
        return (
            <div></div>
        )
    }
}

export default About