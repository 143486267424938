import { ApolloClient, InMemoryCache } from '@apollo/client';
const { createUploadLink } = require('apollo-upload-client')



const apolloUrl = () => {
  if(window.location.hostname.includes("fedworks")) {
    return "https://4aj0g6srld.execute-api.eu-west-2.amazonaws.com/"
  } else if(window.location.hostname.includes("gmworkforce")) {
    return "https://qlojsk981f.execute-api.eu-west-2.amazonaws.com"
  } else {
    return "https://8czojkh8zh.execute-api.eu-west-2.amazonaws.com"
  }
}

const uploadLink = createUploadLink({ uri: apolloUrl() })
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: uploadLink
});

export default client;

