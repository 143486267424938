import { useAuth0 } from '@auth0/auth0-react'
import { Modal } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { SessionModel, StaffModel } from '../../../libary/Models'
import SessionView from '../../../pages/sessionInformation/new/sessionInformation'
import LoadingSpinner from '../../loadingSpinner/LoadingSpinner'
import {
    SessionConfig,
    getSessionConfig,
} from '../CreateSessionModalNew/queries'
import { getSession } from './queries'

interface SessionModalNewProps {
    show: boolean
    id: string
    sessionModel?: SessionModel
    closeModal: (sessionHasChanged: boolean) => void
    showOpenInNewWindow?: boolean
}

const SessionModalNew: FC<SessionModalNewProps> = ({
    show,
    id,
    sessionModel,
    closeModal,
    showOpenInNewWindow,
}) => {
    const { getAccessTokenSilently } = useAuth0()
    const [token, setToken] = useState<string>(undefined)
    const [sessionHasChanged, setSessionHasChanged] = useState<boolean>(false)
    const [session, setSession] = useState<SessionModel | undefined>(undefined)
    const [sessionConfig, setSessionConfig] = useState<
        SessionConfig | undefined
    >()

    useEffect(() => {
        ;(async () => {
            const newToken = await getAccessTokenSilently()
            setToken(newToken)
            const sessionConfigResult = await getSessionConfig(newToken)
            if (sessionConfigResult.isOk) {
                setSessionConfig(sessionConfigResult.value)
            }
            if (sessionModel) {
                setSession(sessionModel)
                return
            }
            const result = await getSession(id, newToken)
            if (result.isOk) {
                setSession(result.value)
            }
        })()
    }, [id])

    return (
        <Modal
            open={show}
            onClose={() => {
                closeModal(sessionHasChanged)
            }}>
            <div
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    outline: 'none',
                }}>
                {session && sessionConfig && (
                    <SessionView
                        token={token}
                        session={session}
                        sessionConfig={sessionConfig}
                        onApply={() => {
                            closeModal(true)
                        }}
                        onClose={() => {
                            closeModal(sessionHasChanged)
                        }}
                        assignedStaffCallback={(staffMember: StaffModel, new_session_id?: string) => {
                            const newSession = {
                                ...session,
                                session_status: 'filled',
                                staff: staffMember,
                            }
                            if(new_session_id) {
                                newSession.id = new_session_id
                            }
                            setSession(newSession)
                            setSessionHasChanged(true)
                        }}
                        removedStaffCallback={() => {
                            const newSession = {
                                ...session,
                                session_status:
                                    (session.applicants || []).length > 0
                                        ? 'pending'
                                        : 'no_applicants',
                                staff: null,
                            }
                            setSession(newSession)
                            setSessionHasChanged(true)
                        }}
                        updateSession={(session_updates) => {
                            setSession({ ...session, ...session_updates })
                            setSessionHasChanged(true)
                        }}
                    />
                )}
                {!session && <LoadingSpinner />}
            </div>
        </Modal>
    )
}

export default SessionModalNew
