import { useAuth0 } from '@auth0/auth0-react'
import { FunctionComponent, useEffect, useState } from 'react'
import { SessionModel } from '../../../libary/Models'
import client from '../../../libary/apollo'
import SessionInformation from '../../../pages/sessionInformation'
import { NewModal } from '../../NewModal'
import LoadingSpinner from '../../loadingSpinner'
import { sessionQuery } from './queries'
import './styles.css'

interface SessionModalProps {
    show: boolean
    id: string
    sessionModel?: SessionModel
    closeModal: Function
    showOpenInNewWindow?: boolean
}

const SessionModal: FunctionComponent<SessionModalProps> = (
    props: SessionModalProps
) => {
    const [sessionHasChanged, setSessionHasChanged] = useState<boolean>(false)
    const [session, setSession] = useState<SessionModel | null>(null)
    const { getAccessTokenSilently } = useAuth0()
    const [loading, setLoading] = useState<boolean>(false)

    const closeModal = () => {
        props.closeModal(sessionHasChanged)
    }
    useEffect(() => {
        if (props.sessionModel) {
            setSession(props.sessionModel)
            return
        }
        ;(async () => {
            const newToken = await getAccessTokenSilently()
            client
                .query({
                    query: sessionQuery,
                    variables: {
                        id: props.id,
                    },
                    context: {
                        headers: {
                            authorization: newToken,
                        },
                    },
                    fetchPolicy: 'network-only',
                })
                .then((response) => {
                    setSession(response.data.session)
                })
        })()
    }, [props.id])
    return (
        <NewModal
            show={props.show}
            borderRadius='0px'
            backgroundColor='transparent'
            close={() => closeModal()}
            loading={loading}>
            {session === null && <LoadingSpinner />}
            {session !== null && (
                <div className='session-modal-info-container'>
                    <SessionInformation
                        session={session}
                        setLoading={(value) => {
                            setLoading(value)
                        }}
                        applyCallback={() => {
                            props.closeModal(true)
                        }}
                        closeCallback={() => {
                            closeModal()
                        }}
                        assignedStaffCallback={(staffMember) => {
                            const newSession = {
                                ...session,
                                session_status: 'filled',
                                staff: staffMember,
                            }
                            console.log('NEW SESSION: ', newSession)
                            setSession(newSession)
                            setSessionHasChanged(true)
                        }}
                        removedStaffCallback={() => {
                            const newSession = {
                                ...session,
                                session_status:
                                    (session.applicants || []).length > 0
                                        ? 'pending'
                                        : 'no_applicants',
                                staff: null,
                            }
                            setSession(newSession)
                            setSessionHasChanged(true)
                        }}
                        showOpenInNewWindow={props.showOpenInNewWindow}
                    />
                </div>
            )}
        </NewModal>
    )
}

export default SessionModal
