import React, { PureComponent } from 'react';
import './styles.css'

interface TextProps {
    type: string;
    text: string;
    color?: string;
    align?: AlignSetting;
    marginLeft?: string;
    underline?: boolean;
    onClick?: Function;
    marginTop?: string;
}

class Text extends PureComponent<TextProps> {
    render() {
        var textDecoration = this.props.underline ? this.props.underline === true ? 'underline' : 'none' : 'none'
        return (
            <h1 onClick={() => this.props.onClick()}className={`text ${this.props.type}`} style={{color: this.props.color ? this.props.color : 'black', textAlign: this.props.align ? this.props.align : 'left', marginLeft: this.props.marginLeft ? this.props.marginLeft : '0px', textDecoration: textDecoration, marginTop: this.props.marginTop ? this.props.marginTop : '0px'}}>
                {this.props.text}
            </h1>
        )
    }
}

export default Text