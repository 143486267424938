import Config from "./Config";

const GMWorkforceConfig: Config = {
    auth0: {
        clientId: "jOwopEqd3Jg70OmUeKcKcklFe3Nv8c3K",
        domain: "gmworkforcebank.eu.auth0.com",
        redirectUri: process.env.NODE_ENV !== "production" ? "http://localhost:3000" : "https://gmworkforcebank.co.uk",
        audience: "https://api.gmworkforcebank.co.uk",
        useRefreshTokens: true,
        cacheLocation: "localstorage"
    }
}

export default GMWorkforceConfig