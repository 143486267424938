import { FunctionComponent, useContext } from "react"
import './styles.css'
import EditIcon from '../../../assets/icons/ic_edit_black.svg'
import LocationIcon from '../../../assets/icons/ic_location.svg'
import CreateSessionIcon from '../../../assets/icons/ic_create_session.svg'
import { ThemeContext } from "../../../App"

export enum PermissionsButtonImage {
    EDIT,
    LOCATION,
    CREATE_SESSION
}

interface PermissionsButtonProps { 
    title: string
    image: PermissionsButtonImage
    action: Function
}

const PermissionsButton: FunctionComponent<PermissionsButtonProps> = (props: PermissionsButtonProps) => {
    const theme = useContext(ThemeContext)

    const getImage = () => {
        switch (props.image) {
            case PermissionsButtonImage.EDIT: 
                return EditIcon
            case PermissionsButtonImage.LOCATION: 
                return LocationIcon
            case PermissionsButtonImage.CREATE_SESSION:
                return CreateSessionIcon
        }
    }

    return (
        <div className="permissions-button-container" style={{borderColor: theme.colors.brand}} onClick={() => props.action()} onKeyPress={() => props.action()} tabIndex={1}>
            <img src={getImage()} className="permissions-button-image"/>
            <label className="permissions-button-text">{props.title}</label>
        </div>        
    )
}

export default PermissionsButton