import { FunctionComponent, useContext, useState } from 'react'

import {
    DocumentModel,
    ItSystemModel,
    LanguagesModel,
    QualificationModel,
    StaffModel,
} from '../../libary/Models'
import './styles.css'

import { withAuth0 } from '@auth0/auth0-react'
import { Cookies, useCookies, withCookies } from 'react-cookie'
import { ThemeContext } from '../../App'
import CloseImage from '../../assets/icons/ic_close.svg'
import OpenNewImage from '../../assets/icons/ic_open_new.svg'
import ColoredButton, {
    ColoredButtonEmphasis,
} from '../../components/colored-button'
import LoadingSpinner from '../../components/loadingSpinner'
import AddQualificationModal from '../../components/modals/AddQualificationModal'
import AddSystemModal from '../../components/modals/AddSystemModal'
import AddWorkExperienceModal from '../../components/modals/AddWorkExperienceModal'
import GeneralConfirmModal from '../../components/modals/GeneralConfirmModal'
import client from '../../libary/apollo'
import Bio from './Sections/Bio'
import StaffInfoHeader from './Sections/StaffInfoHeader'
import StaffInfoQualifications from './Sections/StaffInfoQualifications'
import StaffInfoWorkExperience from './Sections/StaffInfoWorkExperience/StaffInfoWorkExperience'
import {
    promoteUserToAdminMutation,
    updateStaffTypeMutation,
} from './mutations'

export interface StaffInformationProps {
    me?: boolean
    staff?: StaffModel
    cookies?: Cookies
    width?: string
    height?: string
    documents?: DocumentModel[]
    qualifications?: QualificationModel[]
    itSystems?: ItSystemModel[]
    languages?: LanguagesModel[]
    auth0
    closeCallback?: Function
    openCallback?: Function
    removeCallback?: Function
    isModal?: boolean
    showPromote?: boolean
    profileImageClicked?: Function
}

const StaffInformation: FunctionComponent<StaffInformationProps> = (
    props: StaffInformationProps
) => {
    const theme = useContext(ThemeContext)

    const [canEdit] = useState<boolean>(
        props.isModal
            ? props.isModal === true
                ? false
                : JSON.parse(sessionStorage.getItem('user')).id ===
                  props.staff.id
            : JSON.parse(sessionStorage.getItem('user')).id === props.staff.id
    )
    const [staff, setStaff] = useState<StaffModel>(props.staff)
    // const [addingDocument, setAddingDocument] = useState<boolean>(false)
    const [addingSystem, setAddingSystem] = useState<boolean>(false)
    // const [addingLanguage, setAddingLanguage] = useState<boolean>(false)
    const [addingQualification, setAddingQualification] =
        useState<boolean>(false)
    const [addingWorkExperience, setAddingWorkExperience] =
        useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)

    const [promoteUser, setPromoteUser] = useState<boolean>(false)
    const cookies = useCookies()[0]

    const user: StaffModel = JSON.parse(sessionStorage.getItem('user'))

    const promoteUserToAdmin = () => {
        client
            .mutate({
                mutation: promoteUserToAdminMutation,
                variables: {
                    staffId: staff.id,
                },
                context: {
                    headers: {
                        Authorization: cookies['token'],
                    },
                },
            })
            .then((response) => {
                if (response.data.promoteUserToAdmin.success === true) {
                    setStaff({
                        ...staff,
                        staff_type: 'ADMIN',
                    })
                    setPromoteUser(false)
                }
            })
    }

    const renderAddModals = () => {
        if (canEdit) {
            return (
                <div>
                    {/* {addingDocument  === true && (
                        <AddDocumentModal
                            show={addingDocument}
                            closeModal={() => setAddingDocument(false)}
                            documentAdded={(newDocument) => {
                                const newStaff = { ...staff, documents: [...staff.documents, newDocument]}
                                setStaff(newStaff)
                            }}
                        />
                    )} */}
                    {addingSystem === true && (
                        <AddSystemModal
                            show={addingSystem}
                            closeModal={() => setAddingSystem(false)}
                            systems={props.itSystems}
                            systemAdded={(newSystem) => {
                                const newStaff = {
                                    ...staff,
                                    itSystems: [...staff.itSystems, newSystem],
                                }
                                setStaff(newStaff)
                            }}
                        />
                    )}
                    {/* {addingLanguage  === true && (
                        <AddLanguageModal
                            show={addingLanguage}
                            closeModal={() => {
                                console.log("CLOSE MODAL")
                                setAddingLanguage(false)
                            }}
                            languages={props.languages}
                            languageAdded={(newLanguage) => {
                                const newStaff = { ...staff, languages: [...staff.languages, newLanguage] }
                                setStaff(newStaff)
                            }}
                        />
                    )} */}
                    {addingQualification === true && (
                        <AddQualificationModal
                            show={addingQualification}
                            closeModal={() => setAddingQualification(false)}
                            qualificationAdded={(newQualification) => {
                                const newStaff = {
                                    ...staff,
                                    qualifications: [
                                        ...staff.qualifications,
                                        newQualification,
                                    ],
                                }
                                setStaff(newStaff)
                            }}
                        />
                    )}
                    {addingWorkExperience === true && (
                        <AddWorkExperienceModal
                            show={addingWorkExperience}
                            closeModal={() => setAddingWorkExperience(false)}
                            workExperienceAdded={(newWorkExperience) => {
                                const newStaff = {
                                    ...staff,
                                    workExperience: [
                                        ...staff.workExperience,
                                        newWorkExperience,
                                    ],
                                }
                                setStaff(newStaff)
                            }}
                        />
                    )}
                </div>
            )
        }
    }

    // const renderStaffInfoSystems = () => {
    //     return (<StaffInfoSystems
    //         systems={staff.itSystems}
    //         allSystems={props.itSystems}
    //         canEdit={canEdit}
    //         addSystem={() => {
    //             setAddingSystem(true)
    //         }}
    //     />
    //     )
    // }

    const renderCloseAndOpenButtons = () => {
        return (
            <div className='closeAndOpenButtonContainer'>
                {user.staff_type === 'ADMIN' &&
                    staff.staff_type !== 'ADMIN' &&
                    (props.showPromote ?? true) === true && (
                        <b
                            className='promote-button'
                            onClick={() => {
                                setPromoteUser(true)
                            }}>
                            Promote To Admin
                        </b>
                    )}
                {props.openCallback !== undefined && (
                    <img
                        src={OpenNewImage}
                        alt='open-in-new-page-button'
                        className='closeButton'
                        onClick={() => {
                            props.openCallback()
                        }}
                    />
                )}
                {props.closeCallback !== undefined && (
                    <img
                        src={CloseImage}
                        alt='close-button'
                        className='closeButton'
                        onClick={() => {
                            props.closeCallback()
                        }}
                    />
                )}
            </div>
        )
    }

    const updateStaffType = (staff_type: string) => {
        setLoading(true)
        client
            .mutate({
                mutation: updateStaffTypeMutation,
                variables: {
                    staffId: staff.id,
                    staffType: staff_type,
                },
                context: {
                    headers: {
                        Authorization: cookies['token'],
                    },
                },
            })
            .then((response) => {
                if (response.data.updateStaffType.success === true) {
                    const newStaff = { ...staff, staff_type }
                    setStaff(newStaff)
                    setLoading(false)
                } else {
                    alert('Failed to update staff type')
                    setLoading(false)
                }
            })
    }

    if (loading === true) {
        return (
            <div
                className='staff-info-container'
                style={{ width: props.width, height: props.height }}>
                <LoadingSpinner />
            </div>
        )
    }
    return (
        <div
            className='staff-info-container'
            style={{
                width: props.width,
                height: props.height,
            }}>
            {renderCloseAndOpenButtons()}

            <div className='staff-info-content'>
                <StaffInfoHeader
                    image={staff.image}
                    name={staff.name_value}
                    gmcNumber={staff.GMC_number}
                    email={staff.email}
                    canEdit={canEdit}
                    imageClicked={() => {
                        props.profileImageClicked()
                    }}
                    approved={staff.approved}
                    staff_type={staff.staff_type}
                    canChangeStaffType={
                        JSON.parse(window.sessionStorage.getItem('user'))
                            .staff_type === 'ADMIN'
                    }
                    updateStaffType={(staff_type) =>
                        updateStaffType(staff_type)
                    }
                />
                <div className='button-container'>
                    <Bio canEdit={canEdit} bio={staff.bio} />
                    {/* <StaffInfoDocuments
                        documents={staff.documents || []}
                        canEdit={canEdit}
                        addDocument={() => setAddingDocument(true)}
                    /> */}
                    {/* {renderStaffInfoSystems()} */}
                    <StaffInfoQualifications
                        qualifications={staff.qualifications || []}
                        allQualifications={props.qualifications}
                        canEdit={canEdit}
                        addQualification={() => setAddingQualification(true)}
                        removeQualification={(qualification) => {
                            const newQualifications =
                                staff.qualifications?.filter((item) => {
                                    return item.id !== qualification.id
                                })
                            const newStaff = {
                                ...staff,
                                qualifications: newQualifications,
                            }
                            setStaff(newStaff)
                        }}
                    />
                    <StaffInfoWorkExperience
                        workExperience={staff.workExperience || []}
                        canEdit={canEdit}
                        addWorkExperience={() => setAddingWorkExperience(true)}
                        removeWorkExperience={(workExperienceId) => {
                            const newExperience = staff.workExperience?.filter(
                                (item) => {
                                    return item.id !== workExperienceId
                                }
                            )
                            const newStaff = {
                                ...staff,
                                workExperience: newExperience,
                            }
                            setStaff(newStaff)
                        }}
                    />
                    {/* <StaffInfoLanguages
                        languages={staff.languages || []}
                        allLanguages={[]}
                        canEdit={canEdit}
                        addLanguage={() => setAddingLanguage(true)}
                    /> */}
                    <br />
                    {props.me ? (
                        <ColoredButton
                            borderRadius='5px'
                            width='30%'
                            emphasis={ColoredButtonEmphasis.HIGH}
                            backgroundColor={theme.colors.secondary}
                            color='white'
                            text='Save'
                        />
                    ) : (
                        ''
                    )}
                    {props.removeCallback && (
                        <ColoredButton
                            borderRadius='5px'
                            width='40%'
                            emphasis={ColoredButtonEmphasis.HIGH}
                            backgroundColor={theme.colors.secondary}
                            color='white'
                            text='Remove from session'
                            onClick={props.removeCallback}
                        />
                    )}
                </div>
            </div>
            {renderAddModals()}

            {promoteUser === true && (
                <GeneralConfirmModal
                    show={promoteUser}
                    closeModal={() => setPromoteUser(false)}
                    title={`Promote user '${staff.name_value}' to Admin?`}
                    confirmAction={() => promoteUserToAdmin()}
                />
            )}
        </div>
    )
}

var cookieVersion = withCookies(StaffInformation)

export default withAuth0(cookieVersion)
