import { FunctionComponent, useContext } from "react";
import { ThemeContext } from "../../../../App";
import LoadingSpinner from "../../../../components/loadingSpinner";
import './styles.css'

interface ApprovedUnapprovedCardProps {
    title: string
    approvedNumber: number|null
    totalNumber: number|null
}
const ApprovedUnapprovedCard: FunctionComponent<ApprovedUnapprovedCardProps> = (props: ApprovedUnapprovedCardProps) => {
    const theme = useContext(ThemeContext)

    return (
        <div className="approved-unapproved-card-container" style={{ borderColor: theme.colors.brand }}>
            <div className="approved-unapproved-card-title">
                {props.title}
            </div>
            {props.approvedNumber == null && (
                <LoadingSpinner/>
            )}
            {props.approvedNumber != null && (
                <div className="approved-unapproved-card-number" style={{ color: theme.colors.brand}}>
                <div className="approved-unapproved-card-number-row"><span className="approved-unapproved-card-approved-label">Approved: </span>{props.approvedNumber}</div>
                <div className="approved-unapproved-card-number-row"><span className="approved-unapproved-card-total-label">Total: </span>{props.totalNumber}</div>
            </div>
            )}
        </div>
    )
}

export default ApprovedUnapprovedCard