import { FunctionComponent, useContext } from "react"
import { StaffModel } from "../../../libary/Models"
import "./styles.css"

import ApproveImage from '../../../assets/icons/ic_check_circle.svg'
import { ThemeContext } from "../../../App"

interface StaffApprovalCellProps {
    staff: StaffModel
    viewStaff: Function
    approve: Function
    reject: Function
    key: string
}
const StaffApprovalCell: FunctionComponent<StaffApprovalCellProps> = (props: StaffApprovalCellProps) => {
    const theme = useContext(ThemeContext)
    return (
        <div className="document-approval-cell-container" style={{borderColor: theme.colors.brand}}>
            <div className="document-approval-cell-label">Staff Name:</div>
            <div className="document-approval-cell-text">{props.staff.name_value}</div>
            <div className="document-approval-cell-button-view" onClick={() => props.viewStaff()}>View</div>
            <div className="document-approval-cell-button-container" onClick={() => {props.approve()}} >
                {/* <img className="document-approval-cell-button-reject" onClick={() => {props.reject()}} src={RejectImage}/> */}
                <img className="document-approval-cell-button-approve" src={ApproveImage}/>
                Approve
            </div>
        </div>
    )
}

export default StaffApprovalCell