import { FunctionComponent } from "react";
import { LocationModel } from "../../../libary/Models";
import './styles.css'

interface LocationListProps {
    locations: LocationModel[]
    locationSelected: Function
    requestPermissionClicked: Function
}

const LocationList: FunctionComponent<LocationListProps> = (props: LocationListProps) => {

    const renderLocations = () => {
        return props.locations.map((loc) => {
            return (
                <div className="location-list-item" key={loc.id}>
                    <div className="location-list-item-text" onClick={() => props.locationSelected(loc)}>
                        {loc.name_value}
                    </div>                
                    <div className="location-list-item-request-text" onClick={() => props.requestPermissionClicked(loc)}>
                        Request Permission
                    </div>
                </div>
            )
        })
    }

    return (
        <div className="location-list">
            {props.locations.length === 0 && <div>You already have Create Session permissions for all Locations</div>}
            {renderLocations()}
        </div>
    )
}

export default LocationList