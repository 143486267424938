import { gql } from '@apollo/client'
import { Result } from 'true-myth'
import { SessionCounts } from '../../../libary/Models'
import query from '../../../libary/network/apollo'

const getLocalitiesQuery = gql`
    query getLocalities {
        getLocalities {
            id
            name_value
        }
    }
`

const getLocationsQuery = gql`
    query getLocations($localityId: String) {
        getLocations(locality_id: $localityId) {
            id
            name_value
            location_code
        }
    }
`

const sessionCountsQuery = gql`
    query SessionCounts(
        $month: String!
        $year: String!
        $staffType: String
        $localityId: String
        $locationId: String
    ) {
        sessionCounts(
            month: $month
            year: $year
            filterFilled: true
            filterPending: true
            staff_type: $staffType
            LOCALITY_ID: $localityId
            LOCATION_ID: $locationId
        ) {
            counts {
                filled
                no_applicants
                pending
            }
            date
        }
    }
`

const getSessionCounts = async (
    input: {
        month: string
        year: string
        staffType: string
        localityId?: string
        locationId?: string
    },
    token: string
): Promise<Result<SessionCounts[], Error>> => {
    return query(sessionCountsQuery, token, 'sessionCounts', { ...input })
}

export { getLocalitiesQuery, getLocationsQuery, getSessionCounts }
