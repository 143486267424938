import { gql } from '@apollo/client'
import { mutation } from '../../libary/network/apollo'

const deleteRecurrenceMutation = gql`
    mutation DeleteRecurrence($id: String!) {
        deleteRecurrence(id: $id) {
            error
            id
            success
        }
    }
`

const deleteRecurrence = async (id: string, token) => {
    return mutation(deleteRecurrenceMutation, token, 'deleteRecurrence', { id })
}
export { deleteRecurrence }
