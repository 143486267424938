import moment from 'moment'
import { FunctionComponent } from 'react'
import { ApplicationModel } from '../../../../../libary/Models'
import './styles.css'

interface PendingSessionCardProps {
    application?: ApplicationModel
    onClick?: Function
}

const PendingSessionCard: FunctionComponent<PendingSessionCardProps> = (
    props: PendingSessionCardProps
) => {
    return (
        <div
            className='application-list-card'
            onClick={() => {
                props.onClick()
            }}>
            <div className='application-list-card-text'>
                {props.application.session_location_name}
            </div>
            <div className='application-list-multilayer'>
                <div className='application-list-card-text'>
                    {moment(props.application.session_start_time).format(
                        'HH:mm'
                    )}{' '}
                    -{' '}
                    {moment(props.application.session_end_time).format('HH:mm')}
                </div>
            </div>
        </div>
    )
}

export default PendingSessionCard
