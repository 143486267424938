import { LoadingButton } from '@mui/lab'
import { Box, Grid, TextField } from '@mui/material'
import { FC, useState } from 'react'
import { SessionConfig } from '../../../components/modals/CreateSessionModalNew/queries'
import { SessionModel } from '../../../libary/Models'
import Mutations from '../../../libary/apollo/mutations'
import useFeatures from '../../../libary/hooks/useFeatures'
import { staffAvailabilityCheck } from './queries'

interface SessionInformationTabProps {
    token: string
    session: SessionModel
    sessionConfig: SessionConfig
    onApply: () => void
}
const SessionInformationTab: FC<SessionInformationTabProps> = (
    props: SessionInformationTabProps
) => {
    const [applying, setApplying] = useState<boolean>(false)
    const { features } = useFeatures()

    console.log('SESSION IN TAB: ', props.session)
    const applyForSession = async () => {
        const token = props.token

        const result = await staffAvailabilityCheck(
            { sessionDate: props.session.session_date },
            token
        )
        if (result.isOk) {
            if (result.value.available === false) {
                alert('You have already applied for a session on this date.')
            } else {
                setApplying(true)
                Mutations.applyToSession(
                    props.session.id,
                    props.session.session_date,
                    props.session.start_time,
                    props.session.end_time,
                    props.session.location.name_value,
                    token
                ).then((response) => {
                    // props.setLoading(false)
                    setApplying(false)
                    props.onApply()
                })
            }
        }
    }

    const renderApplySection = () => {
        if (
            features.findSessions === true &&
            props.session.session_status !== 'filled'
        ) {
            return (
                <Grid item xs={12}>
                    <LoadingButton
                        fullWidth
                        variant='contained'
                        color='primary'
                        onClick={() => applyForSession()}
                        loading={applying}>
                        Apply
                    </LoadingButton>
                </Grid>
            )
        }
    }

    return (
        <Box component='form' sx={{ overflowY: 'hidden', display: 'flex' }}>
            <Grid
                container
                spacing={2}
                sx={{ overflowY: 'scroll', marginTop: 0 }}>
                <Grid item lg={12} xs={12}>
                    <TextField
                        fullWidth
                        label='Location'
                        value={props.session.location?.name_value ?? ' '}
                        disabled
                    />
                </Grid>
                <Grid item lg={12} xs={12}>
                    <TextField
                        fullWidth
                        label='Locality'
                        value={props.session.locality?.name_value ?? ' '}
                        disabled
                    />
                </Grid>
                <Grid item lg={12} xs={12}>
                    <TextField
                        fullWidth
                        label='Description'
                        value={props.session.session_description ?? ' '}
                        disabled
                    />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <TextField
                        fullWidth
                        label='Staff Type'
                        value={props.session.staff_type}
                        disabled
                    />
                </Grid>
                <Grid
                    item
                    lg={6}
                    xs={12}
                    hidden={props.sessionConfig.hiddenFields.paymentProcess}>
                    <TextField
                        fullWidth
                        label='Payment Process'
                        value={props.session.payment_process ?? 'N/A'}
                        disabled
                    />
                </Grid>
                <Grid
                    item
                    lg={6}
                    xs={12}
                    hidden={
                        props.sessionConfig.hiddenFields.lengthOfAppointments
                    }>
                    <TextField
                        fullWidth
                        label='Length of Appointments'
                        value={props.session.length_of_appointments ?? ' '}
                        disabled
                    />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <TextField
                        fullWidth
                        label='Break'
                        value={props.session.break ?? ' '}
                        disabled
                    />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <TextField
                        fullWidth
                        label='IT System'
                        value={props.session.it_system.name_value ?? ' '}
                        disabled
                    />
                </Grid>
                {renderApplySection()}
            </Grid>
        </Box>
    )
}

export default SessionInformationTab
