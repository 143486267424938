import { gql } from '@apollo/client'
import { Result } from 'true-myth'
import {
    LocalityModel,
    LocationModel,
    RecurrenceModel,
} from '../../libary/Models'
import query from '../../libary/network/apollo'

const getLocalitiesQuery = gql`
    query {
        getLocalities {
            id
            name_value
        }
    }
`

const getLocationsQuery = gql`
    query GetLocations($localityId: String) {
        getLocations(locality_id: $localityId) {
            id
            name_value
        }
    }
`

const getRecurrencesQuery = gql`
    query GetRecurrences($localityId: String, $locationId: String) {
        recurrences(LOCALITY_ID: $localityId, LOCATION_ID: $locationId) {
            id
            start_date
            end_date
            models {
                date
                day
                frequency
            }
            session_template {
                staff_type
            }
            locality {
                id
            }
            location {
                id
                name_value
            }
            locality {
                id
                name_value
            }
        }
    }
`

const getLocalities = async (
    token: string
): Promise<Result<LocalityModel[], Error>> => {
    return query(getLocalitiesQuery, token, 'getLocalities')
}

const getRecurrences = async (
    input: { locationId: string; localityId: string },
    token: string,
    localityId?: string,
    locationId?: string
): Promise<Result<RecurrenceModel[], Error>> => {
    return query(getRecurrencesQuery, token, 'recurrences', {
        localityId: input.localityId,
        locationId: input.locationId,
    })
}

const getLocations = async (
    localityId: string,
    token: string
): Promise<Result<LocationModel[], Error>> => {
    return query(getLocationsQuery, token, 'getLocations', {
        localityId,
    })
}

export { getLocalities, getLocations, getRecurrences }
