import Config from "./Config"
import DevConfig from "./DevConfig"
import FedworksConfig from "./FedworksConfig"
import GMWorkforceConfig from "./GMWorkforceConfig"

export const getConfig = (): Config => {
    if(process.env.NODE_ENV !== 'production') {
        return DevConfig
      }
      if(window.location.hostname.includes("fedworks")) {
        return FedworksConfig
      } else if(window.location.hostname.includes("gmworkforce")) {
        return GMWorkforceConfig
      } else {
        return DevConfig
      }
}