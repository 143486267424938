import { useAuth0 } from "@auth0/auth0-react";
import { FunctionComponent, useEffect, useState } from "react";
import Footer from "../../components/Footer";
import GeneralConfirmModal from "../../components/modals/GeneralConfirmModal";
import StaffModal from "../../components/modals/StaffModal";
import NavBarNew from "../../components/NavBarNew";
import AuthContainer from "../../container/AuthContainer";
import client from "../../libary/apollo";
import { SelectedTab, StaffModel } from "../../libary/Models";
import { approveMutation } from "./mutations";
import { staffPendingApprovalQuery } from "./queries";
import StaffApprovalCell from "./StaffApprovalCell";

import './styles.css'

interface StaffApprovalProps {

}

const StaffApproval: FunctionComponent<StaffApprovalProps> = (props: StaffApprovalProps) => {

    const [staffPendingApproval, setStaffPendingApproval] = useState<StaffModel[] | null>(null)
    const [staff, setStaff] = useState<StaffModel|null>(null)
    const [staffToApprove, setStaffToApprove] = useState<StaffModel|null>(null)
    const [token, setToken] = useState<string|null>(null)
    const { getAccessTokenSilently } = useAuth0()

    useEffect(() => {
        (async () => {
            const token = await getAccessTokenSilently()
            setToken(token)
            client.query({
                query: staffPendingApprovalQuery,
                context: {
                    headers: {
                        "Authorization": token
                    }
                }
            }).then((response) => {
                setStaffPendingApproval(response.data.staffPendingApproval)
            })
        })()
    }, [])

    const renderStaff = () => {
        return staffPendingApproval.map((staffItem) => {
            return <StaffApprovalCell staff={staffItem} viewStaff={() => {setStaff(staffItem)}} approve={() => {
                setStaffToApprove(staffItem)
            }} reject={() => {}} key={staffItem.id}/>
        })
    }

    const approveStaffMember = () => {
        client.mutate({
            mutation: approveMutation,
            variables: {
                staffId: staffToApprove.id,
                approve: true
            },
            context: {
                headers: {
                    "Authorization": token
                }
            }
        }).then((response) => {
            if(response.data.approveUser.success === true) { 
                const newStaffPendingApproval = staffPendingApproval.filter((staff) => staff.id !== staffToApprove.id)
                setStaffPendingApproval(newStaffPendingApproval)
                setStaffToApprove(null)
            } else {
                alert("Failed to approve user")
            }
        })
    }

    return (
        <AuthContainer>
            <div className='staff-approval-body'>
                <NavBarNew setTab={() => { }} selectedTab={SelectedTab.StaffApproval}/>
                <div className='mainBody'>
                    <div className='staff-approval-container'>
                        {staffPendingApproval !== null && renderStaff()}
                    </div>
                </div>
                <Footer />
                {staff !== null && <StaffModal id={staff.id} show={staff !== null} closeModal={() => {setStaff(null)}}/>}
                {staffToApprove !== null && <GeneralConfirmModal 
                title={`Approve ${staffToApprove.name_value}`} 
                closeModal={() => {setStaffToApprove(null)}} 
                show={staffToApprove !== null} 
                confirmAction={() => {
                    approveStaffMember()
                }}/>}
            </div>
        </AuthContainer>
    )
}

export default StaffApproval