import React, { FunctionComponent } from 'react'

interface StaffInfoListItemProps {
    name?: string
    id: string
    staffId?: string
    removeItem: Function
    key: string
    edit: boolean
    approved?: boolean
    viewDocument?: Function
    isDoc: boolean
}

const StaffInfoListItem: FunctionComponent<StaffInfoListItemProps> = (props: StaffInfoListItemProps) => {

    const remove = () => {
        props.removeItem(props.id)
    }

    const getColor = () => {
        if(props.approved === null) {
            return ''
        } 
        if(props.approved  === false) {
            return 'var(--pending-color)'
        }
        if(props.approved  === true) {
            return 'var(--filled-color)'
        }
    }
    return (
        <div className="ListItem" style={{backgroundColor: getColor()}}key={props.key}>
                {
                (props.id && props.staffId && props.isDoc  === true) ? 
                <div className="list_item_name">
                    <div onClick={() => {
                        props.viewDocument()
                    }}>{props.name}</div>   
                </div> 
                :
                <div className="list_item_name">
                        {props.name}
                    </div>
                }
                {
                    props.edit ? <div className="listItemcloseButton" onClick={
                            () => remove()
                            }>X</div>
                    : 
                    ""
                }
            </div>
    )
}

export default StaffInfoListItem