import { gql } from "@apollo/client";

const getMeQuery = gql`
query getMeQuery {
  me {
    id
    name_value
    staff_type
    title
    GMC_number
    image
    bio
    approved
    can_edit
    documents {
        id
        name_value
        staff_id
        staff_name
        approved
    }
    workExperience {
      id
      name_value
    }
    itSystems{
      id
      name_value
    }
    languages{
      id
      name_value
      short_name
  }
    qualifications{
      id
      name_value
    }
  }
  qualifications {
    id
    name_value
  }
  itSystems {
    id
    name_value
  }
  languages {
    id
    name_value
    short_name
  }
}
`

export { getMeQuery }