import { FunctionComponent, useContext, useEffect, useState } from 'react'
import { AccountProps } from './types'

import { useAuth0 } from '@auth0/auth0-react'
import { ThemeContext } from '../../App'
import Footer from '../../components/Footer'
import NavBarNew from '../../components/NavBarNew'
import ColoredButton, {
    ColoredButtonEmphasis,
} from '../../components/colored-button'
import LoadingSpinner from '../../components/loadingSpinner'
import ChangeProfileImageModal from '../../components/modals/ChangeProfileImageModal'
import AuthContainer from '../../container/AuthContainer'
import {
    DocumentModel,
    ItSystemModel,
    LanguagesModel,
    QualificationModel,
    SelectedTab,
    StaffModel,
} from '../../libary/Models'
import client from '../../libary/apollo'
import StaffInformation from '../staffInformation'
import { getMeQuery } from './queries'
import './styles.css'

interface MinimalStaffInfoProps {
    staff?: StaffModel
    documents?: DocumentModel[]
    qualifications?: QualificationModel[]
    itSystems?: ItSystemModel[]
    languages?: LanguagesModel[]
}

const Account: FunctionComponent<AccountProps> = (props: AccountProps) => {
    const [staffInfoProps, setStaffInfoProps] =
        useState<MinimalStaffInfoProps | null>(null)
    const [changingProfileImage, setChangingProfileImage] =
        useState<boolean>(false)
    const theme = useContext(ThemeContext)
    // const [imageSrc, setImageSrc] = useState<string|undefined>(undefined)
    const { getAccessTokenSilently } = useAuth0()

    useEffect(() => {
        loadAcount()
    }, [getAccessTokenSilently])

    // useEffect(() => {
    //   if(imageSrc !== undefined) {
    //     console.log("UPDATE IMAGE with: ", imageSrc)
    //     const newStaffInfoProps = {...staffInfoProps, staff: { ...staffInfoProps.staff, image: imageSrc}}
    //     console.log("New staff infor props: ", staffInfoProps)
    //     setStaffInfoProps(newStaffInfoProps)
    //   }
    // }, [imageSrc])

    const loadAcount = () => {
        ;(async () => {
            const token = await getAccessTokenSilently()
            client
                .query({
                    query: getMeQuery,
                    context: {
                        headers: {
                            authorization: token,
                        },
                    },
                })
                .then((response) => {
                    setStaffInfoProps({
                        staff: response.data.me,
                        documents: response.data.documents,
                        itSystems: response.data.itSystems,
                        qualifications: response.data.qualifications,
                        languages: response.data.languages,
                    })
                })
        })()
    }
    return (
        <AuthContainer>
            <div className='accountBody'>
                <NavBarNew
                    setTab={() => {}}
                    selectedTab={SelectedTab.Account}
                />
                {staffInfoProps !== null && (
                    <div className='mainBody' style={{ height: '100%' }}>
                        <StaffInformation
                            staff={staffInfoProps.staff}
                            documents={staffInfoProps.documents}
                            itSystems={staffInfoProps.itSystems}
                            qualifications={staffInfoProps.qualifications}
                            languages={staffInfoProps.languages}
                            width='100%'
                            profileImageClicked={() => {
                                setChangingProfileImage(true)
                            }}
                        />
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}>
                            <ColoredButton
                                borderRadius='5px'
                                width='30%'
                                emphasis={ColoredButtonEmphasis.HIGH}
                                backgroundColor={theme.colors.secondary}
                                color='white'
                                text='Edit'
                                onClick={() => {
                                    window.history.pushState(
                                        null,
                                        null,
                                        '/editaccount'
                                    )
                                    window.location.replace('/editaccount')
                                }}
                            />
                        </div>
                    </div>
                )}
                {staffInfoProps === null && (
                    <div className='mainBody'>
                        <LoadingSpinner />
                    </div>
                )}
                <Footer />
                {changingProfileImage === true && (
                    <ChangeProfileImageModal
                        closeModal={() => setChangingProfileImage(false)}
                        show={changingProfileImage}
                        imageAdded={async (imageFile: File) => {
                            // const arrayBuffer = imageFile.arrayBuffer()
                            // const safeBuffer = await arrayBuffer

                            // const blob = new Blob( [ safeBuffer ] );
                            // const url = URL.createObjectURL( blob );
                            // setImageSrc(url)
                            window.location.reload()
                        }}
                    />
                )}
            </div>
        </AuthContainer>
    )
}

export default Account
