import React, { FunctionComponent, useEffect, useState } from 'react'
import './styles.css'
import { StaffModel } from '../../../libary/Models'
import StaffInformation from '../../../pages/staffInformation'
import { staffQuery } from './queries'
import client from '../../../libary/apollo'
import { idWithoutHash } from '../../../libary/IDHelpers'
import useToken from '../../../libary/hooks/useToken'
import LoadingSpinner from '../../loadingSpinner'
import { Modal } from '@mui/material'

interface StaffModalProps {
    show: boolean
    id: string
    closeModal: Function
    showPromote?: boolean
}

const StaffModal: FunctionComponent<StaffModalProps> = (props: StaffModalProps) => {
    const [staff, setStaff] = useState<StaffModel | null>(null)
    const token = useToken()

    useEffect(() => {
        if (token === null) { return }
        client.query({
            query: staffQuery,
            variables: {
                id: props.id
            },
            context: {
                headers: {
                    authorization: token
                }
            }

        }).then((response) => {
            setStaff(response.data.staffById)
        })
    }, [props.id, token])

    const renderStaffInfo = () => {
        return (
            <div style={{ width: '80vw' }}>
                <StaffInformation
                    showPromote={props.showPromote}
                    isModal={true}
                    staff={staff}
                    closeCallback={props.closeModal}
                    openCallback={() => {
                        const newLocation = `/staff?id=${idWithoutHash(staff.id)}`
                        window.open(newLocation)
                    }}
                />
            </div>
        )
    }
    return (
        <Modal
            open={props.show} onClose={() => props.closeModal()}>
            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', outline: 'none' }}>
                {staff === null && <LoadingSpinner />}
                {staff !== null && renderStaffInfo()}
            </div>
        </Modal>
        // <NewModal show={props.show} close={() => props.closeModal()}>
        //     {staff === null && <LoadingSpinner/>}
        //     {staff !== null && renderStaffInfo()}
        // </NewModal>
    )
}

export default StaffModal