const idWithoutHash = (id: string) => {
    const parts = id.split("#")
    if(parts.length > 0) {
        return parts[parts.length - 1]
    } else { 
        return ""
    }
}

export { 
    idWithoutHash
}